.elec-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #a3a3a3;
}

.elec-footer {
  position: fixed;
  background-color: #4a4a4a;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
}
.footer-button-gray {
  background-color: #bebfbf;
  color: white;
  border-radius: 100px;
  font-size: 20px;
  padding: 8px 40px;
  margin: 12px 4px;
  font-weight: 900;
}

.footer-button {
  background-color: #22ccb7;
  color: white;
  border-radius: 100px;
  font-size: 20px;
  padding: 8px 40px;
  margin: 12px 4px;
  font-weight: 900;
}

.left-menu {
  width: 250px;
  position: fixed;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 998;
}

.elec-content {
  margin-top: 100px;
  padding-left: 300px;
  padding-bottom: 100px;
  background-color: #a3a3a3;
}

.elec-card {
  width: 90%;
  margin: 16px;
  border: 1px solid #ebebeb;
  padding: 8px;
}
.elec-card > h1 {
  font-size: 16px;
}
.elec-card > span {
  font-size: 12px;
  color: #848484;
}
.elec-card-company {
  border-top: 16px solid #22ccb7;
}
.elec-card-user {
  border-top: 16px solid #ffda0d;
}

.card-button {
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 8px;
  margin: 4px 0;
}

.card-button-del {
  width: 45%;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 1px;
  margin: 2px 4px;
}

.card-button:disabled {
  color: black;
}

.create_stamp_menu {
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  color: #818181;
  padding: 8px;
}

.primary-button {
  color: white;
  padding: 8px;
  background-color: #22ccb7;
}
