/*reset*/
.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: #fd9f9f !important;
}
.toastBold {
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #333;
}
ul,
ol {
  list-style: none;
}
img {
  vertical-align: middle;
}
input,
select,
button,
textarea {
  font-family: "s-core4", sans-serif;
}
table {
  border-spacing: 0;
}
caption {
  display: none;
}
input:focus {
  outline: none;
}
custom-modal {
  display: none;
}
/*레이아웃*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "s-core4", sans-serif !important;
  color: #454545;
  /* padding-left: 80px;*/
  font-size: 14px;
}

@font-face {
  font-family: "s-core1";
  src: url("../fonts/S-Core-Dream-light/scdream2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "s-core2";
  src: url("../fonts/S-Core-Dream-light/scdream2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "s-core3";
  src: url("../fonts/S-Core-Dream-light/scdream2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "s-core4";
  src: url("../fonts/S-Core-Dream-light/scdream4-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "s-core5";
  src: url("../fonts/S-Core-Dream-light/scdream7-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "s-core6";
  src: url("../fonts/S-Core-Dream-light/scdream7-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: "s-core7"
  src: url("/static/css/font/S-Core-Dream-light/scdream7-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: "s-core9";
  src: url("../fonts/S-Core-Dream-light/scdream7-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.tox-notifications-container {
  display: none !important;
}
/* common */
.m-3 {
  margin: 12px;
}
.mt-3 {
  margin-top: 12px;
}
.ml-3 {
  margin-left: 12px;
}
.mr-3 {
  margin-right: 12px;
}
.mb-3 {
  margin-bottom: 12px;
}
.inline-block {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.vertical-middle {
  vertical-align: middle;
}

.fixedTh th {
  width: 200px;
}

.register-flex {
  display: flex;
}
.register-flex.time-select > div {
  display: inline-block;
}
.register-flex.time-select > div > div {
  display: inline-block;
  margin: 0 5px;
}
/*지원하지않는브라우저 팝업*/
.no-use {
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -276px;
  position: absolute;
}
.btn-group {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
  margin: 4px;
}
.btn-group-left {
  justify-content: left;
}
.btn-group-inline {
  display: inline-flex;
  gap: 5px;
  margin-right: 4px;
}
.d-inline-block {
  display: inline-block;
}
/*메인 팝업창*/
.pop_footer {
  padding: 10px 8px;
}
a.closeonce {
  float: right;
}
a.closeonce img {
  width: 14px;
}
.border-line-alway {
  border: 1px solid #f57708;
}
.border-line-on:hover {
  border: 1px solid #f57708;
}
/*회원가입리스트 팝업*/
.join-popup {
  position: absolute;
  background-color: #fff;
  z-index: 600;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.join-top {
  background: #23cdb7;
  height: 60px;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 14px 10px 18px;
  border-radius: 8px 8px 0 0;
}
.join-top h5 {
  line-height: 41px;
  float: left;
  font-size: 17px;
  box-sizing: border-box;
}
.jbutton {
  background: transparent;
  float: right;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
}
.join-table table {
  width: 100%;
  text-align: center;
}
.j-head .head {
  background-color: #f7f7f7;
}
.j-head .head th {
  height: 44px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}
.body-join {
  height: 265px;
  overflow-y: auto;
}
.j-body td {
  border-bottom: 1px solid #ccc;
  height: 51px;
}
.jbtn a {
  display: inline-block;
  border-radius: 6px;
  padding: 5px;
  color: #fff;
}
.jbtn a.ok {
  background-color: #23cdb7;
}
.jbtn a.no {
  background-color: #fd669f;
}

/*급여지급명세서 팝업*/
.cal-popup .pay-top {
  border-bottom: 0 !important;
}
.pop-pay {
  padding: 0 10px;
  position: relative;
  height: 660px;
  overflow-y: auto;
}
.pay01 {
  margin-bottom: 10px;
}
.pay01 .title {
  float: left;
  padding-top: 20px;
  text-align: center;
  width: 50%;
}
.pay01 .h5 {
  margin-bottom: 10px;
  font-size: 25px;
  font-family: s-core5;
}
.pay01 .title p {
  font-size: 18px;
  font-family: s-core4;
}
.pay-table1 {
  float: right;
  border: 1px solid #ddd;
  width: 290px;
  border-collapse: collapse;
}
.pay02 {
  margin-bottom: 25px;
}
.pay02 .pay-tit {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: bold;
}

.pay02 table.pay-table {
  border: 1px solid #ddd;
  width: 100%;
  border-collapse: collapse;
}
.pay-table thead tr,
.pay-table tbody tr,
.pay-table1 thead tr,
.pay-table1 tbody tr,
.pay-table2 tbody tr {
  border-bottom: 1px solid #ddd;
}
.pay-table tr th,
.pay-table1 tr th {
  border-right: 1px solid #ddd;
  font-weight: bold;
  background: #f7f7f7;
  padding: 10px;
}
.pay-table tfoot td {
  font-weight: bold;
  border-top: 1px solid #818181;
}
.pay-table1 tr td {
  padding: 10px;
}
.pay-table tr td {
  height: 34px;
  text-align: center;
  border-right: 1px solid #ddd;
}
.pay04 {
  padding-top: 17px;
  text-align: center;
}
.pay04 p {
  margin-bottom: 5px;
}
.pay04 .paysign {
  margin-top: 14px;
}
.pay04 .back-sign {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-size: 100% 100%;
  position: relative;
}
.pay04 .back-sign p {
  position: absolute;
  top: 40%;
  left: 40%;
}
.pay04 p {
  margin-bottom: 5px;
}
.print-btn a {
  color: #fff;
  display: block;
  width: 130px;
  text-align: center;
  margin: 17px auto 0 auto;
  border-radius: 10px;
  padding: 15px 0;
  background: #23cdb7;
}
/*휴가신청서 팝업창*/
.pay01 .title2 {
  width: inherit;
}
.pay-table2 {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.rest-top .approv {
  width: 100%;
}
.pay-t {
  margin-bottom: 10px;
  width: 438px;
  float: right;
}
.pay-t tbody tr td:first-child {
  width: 50px;
}
/*.pay-table2 thead td.h5{
    border-left: 0;
    border-top: 0;
}*/
.pay-table2 tbody td {
  /*width: 58px;*/
  border-top: 1px solid #ddd;
}
.pay-table2 .rest-top td {
  height: 46px;
}
.pay-table2 tr td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.pay-table2 td.back {
  text-align: center;
  font-weight: bold;
  background: #f7f7f7;
}
.pay-table2 tbody td {
  border-top: 1px solid #ddd;
}
.pay-table2 .reason {
  height: 150px;
}
.pay-table2 .text {
  text-align: center;
}
.pay-table2 .text td {
  padding: 41px 18px;
  box-sizing: border-box;
}
.pay-table2 .restsign p {
  display: inline-block;
  vertical-align: sub;
  margin-bottom: 0;
}
.pay-table2 .restsign {
  margin-top: 41px;
  text-align: right;
}
.pay-table2 p {
  margin-bottom: 25px;
}
.pay-table2 .back-sign {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
  position: relative;
}
.pay-table2 .back-sign p {
  position: absolute;
  top: 40%;
  left: 40%;
}

/* .cal-popup .pop-con {
  position: relative;
}
.toggle-list {
  position: absolute;
  top: 45px;
  width: 100%;
} */
.popcon-list {
  height: 240px;
  padding: 10px 0 10px 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  /*border: 1px solid #e2e2e2;
    border-top: 0;
    border-radius: 0 0 25px 25px;*/
}
.popcon-list .popul {
  flex: auto;
  display: block;
  overflow: auto;
}
.popcon-list .popul li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
.popcon-list .popul li:hover {
  background: #f1f1f1;
}
.popcon-list .popul li a {
  width: 100%;
  padding: 12px 24px;
  display: block;
}

/*사내문서 등록 모달*/
.tabbtnbox {
  float: left;
  width: 25%;
  background-color: #fafafa;
  margin-top: -20px;
  margin-left: -30px;
  padding: 10px;
}
.tabbtn {
  display: block;
  border: none;
  font-size: 15px;
  /* margin-bottom: 15px; */
}
#form-search {
  position: absolute;
  bottom: -235px;
  width: 160px;
  height: 40px;
  margin-left: -30px;
  padding: 3px;
  border: 1px solid #e5e5e5;
}
#form-searchbtn {
  position: absolute;
  top: 200px;
  right: -20px;
  width: 30px;
  height: 30px;
  /* background: url("./img/search.png") no-repeat; */
  background-size: 20px;
  background-position: right;
}
.tabcontbox {
  /* width: 75%; */
  margin-left: 150px;
  margin-bottom: 30px;
}
#docfile-btn {
  display: inline-block;
  background-color: #40b8a8;
  border-radius: 30px;
  line-height: 22px;
  padding: 7px 15px;
  color: #fff !important;
  cursor: pointer;
  margin-left: 4px;
}
.file-guide {
  border: 1px solid #e5e5e5;
  text-align: left;
  padding: 5px 25px;
  margin-top: 10px;
}
.file-guide li {
  list-style: disc;
}
.uploadbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}
.upload-txt {
  padding: 15px 0;
}
.txt-img {
  width: 25px;
  height: 25px;
  background: url("../img/doc/doc_pdf.svg") no-repeat right;
  background-size: 20px;
}
.tactive {
  color: #22ccb7;
}
.tabcont {
  display: none;
}
.tabcont:first-of-type {
  display: block;
}

.uploadbtn {
  text-align: end;
  border-top: 1px solid #e5e5e5;
  padding-top: 10px;
  margin: 0 -30px;
}
.uploadbtn > button {
  border: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  padding: 10px 30px;
  margin-right: 30px;
}
.mail-ellipsis {
  width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mail-ellipsis2 {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
}
/*사내문서 list용 관리자팝업*/
.doc-ellipsis {
  /* margin: 0 auto;
  width: 200px; */
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 텍스트를 자를 때 원하는 단위 ex) 3줄 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
select.select_doc {
  color: #898989;
  padding: 12px 19px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0;
  border: 1px solid #eaeaea;
}
table.pop_doc {
  border-collapse: collapse;
  width: 100%;
}
.pop_doc tr {
  border: 1px solid #eaeaea;
}
.pop_doc th.pop_bac {
  background: #f7f7f7;
}
td.pop_bac {
  padding: 0.5rem;
  display: flex;
}
input.finput.fin-bt {
  width: 80%;
  float: left;
}
.pop_doc a.bt-a {
  margin: 8px 0;
  height: 48px;
  width: 17%;
  color: #fff;
  background: #23cdb7;
  padding: 11px 8px;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  float: right;
  line-height: 28px;
}

.caution {
  padding-top: 20px;
  text-align: center;
}
.caution i {
  color: #fff;
  text-align: center;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  display: inline-block;
  line-height: 24px;
  border-radius: 50%;
  font-weight: bold;
  background: #838383;
}
.caution span {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
}
.caution.upload {
  text-align: left;
}
.in_tit span {
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
  font-size: 1rem;
}
/*입사서류 등록 버튼*/
.in_doc {
  margin-top: 25px;
}
.in_doc .in_updoc {
  float: right;
}
.in_img {
  margin-right: 4px;
  display: inline-block;
}
.in_click:hover {
  text-decoration: underline;
  color: #4e89eb;
}

/*마이페이지+전자서명 팝업*/
.sign_tab li {
  float: left;
  font-size: 15px;
  width: 50%;
  text-align: center;
}
.sign_tab li a {
  display: inline-block;
  position: relative;
  height: 65px;
  line-height: 60px;
  font-weight: bold;
}
.sign_tab li.on a {
  color: #23cdb7;
}
.sign_tab li.on a:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 15px;
  display: block;
  height: 3px;
  background: #23cdb7;
}
.stamp_area {
  height: 300px;
  padding: 50px 0;
  box-sizing: border-box;
}
/*#signature, .stamp_area{
    width: 100%;
    box-sizing:border-box;
}*/
.stamp_area img {
  height: 100%;
  text-align: center;
}
.sign01,
.sign02 {
  text-align: center;
}
.sign01 .subtit1 {
  color: #818181;
  margin: 26px 0 8px 0;
}
.sign01 .subtit,
.sign02 .subtit {
  color: #818181;
  margin: 15px 0 8px 0;
}
.sign02 .subtit span {
  font-size: 13px;
  display: block;
}

.button-line {
  padding-top: 30px;
}
.button-up button {
  padding: 15px 35px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #ddd;
}
.button-line button {
  display: inline-block;
  padding: 15px 35px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #ddd;
}

.button-line button#save01,
.button-line button#save02 {
  background: #23cdb7;
  color: #fff;
  margin-right: 20px;
}

.button-line button#clear {
  margin-bottom: 25px;
  /* background: #f7f7f7;*/
}
.button-line button#remove {
  background: #f7f7f7;
}
.button-line1 {
  text-align: right;
  margin-top: 10px;
}
.button-line1 button {
  border: 1px solid #ccc;
  padding: 8px 11px;
  font-size: 12px;
  color: #636363;
  /*border-radius:6px;*/
}
.button-line1 #clear img {
  vertical-align: sub;
  padding-right: 4px;
}
.input-w {
  box-sizing: border-box;
  width: 77%;
  display: inline-block;
  position: relative;
}
.file-up {
  margin-top: 10px;
  width: 100%;
}
.file-up .up-name {
  color: #797979;
  width: 100%;
  padding: 10px 10px 10px 39px;
  border: 1px solid #ccc;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background-color: #fff;
}
.file-up input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.input-w img {
  position: absolute;
  left: 10px;
  top: 10px;
}
.file-up label {
  color: #fff;
  background: #afafaf;
  width: 22%;
  border-radius: 6px;
  cursor: pointer;
  padding: 11px 10px;
  display: inline-block;
  border: 1px solid #ddd;
  box-sizing: border-box;
  vertical-align: middle;
}
/* 전자결재 도장영역2
.upload_f{
    width: 500px;
    margin: 0 auto;
    border-radius: 6px;
    border: 1px solid #ddd;
}
.upload_f a{
    padding: 10px;
    box-sizing: border-box;
    height: 40px;
    display: inline-block;
}
.upload_f .img-up{
    text-align: left;
    width: 85%;
}
.upload_f .img-up img{
    padding-right: 7px;
}
.upload_f .no-up{
    border-radius:0px 4px 4px 0;
    float: right;
    width: 15%;
    background:#afafaf;
    font-weight: bold;
    color: #fff;
}*/

/*로그인 회원가입*/
.con-login {
  overflow: hidden;
  /*height: 700px;*/
  /*height: 970px;*/
  min-height: 100vh;
  display: flex;
  width: 100%;
  /*grid-auto-flow:row;
    grid-template-columns: 4fr 6fr;*/
}
.con-login .loimage {
  position: fixed;
  width: 26%;
  height: 100%;
  min-height: 1080px;
  z-index: 1;
}
.flex-log {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-pack: center;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  /*height: 970px;*/
}
.flex-log.login-l {
  width: 26%;
  text-align: center;
}
.flex-log.login-r {
  z-index: 2;
  width: 73%;
}
.r-cont {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
}
.r-cont .setit h3,
.r-cont .lotit h3 {
  font-size: 35px;
  font-weight: bold;
}
.r-cont .lotit h3 {
  display: inline-block;
}
.r-cont .lotit span {
  font-size: 16px;
  display: inline-block;
  color: #23cdb7;
  padding-top: 17px;
}
.r-cont .setit h3 {
  margin-bottom: 8px;
}
.r-cont .setit h3,
.r-cont .setit p {
  text-align: center;
}
.r-cont .lotit h3 {
  color: #23cdb7;
}
.r-cont .lotit p,
.r-cont .setit p {
  color: #b3b3b3;
  line-height: 22px;
  font-size: 15px;
  font-family: s-core4;
}
.l-cont {
  z-index: 2;
  color: #fff;
  min-width: 26%;
  width: 300px;
  font-family: s-core2;
}
.l-cont img {
  width: 190px;
}
.l-cont p {
  font-size: 18px;
  margin-top: 15px;
}
.l-cont span {
  bottom: 18px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.data-placeholder {
  color: #ccc;
}
.data-placeholder::before {
  content: attr(data-placeholder);
  width: 100%;
}

.data-placeholder:focus::before,
.data-placeholder:valid::before {
  display: none;
}

.loform {
  width: 100%;
  margin-top: 35px;
}

.lotable td {
  height: auto !important;
  display: block;
  border: none;
}
.lotable input {
  box-sizing: border-box;
  width: 100%;
  /*margin-bottom: 13px;*/
  border: 1px solid #ccc;
  padding: 13px;
  border-radius: 8px;
}
td.checkbt,
td.forget {
  display: inline-block;
}
td.forget a {
  cursor: pointer;
  color: #b3b3b3;
}
td.forget {
  float: right;
}
td.forget a:first-child::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 11px;
  background: #a7a7a7;
  margin: 0 6px 0 6px;
}
.checkbt .label {
  cursor: pointer;
  color: #9c9fa6;
  font-size: 15px;
}
.lotable input.check-w {
  width: 0;
  display: none;
  /*visibility: hidden;*/
}
.lotable input::placeholder {
  color: #c7c7c7;
}
.checkbt input[type="checkbox"] + span {
  border-radius: 3px;
  position: relative;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  width: 21px;
  height: 21px;
}
.checkbt input[type="checkbox"] + span.off {
  border: 1px solid #ccc;
  background: #fff;
}
.checkbt input[type="checkbox"] + span.on {
  background: #23cdb7 url("../img/check.png") no-repeat center;
}
.tdbutt {
  display: inline-block;
  margin-top: 15px;
  /* width: 50%; */
}
.checkbt,
.forget {
  display: inline-block;
}
.macheck {
  /*margin-bottom: 10px;*/
}
.forget {
  float: right;
}
.forget img {
  vertical-align: middle;
}
.watch {
  float: right;
}
.tdbutt td:first-child {
  margin-right: 10px;
}
.tdbutt .on {
  border: 1px solid #23cdb7;
  background: #23cdb7;
  color: #fff;
}
.tdbutt :disabled {
  background: grey !important;
  border: none;
  cursor: not-allowed;
}
.lobutt {
  color: #a3a3a3;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 180px;
  padding: 14px;
  font-size: 15px;
  display: inline-block;
}
/*회원가입 이메일*/
.input-email input.form-control {
  width: 40%;
}
.input-email input,
.input-email select {
  width: 28%;
}
.lotable select {
  box-sizing: border-box;
  padding: 13px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: right;
}

/*개인. 기업 회원가입*/
.alert-w {
  display: none;
}
.alert-w i {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: text-bottom;
  background: #ffeaea;
  text-align: center;
  border-radius: 50%;
  margin-right: 2px;
  font-size: 12px;
  color: #d92742;
}
.input-group .form-control {
  width: 80%;
}
.input-group-btn a {
  cursor: pointer;
  padding: 13px 0;
  display: inline-block;
  background: #23cdb7;
  border-radius: 8px;
  float: right;
  width: 19%;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
}
.input-time {
  width: 80%;
}
.input-time-t {
  border-top: 1px solid #ddd;
  padding-top: 13px;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.input-time-t2 {
  border-bottom: 3px solid #23cdb7;
  padding-bottom: 13px;
  display: block;
  font-weight: 600;
  color: #22ccb7;
  text-align: center;
}

.speech-bubble {
  position: relative;
  background: #ffffff;
  border-radius: 0.8em;
  top: 90%;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}
.speech-bubble:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -12px;
  margin-top: -12px;
}
.input-time p {
  display: inline-block; /*width: 1.2%;*/
}
.time-front {
  display: inline-block;
  width: 48% !important;
}
.time-end {
  display: inline-block;
  width: 48% !important;
}
.time-front input {
  width: 46% !important;
}
.time-end input {
  width: 46% !important;
}
.time-front span,
.time-end span {
  display: inline-block;
  width: 3%;
  padding: 0 0 0 3px;
  text-align: center;
  font-family: "s-core7";
}

/*개인. 기업 선택*/
.id_sear,
.join {
  color: #fff;
  background: #23cdb7;
  border: 1px solid #23cdb7;
}
/*.id_sear{
    width: 160px;
}*/
.selectwo {
  margin-top: 65px;
}
.selectwo .company {
  margin-right: 0;
  float: right;
}
.selectwo .person {
  margin-right: 5%;
  float: left;
}
.selectwo .person:hover,
.selectwo .company:hover {
  border: 1px solid #23cdb7;
}
.selectwo .person,
.selectwo .company {
  padding: 9% 0 0 0;
  text-align: center;
  width: 47%;
  height: 438px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  display: inline-block;
  border-radius: 8px;
}
.selectwo strong {
  display: block;
  font-weight: bold;
  font-size: 22px;
  margin: 35px 0 21px 0;
}
.selectwo span img {
  height: 100%;
}
.selectwo span {
  display: block;
  height: 74px;
}
/* .s-button.s-select {
  padding-top: 53px;
} */

/*레이어팝업*/
.modal-block-button {
  width: 100%;
  background: #22ccb7;
  font-size: 20px;
  padding: 16px;
  color: white;
}

.modal-w {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  /*opacity: 0;*/
}
.modal-w .layer-pop {
  border-radius: 6px;
  width: 400px;
  height: 300px;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  /*opacity: 0;*/
}
.layer-pop .head {
  box-sizing: border-box;
  width: 100%;
  height: 75px;
  padding: 25px 30px;
  overflow: hidden;
  background: #1ebda7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.layer-pop .head .close-btn {
  position: absolute;
  right: 15px;
}
.layer-pop .cont {
  padding: 4%;
}
.sub-cont .form {
  margin-bottom: 12px;
}
/*로그인,회원가입 팝업 (서브페이지는 css는 asset에)*/
.lotable {
  width: 100%;
  border: none !important;
}
.register-warning {
  color: #d92742;
  margin: 3px 0 0 0;
  display: inline-block;
}
.register-ok {
  color: #23cdb7;
  margin: 3px 0 0 0;
  display: inline-block;
}
.lotable input,
.id-complete,
.input-email select {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ccc;
  padding: 13px;
  border-radius: 8px;
}
/*id찾기*/
.idpass {
  margin-bottom: 30px;
}
.id_tit span {
  line-height: 40px;
  width: 48px;
  display: inline-block;
  padding: 7px;
  border-radius: 21px;
  text-align: center;
  height: 47px;
  background: #e4e4e4;
}
.id_tit span img {
  width: 32px;
}
.id_tit .textin {
  margin-left: 11px;
  display: inline-block;
}
.id_tit .text p {
  color: #818181;
  line-height: 22px;
}
.i-caution {
  color: #818181;
  margin: 10px 0 22px 13px;
}
.id_tit .text strong {
  margin-bottom: 5px;
  font-size: 17px;
  display: block;
  font-weight: bold;
}
.id_input input {
  margin-top: 3px;
  margin-bottom: 5px;
}
.id_sear,
.join {
  color: #fff;
  background: #23cdb7;
  border: 1px solid #23cdb7;
}
.id_width {
  margin-right: 10px;
  width: 160px;
}
.s-button.s-idpass {
  padding-top: 15px;
}
/*id 조회*/
.id-complete {
  background: #f3fffd;
  text-align: center;
}
.id-complete p {
  font-family: s-core5;
}
.id-complete span {
  color: #23cdb7;
  font-family: s-core3;
  vertical-align: top;
}
/*가입완료 팝업 및 가운데정렬들*/
.id_center {
  text-align: center;
}
.id_center .text {
  margin-bottom: 10px;
}
.id_center span {
  width: 70px;
  height: 70px;
  line-height: 60px;
  margin-bottom: 26px;
}
.id_center span img {
  width: 40px;
}
.id_center .text em {
  color: #23cdb7;
}
.id_tit .text strong.te_center {
  margin-bottom: 10px;
}
.input-email input {
  width: 27%;
}
.input-email select {
  width: 160px;
}

/*모바일 유틸리티*/
.mobi-util {
  display: none;
}
/*모바일 헤더*/
#mobi-head {
  display: none;
}

/*header*/
#header {
  position: relative;
  background: #414453;
  z-index: 300;
}
.headerin {
  display: flex;
  height: 80px;
}
.headerin ul {
  padding-right: 50px;
  margin-left: auto;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.headerin ul li {
  position: relative;
}
.headerin ul li a {
  margin-right: 1.2rem;
  margin-left: 1.2rem;
  display: flex;
  height: 80px;
  align-items: center;
}
.headerin ul li a img {
  margin-bottom: 20px;
  /* width: 86%;*/
  display: block;
}
.headerin ul li a em {
  color: #fff;
  width: 100%;
  display: block;
  font-size: 0.75rem;
  position: absolute;
  bottom: 17px;
  right: 50%;
  text-align: center;
  transform: translateX(50%);
}
.headerin ul li span {
  border: 2px solid #414453;
  color: #fff;
  background: #ff247e;
  padding: 0 5px;
  /*width: 16px;
    height: 16px;*/
  position: absolute;
  top: 14px;
  right: 12px;
  font-size: 0.7rem;
  text-align: center;
  line-height: 1rem;
  font-weight: bold;
  border-radius: 10px;
}

/*헤더 쪽지함팝업*/
.message-pop {
  position: absolute;
  z-index: 9999;
  display: none;
}
.message-pop .ptit2 {
  display: inline-block;
  height: 60px;
  line-height: 60px;
  font-size: 1.1rem;
  font-family: s-core4;
}
button.pbutton2 {
  margin-right: 10px;
  float: right;
  width: 40px;
  height: 40px;
}
.popconts button,
.message-pop button {
  vertical-align: middle;
  border: 0;
  /* background: transparent; */
  outline: 0;
  cursor: pointer;
}
.message-pop .picon {
  position: absolute;
  top: 0;
  right: 0;
  clear: both;
  margin-top: 10px;
  height: 60px;
}
.message-pop .pop-03 {
  background: #fff;
  box-sizing: border-box;
  width: 500px;
  height: 700px;
  border: 1px solid #ccc;
}

.message-pop .pop-04 {
  background: #fff;
  box-sizing: border-box;
  width: 500px;
  height: 552px;
  border: 1px solid #ccc;
}

.dropdown-menu .wrimess .searchcont a,
.message-pop .wrimess .searchcont a {
  background: #23cdb7;
  color: #fff;
  padding: 10px 15px;
  border-radius: 6px;
  display: inline-block;
}
.dropdown-menu .wrimess .searchcont.sea a,
.message-pop .wrimess .searchcont.sea a {
  text-align: right;
  float: right;
  margin-top: 10px;
}
.sendMailbtn {
  text-align: center;
  background-color: #7c7d80;
  padding: 12px;
  margin: 10px -20px 0;
}
.sendMailbtn button {
  width: 100%;
  color: #fff;
}
.dropdown-menu .wrimess .area,
.message-pop .wrimess .area {
  margin: 11px 0 7px;
  height: 200px;
  max-height: 220px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: auto;
  width: 100%;
}
.poimt.wrimess {
  padding: 0 20px;
}
.wrimess .search.attat {
  width: 100%;
  box-sizing: border-box;
  width: 458px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wrimess .search {
  border: 1px solid #ccc;
  padding: 9px;
  width: 267px;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-menu .checkbt,
.message-pop .checkbt {
  position: relative;
  /*margin-top: -16px;*/
  margin-right: 3px;
  display: inline-block;
  padding-left: 20px;
  display: inline-block;
}
.message-pop input {
  /* width: 0; */
  /*display: none;*/
}
.message-pop input[type="checkbox"] + span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  box-sizing: border-box;
  border-radius: 3px;
}
.message-pop input[type="checkbox"] + span.off {
  border: 1px solid #ccc;
  background: #fff;
}
.message-pop input[type="checkbox"] + span.on {
  background: #23cdb7 url("../img/check.png") no-repeat center;
}

.message-pop .me-page {
  padding: 0px 0 15px;
  /*padding:20px 15px 25px;*/
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}
.message-pop .me-page .me-r li {
  display: inline-block;
  margin: 0 2px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.message-pop .me-page .me-r li.on {
  background: #23cdb7;
}
.message-pop .me-page .me-r li.on a {
  color: #fff;
}
.message-pop .me-page .me-r li a {
  text-align: center;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #b5b5b5;
}
.message-pop .check-line {
  padding: 0px 15px;
  overflow: auto;
  background-color: #f7f6f5;
}
.message-pop .bt {
  vertical-align: baseline;
  display: inline-block;
}
.message-pop .check-line .me-l {
  float: right;
  text-align: right;
  display: inline-block;
  line-height: 2.3rem;
}
.message-pop .check-line .me-l .count {
  font-weight: bold;
  color: #23cdb7;
  vertical-align: baseline;
}
.message-pop .check-line .me-btn {
  display: inline-block;
  margin-left: 7px;
}
.message-pop .check-line .me-btn img {
  vertical-align: sub;
}
.message-pop .check-line .me-btn a {
  color: #a9a9a9;
  background: #f7f7f7;
  line-height: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px 12px;
  display: inline-block;
}
.message-pop .check-line .me-btn .one {
  margin-right: 5px;
}

.dropdown-menu .poimt.sendmess {
  height: 270px;
}
.sendmess .tit .date span {
  margin-left: 8px;
}
.sendmess .tit .name strong {
  font-weight: bold;
}
.sendmess .tit .name {
  display: inline-block;
}
.sendmess .tit .date {
  float: right;
}
.sendmess .tit {
  padding: 15px 24px;
  background: #989898;
  color: white;
}
.sendmess .cont {
  padding: 14px 24px;
  word-break: break-all;
  border-bottom: 1px solid #ccc;
}
.sendmess .cont {
  height: 400px;
  overflow: auto;
}
.searchee img {
  padding-right: 6px;
}
.searchee {
  padding: 11px 24px;
  border-bottom: 1px solid #ccc;
}
.sendmess .sencbtn {
  margin: 15px 12px 15px 0;
  text-align: center;
}
.sendmess .sencbtn a {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px 15px;
}
.sendmess .sencbtn a.re {
  background: #23cdb7;
  color: #fff;
  border: 1px solid #23cdb7;
  margin-left: 5px;
}
/*회원검색 모달*/
.modal-search.popconts {
  box-sizing: border-box;
  height: 507px;
  width: 393px;
  outline: 0;
  position: absolute;
  top: 180px;
  left: 500px;
  z-index: 9999;
  background: #fff;
}
.popconts .pop-02 .ptit.on {
  background: #23cdb7;
}
.popconts .pop-sea.pop-02 .ptit {
  padding: 0;
}
.popconts .pop-sea .ptit1 {
  font-size: 1.1rem;
  padding: 0 0 0 20px;
}
span.ptit1 {
  display: inline-block;
  height: 60px;
  line-height: 60px;
}
.picon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
}
.psearch {
  box-sizing: border-box;
  padding: 17px 0;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.psearch #search {
  display: inline-block;
  vertical-align: middle;
  width: 82%;
  font-size: 1rem;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.psearch span {
  display: inline-block;
  height: 30px;
  width: 13%;
  vertical-align: middle;
}
.psearch .inputsearch {
  top: 12px;
  right: 10px;
}
.psearch .searchbtn {
  background-size: 34px !important;
}
.pop-sea .poimt {
  height: 329px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
  border-top: none;
}
.pop-sea .popscroll {
  height: 240px;
}
.popscroll {
  overflow-x: hidden;
  overflow-y: auto;
  overflow: auto;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
#chat_table_132 {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  padding: 0 10px;
}
.chatchat_132 {
  height: 318px;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-top: none;
  border-bottom: none;
}
.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #cdf2ed;
  border-color: #cdf2ed;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #707070;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #cdf2ed;
  border-color: #cdf2ed;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.popconts .pop-02 .popimg {
  padding: 9px 0 9px 20px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.modal-search.popconts .popimg span {
  width: 13%;
  height: 43px;
  background-size: 81% auto;
}
.popconts .popimg span {
  display: inline-block;
  margin-right: 2%;
  background-size: 40px 40px;
  height: 62px;
  width: 11%;
  vertical-align: middle;
}
.popconts .pop-02 .popit {
  vertical-align: middle;
  display: inline-block;
}
.popconts dl {
  font-size: 0.95rem;
  overflow: hidden;
}
.popconts dd {
  color: #c8c8c8;
}

/*헤더 유틸리티 팝업*/
.pop-01 {
  border-radius: 8px;
  position: absolute;
  top: 80px;
  left: -250px;
  background: #fff;
  width: 425px;
  border: 1px solid #ccc;
  z-index: 1;
}
/*헤더 화상채팅용 드롭다운*/
.pop-01-1 {
  border-radius: 8px;
  position: absolute;
  top: 80px;
  left: -246px;
  background: #fff;
  width: 425px;
  border: 1px solid #ccc;
  z-index: 1;
}
.dropdown-menu .pop-arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
  content: "";
}
.dropdown-menu .pop-arrow {
  z-index: 2;
  border-width: 12px 10px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 73px;
  left: 50%;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.message-pop .ptit {
  color: #fff;
  padding: 0 0 0 20px;
  width: 100%;
  background: #23cdb7;
  position: relative;
  height: 60px;
  box-sizing: border-box;
  font-size: 1.1rem;
}
.dropdown-menu .ptit {
  box-sizing: border-box;
  height: 60px;
  padding: 20px 0 20px 15px;
  font-weight: bold;
  font-size: 1.1rem;
  color: #23cdb7;
  border-bottom: 2px solid #23cdb7;
}
.message-pop .modal-search .poimt {
  height: 435px;
  overflow: hidden;
  background: #fff;
}
.message-pop .modal-search .popimg a {
  padding: 15px 0 15px 20px;
}
.message-pop .poimt {
  /* height: 300px; */
}
/*쪽지함컨텐츠 따로 스크롤*/
.message-pop .poimt-cont {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  height: 357px;
}
.dropdown-menu .poimt {
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  height: 383px;
}
.dropdown-menu .poimess,
.message-pop .popimg a {
  height: 318px;
}
.dropdown-menu .popimg,
.message-pop .popimg {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.message-pop .popimg a {
  width: 89%;
  height: 80px;
  padding: 20px 0;
}
.dropdown-menu .popimg a {
  width: 100%;
  padding: 20px 20px;
}
.dropdown-menu .popimg a,
.message-pop .popimg a {
  margin: inherit;
  /*padding: 20px 0;*/
  /*height:40px;*/
  display: inline-block;
  box-sizing: border-box;
}
.dropdown-menu .popimg .popit,
.message-pop .popimg .popit {
  font-size: 0.96rem;
  width: 85%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.message-pop .popimg .msg-unread-img {
  background: url(../img/msg_unread.svg) no-repeat left;
  padding-left: 50px;
  line-height: 37px;
}
.message-pop .popimg .msg-read-img {
  background: url(../img/msg_read.svg) no-repeat left;
  padding-left: 50px;
  line-height: 37px;
}
.dropdown-menu .popimg span,
.message-pop .popimg span {
  position: inherit;
  border: 0;
  margin-right: 30px;
  height: 36px;
  width: 9%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: inherit;
  vertical-align: top;
}
span.pitxt {
  line-height: 36px !important;
}
.dropdown-menu .popimg.popchat span {
  background-size: 80% auto;
}
.dropdown-menu .popimg.popsign span,
.message-pop .popimg.popsign span {
}
.dropdown-menu .popimg.noread span,
.message-pop .popimg.noread span {
}
.dropdown-menu .popimg.read span,
.message-pop .popimg.read span {
}
.dropdown-menu .popimg.noread .popit dt,
.message-pop .popimg.noread .popit dt {
  color: #23cdb7;
}
.message-pop .modal-search .popit dd,
.message-pop .modal-search .popit dt {
  display: block;
  width: 100%;
}
.message-pop .modal-search .popit dd i {
  margin: -2px 6px 0 0;
  vertical-align: top;
  display: inline-block;
}
.dropdown-menu .pop-01 .popit li.ri {
  display: inline-block;
  color: #a9a9a9;
}
.dropdown-menu .pop-01 .popit .li {
  white-space: nowrap;
  width: 214px;
  max-width: 73%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  font-weight: bold;
}
.dropdown-menu .popimg.popchat p {
  margin-top: 3px;
  max-width: 73%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-menu .popimg.popchat .count {
  width: initial;
  color: #fff;
  background: #ff247e;
  position: absolute;
  bottom: 1px;
  right: 2px;
  font-size: 0.7rem;
  text-align: center;
  line-height: 0.4rem;
  border-radius: 10px;
  margin-top: 0;
  padding: 7px;
  height: 18px;
  box-sizing: border-box;
}
.dropdown-menu .popit dd,
.message-pop .popit dd {
  color: #a9a9a9;
  display: inline-block;
}
.dropdown-menu .popit dt,
.message-pop .popit dt {
  display: inline-block;
}
.dropdown-menu .popit .noline,
.message-pop .popit .noline {
  /* line-height: 1.4rem; */
  float: right;
}
/* .dropdown-menu .popit dd.line,
.message-pop .popit dd.line {
  float: right;
} */
.dropdown-menu .popit em.read,
.message-pop .popit em.read {
  margin-left: 4px;
  color: #848484;
  padding: 4px 5px;
  font-size: 0.73rem;
  background: #f4f4f4;
  border-radius: 8px;
  box-sizing: border-box;
}
.dropdown-menu .popit .time,
.message-pop .popit .time {
  margin-left: 10px;
  font-size: 0.85rem;
}
.dropdown-menu .pbtn,
.message-pop .pbtn {
  border-top: 1px solid #ccc;
  background: #f7f7f7;
  padding: 0;
}
.dropdown-menu .pbtn a,
.message-pop .pbtn a {
  height: inherit;
  padding: 15px;
  display: block;
  text-align: center;
  margin: 0;
}
.dropdown-menu ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.message-pop ul {
  overflow: hidden;
}
/*
.dropdown-menu .ptab li{
    display: inline-block;
    width: 33.3%;
    margin: 0 auto;
    text-align: center;
}
 */
.message-pop .ptab ul li {
  float: left;
  width: 25%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.dropdown-menu .ptab li a,
.message-pop .ptab {
  border-bottom: 1px solid #eee;
}
.message-pop .ptab li a {
  position: relative;
  height: 50px;
  margin: 0;
  display: inline-flex;
  font-weight: bold;
  align-items: center;
}
.dropdown-menu .ptab li.on a,
.message-pop .ptab li.on a {
  color: #23cdb7;
}
.dropdown-menu .ptab li.on a:after,
.message-pop .ptab li.on a:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 15px;
  display: block;
  height: 3px;
  background: #23cdb7;
}
.message-pop .pnomess {
  display: table;
  text-align: center;
  width: 100%;
}
.dropdown-menu .pnomess,
.dropdown-menu .pnoalarm {
  display: table;
  text-align: center;
  width: 100%;
}
.dropdown-menu .no-ico,
.message-pop .no-ico {
  vertical-align: middle;
  padding-bottom: 10px;
  display: table-cell;
}
.dropdown-menu .no-ico p,
.message-pop .no-ico p {
  padding-top: 17px;
  color: #d9d9d9;
}
/*프로필 알림창*/
.dropdown-menu .pro {
  margin-left: 0;
  position: absolute;
  left: 70px;
  top: 80px;
  transform: rotate(-45deg);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 12px;
  height: 12px;
  border-left-color: #fff;
  border-bottom-color: #fff;
  background: #fff;
  z-index: 2;
}
.pop-pro {
  background: #fff;
  border: 1px solid #ccc;
  width: 171px;
  position: absolute;
  right: -136px;
  top: 87px;
  z-index: 1;
  border-radius: 8px;
}
.pop-pro .p01 i {
  margin-right: 6px;
  display: inline-block;
  vertical-align: text-bottom;
}
.pop-pro .p01 span {
  display: inline-block;
}
.pop-pro .logout a {
  display: block;
  padding: 13px;
  color: #23cdb7;
}
.pop-pro .logout {
  border-top: 1px solid #ccc;
  text-align: center;
}
.pop-pro .p01.p03 a {
  /*padding-top: 22px;*/
  padding-top: 12px;
}
.pop-pro .p01.p02 a {
  padding-bottom: 22px;
}
.pop-pro .p01 a {
  padding: 10px 24px;
  display: block;
}
/*알림창 끝*/

/*유틸리티 - 환경설정(기업)*/
.pop_radio {
  display: inline-block;
  position: relative;
  margin-right: 9px;
}
.pop_radio input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  border: 0;
}
.pop_radio input[type="radio"] + label {
  padding-left: 20px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.pop_radio input[type="radio"]:checked + label:before {
  background: #ebfffd;
  border-color: #23cdb7;
}
.pop_radio input[type="radio"] + label:before {
  left: 0;
  top: 0;
  position: absolute;
  border: 1px solid #ddd;
  content: "";
  background: #fafafa;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  text-align: center;
}
.pop_radio input[type="radio"]:checked + label:after {
  left: 4px;
  top: 4px;
  position: absolute;
  content: "";
  background: #23cdb7;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

/*관리자-회원관리*/
.pop_person th {
  background: #f7f7f7;
}
.pop_person th,
.pop_person td {
  line-height: 3rem;
}
.pop_person th,
.pop_person td {
  padding: 2px 11px;
  border-bottom: 1px solid #ddd;
}
.pop_person tr:last-child td {
  padding: 10px 11px 0 11px;
  height: 74px;
}
.pop_person td textarea {
  height: 54px;
}
.pop_person input {
  height: 44px;
}

/*컨테이너*/
div#chat_postion {
  padding-left: 80px;
}
/*모바일 메뉴*/
.m_nav {
  display: none;
}
/*메뉴*/
#left_nav {
  box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.1);
  width: 235px;
  height: 100%;
  overflow-y: hidden;
  z-index: 400;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.nav-profi {
  width: 235px;
  box-sizing: border-box;
  padding: 0 16px;
}
.lome {
  height: 80px;
}
.nav-profi .logo a {
  display: block;
  padding: 42px;
  box-sizing: border-box;
}
.nav-profi .logo {
  float: left;
  width: 70%;
  height: 80px;
}
.c_name {
  color: #fff;
  display: inline-block;
  margin-top: 30px;
  margin-left: 251px;
  font-size: 15px;
}

.promy {
  margin: 39px 0 10px 0;
}
.profile {
  height: 100px;
  width: 35%;
  position: relative;
  float: left;
}
.profile img {
  width: 100%;
}
.profile .set {
  position: absolute;
  bottom: 20px;
  right: -4px;
}
.profile .set a {
  border: 1px solid #ccc;
  display: block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.myinfo {
  margin: 10% 0 0 6%;
  float: left;
  width: 50%;
}
.myinfo h2 {
  color: #1ebda7;
  font-size: 0.85rem;
}
.myinfo h3 {
  font-size: 1rem;
}

/*.nav-ulall{
    position: relative;
}*/

.nav-ul {
  overflow-x: hidden;
  overflow-y: auto;
  overflow: auto;
  width: 235px;
  height: 600px;
}
.nav-ul .nav-ull > li {
  /*height: 40px;*/
  /* line-height: 46px;*/
}
.nav-ul .nav-ull > li a {
  padding: 13px 0 13px 38px;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.nav-ul ul li a {
  color: #454545;
  font-size: 0.97rem;
  line-height: 1.1rem;
}
.nav-ul .nav-ull > li a img {
  display: inline-block;
  width: 18px;
  vertical-align: middle;
}
.nav-ul .nav-ull > li a span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}
.nav-ul .innav {
  display: none;
  height: auto;
}

.nav-ul .nav-ull > li:hover:before {
  display: none;
  content: "";
  /* display: inline-block;*/
  vertical-align: middle;
  box-sizing: border-box;
  width: 5px;
  height: 46px;
  background: #23cdb7;
}
.nav-ul .nav-ull > li:hover {
  background: #d1fcf7;
}
.nav-ul .nav-ull > li:hover .innav {
  /*  display: block; */
}
.nav-ul .nav-ull .innav ul {
  background: #ebfffd;
}
.nav-ul .nav-ull .innav a {
  padding-left: 74px;
}
.nav-ul .nav-ull > li .innav a {
  padding: 10px 0 10px 74px;
}
.nav-ul .nav-ull > li .innav a:hover {
  color: #23cdb7;
}
/*메뉴설정버튼 추가*/
.menu_set {
  border-top: 1px solid #dcdcdc;
  text-align: left;
  width: 235px;
  box-sizing: border-box;
  padding: 13px 0 13px 38px;
  position: fixed;
  bottom: 0px;
  /*background: #eee;*/
}
.menu_set span {
  font-size: 15px;
  vertical-align: middle;
  display: inline-block;
  margin-left: 12px;
  color: #949494;
}
.copy {
  display: none;
  width: 100%;
  bottom: 41px;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  color: #ccc;
  font-size: 0.8rem;
}

/*퀵메뉴*/
.quick-nav {
  z-index: 100;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.quick-nav a {
  font-size: 13px;
  font-family: s-core4;
  text-align: center;
  height: 80px;
  width: 80px;
  box-shadow: 0px 3px 1px #bebebe;
  /* background: #414453;*/
  background: #fd669f;
  padding: 15px 0;
  display: block;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 50%;
}

.quick-nav a.access {
  background: #4e89eb;
}

.quick-nav img {
  height: 27px;
}
.quick-nav p {
  margin-top: 5px;
}

/* 컨텐츠*/
#container {
  width: 100%;
  /* background: #ccc;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 23px 20px 185px;
}
.checklist {
  position: relative;
  box-sizing: border-box;
}
.calen {
  position: relative;
  padding-bottom: 12px;
  font-size: 1.2rem;
}

.calen span {
  vertical-align: middle;
  display: inline-block;
}
.calen .people-li {
  font-size: 1rem;
  /*float: right;*/
  display: inline-block;
}
.calen .people,
.calen .nopeople {
  display: inline-block;
}
.calen .people-li .ico {
  display: inline-block;
}
.calen .people .ico {
  padding-left: 0;
  background-size: 19px;
  margin-left: 0;
  display: inline-block;
  width: 30px;
  height: 27px;
}
.calen .nopeople .ico {
  padding-left: 0;
  background-size: 19px;
  margin-left: 0;
  display: inline-block;
  width: 17px;
  height: 27px;
}
.calen .people .color {
  color: #23cbd7;
}
.calen .nopeople .color a {
  color: #f066c4;
}
.calen .nopeople {
  margin-left: 6px;
  box-shadow: 2px 2px 3px 0px #6e5e5e2e;
  padding: 5px 10px;
  border-radius: 13px;
  background: #fff6f9;
  box-sizing: border-box;
}

.calen i {
  margin-left: 11px;
  font-family: s-core5;
}
.calen i::before {
  content: "";
  display: inline-block;
  clear: both;
  vertical-align: text-top;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.calen i.cal-on {
  color: #f58962;
}
.calen i.cal-off {
  color: #007ae8;
}
.calen i.cal-no {
  /*color:#454545;*/
  color: #fd669f;
}

.calen i.cal-on::before {
  background: #f58962;
}
.calen i.cal-off::before {
  background: #007ae8;
}
.calen i.cal-no::before {
  /*background:#454545;*/
  background: #fd669f;
}

/*달력오픈*/
.calenopen {
  z-index: 1;
  display: none;
  border: 1px solid #ccc;
  font-size: 0.98rem;
  width: 305px;
  position: absolute;
  background: #fff;
  border-radius: 10px;
  top: 0;
  left: 9.1%;
}

.calen .nopeople {
  margin-left: 6px;
  box-shadow: 2px 2px 3px 0px #6e5e5e2e;
  padding: 5px 10px;
  border-radius: 13px;
  background: #fff6f9;
  box-sizing: border-box;
}

.calen .nopeople .ico {
  padding-left: 0;
  background-size: 19px;
  margin-left: 0;
  display: inline-block;
  width: 17px;
  height: 27px;
}

/*기업용 출퇴근상황 팝업*/
.dropdown-menu .main-pop {
  left: 52px;
}
.pop-pro.main-popcont {
  width: 250px;
  right: -175px;
  height: 227px;
}
.pop-pro.main-popcont .ptit {
  height: 43px;
  padding: 10px 0 0 0;
  text-align: center;
}
.main-pop-w {
  padding: 10px 5px;
}
.image-ico {
  padding-top: 35px;
  text-align: center;
}
.image-ico p {
  margin-top: 15px;
  color: #ddd;
}
.pop-in-main {
  height: 160px;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  display: block;
}
.main-tit {
  text-align: right;
  margin-bottom: 9px;
  color: #b7b7b7;
}
.main-tot {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 17px;
}
.main-tot span {
  margin: 0 4px 0 3px;
  color: #454545;
  font-family: s-core2;
  font-size: 14px;
}
.pop-in-main thead {
  font-family: s-core5;
}
.pop-in-main thead th {
  color: #797979;
  border-bottom: 1px solid #ddd;
  padding: 10px 0 10px 0;
  font-size: 12px;
}
.pop-in-main thead th:first-child {
  display: block;
  margin-right: 10px;
}
.pop-in-main tbody th {
  padding: 10px 0 0 5px;
  font-size: 16px;
  font-family: s-core5;
  /*width: 110px;*/
}
.pop-in-main tbody td span {
  width: 108px;
}
.pop-in-main tbody th span {
  width: 107px;
  margin-right: 10px;
}
.pop-in-main tbody td {
  color: #b7b7b7;
  font-size: 13px;
  padding: 10px 0 0 0;
}
.pop-in-main tbody span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
/*기업용상단상태*/
.cal-firm {
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 10px;
  vertical-align: middle;
}
.cal-firm li {
  text-align: center;
  float: left;
  border-right: 1px solid #ccc;
  width: 25%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.8rem 0 0 0;
  position: relative;
}
.cal-firm li:last-child {
  border-right: 0;
}
.cal-firm li span {
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 80px;
  height: 80px;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 5rem;
}
.cal-firm .num strong {
  font-size: 2rem;
  font-weight: bold;
}
.cal-firm .firm1 .num strong {
  color: #4e89eb;
}
.cal-firm .firm1 span {
  background: #4e89eb;
}
.cal-firm .firm2 .num strong {
  color: #16d18d;
}
.cal-firm .firm2 span {
  background: #16d18d;
}
.cal-firm .firm3 .num strong {
  color: #ff834c;
}
.cal-firm .firm3 span {
  background: #ff834c;
}
.cal-firm .firm4 .num strong {
  color: #f066c4;
}
.cal-firm .firm4 span {
  background: #f066c4;
}
.cal-firm .num {
  padding-left: 11%;
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}

.cal-tit {
  text-align: center;
  box-sizing: border-box;
  padding-top: 5px;
  height: 40px;
  background: #23cdb7;
  border-radius: 10px 10px 0 0;
}
.cal-tit a .pre-ar,
.cal-tit a .next-ar {
  display: none;
}
.cal-tit a .pre-ar1 {
  transform: scaleX(-1);
}
.cal-tit a:hover {
  background: #1ebda7;
}
.cal-tit a:hover .pre-ar1,
.cal-tit a:hover .next-ar1 {
  display: none;
}
.cal-tit a:hover .pre-ar,
.cal-tit a:hover .next-ar {
  display: inline-block;
}
.cal-tit a:hover .next-ar {
  transform: scaleX(-1);
}
.cal-tit a {
  border-radius: 50%;
  background: #f4f4f4;
  width: 25px;
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  padding: 2px;
}
.cal-tit h5 {
  color: #fff;
  vertical-align: middle;
  display: inline-block;
  width: 33.3%;
  text-align: center;
}
.cal-cont {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin: 12px 0;
}
.cal-cont td a {
  padding: 0.2rem 0;
  display: inline-block;
}
.cal-cont td.t0 a {
  color: #ff1919;
}

.calen .mon {
  vertical-align: top;
  display: inline-block;
}

.cal {
  /*3차수정 height: 140px;
    border: 1px solid #ccc;*/
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
}
.cal01 li .icocal {
  margin-top: 25px;
  /* margin-right: -15px; */
  display: inline-block;
  background-size: 100%;
  background-position: cover;
  background-repeat: no-repeat;
  /* background-position: bottom -25px right -25px; */
  /* border-radius: 10px; */
  width: 40%;
  height: 110%;
  /*width: 82px;
    height: 80px;*/
  /*3차수정
    background-size: 100% auto;
    width: 62px;
    height: 64px;*/
  /* vertical-align: middle; */
  float: right;
}

.cal01 .work-t {
  width: 100%;
  border: none;
  overflow: hidden;
  background-color: #22ccb7;
}

.cal01 .late {
  width: 100%;
  border: none;
  overflow: hidden;
  background-color: #1a5c96;
}
.cal01 .absen {
  width: 100%;
  border: none;
  overflow: hidden;
  background-color: #fd9f9f;
}

.cal01 .holly {
  width: 100%;
  border: none;
  overflow: hidden;
  background-color: #8e8e8e;
}

.cal01 .work-t-u {
  width: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  color: #22ccb7;
}

.cal01 .late-u {
  width: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  color: #1a5c96;
}
.cal01 .absen-u {
  width: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  color: #fd9f9f;
}

.cal01 .holly-u {
  width: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  color: #8e8e8e;
}

.cal01 .work-t .icocal {
  background-image: url(../img_new/index_img_1.svg);
}
.cal01 .late .icocal {
  background-image: url(../img_new/index_img_2.svg);
}
.cal01 .absen .icocal {
  background-image: url(../img_new/index_img_3.svg);
}

.cal01 .holly .icocal {
  background-image: url(../img_new/index_img_4.svg);
}
.cal01 .work-t-u .icocal {
  background-image: url(../ico/icon_출근인원_근로자.svg);
}
.cal01 .late-u .icocal {
  background-image: url(../ico/icon_지각인원_근로자.svg);
}
.cal01 .absen-u .icocal {
  background-image: url(../ico/icon_미출근자_근로자.svg);
}

.cal01 .holly-u .icocal {
  background-image: url(../ico/icon_휴가인원_근로자.svg);
}
.cal01 .holly .icocal .hol_t {
  text-align: center;
  width: 100%;
  color: #169c8b;
  position: absolute;
  left: 0;
  bottom: 1px;
}
.cal01 .holly .hol_tm {
  display: none;
}

.cal01 .work-t .worktist {
}
.clist {
  float: left;
  width: 60%;
  padding-top: 2%;
  display: inline-block;
  vertical-align: middle;
  /*padding-left: 8%;*/
}
.holist.clist {
  padding-top: 0%;
}

.clist strong {
  font-family: "s-core7";
  margin-right: 5px;
  font-size: 2rem;
}
.cal01 .holist strong {
  font-family: s-core6;
  font-size: 1.6rem;
  margin-left: 5px;
}
.cal01 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 18px;
  color: white;
  height: 41%;
  width: 100%;
  margin-bottom: 20px;
}
.cal01 li {
  box-sizing: border-box;
  height: 140px;
  float: left;
  width: 49%;
  padding: 25px 24px;
  padding-right: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  /*3차수정
      text-align: center;
      width: 25%;
    height: 100%;
    padding:2.2rem 0 0 0;*/
}
/* .cal01 li:last-child { */
/*3차수정 border:0;*/
/* padding: 23px 24px;
} */
.holist.clist p {
  line-height: 1.8rem;
}
.cal01 .clist span {
  width: 100%;
  font-size: 0.95rem;
  display: block;
  text-align: left;
  padding-left: 2%;
}

.cal02 {
  width: 24%;
  position: relative;
  padding: 1.8rem 0 0 3%;
  /*text-align: center;*/
}
.cal02 .holist {
  padding-left: 10%;
  display: inline-block;
  vertical-align: middle;
}
.cal02 .holly .holico {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  vertical-align: middle;
  display: inline-block;
}
.cal02 .holly .holt {
  position: absolute;
  top: 10px;
  right: 42px;
  color: #23cdb7;
}
.cal02 .holist p {
  text-align: left;
  line-height: 1.5rem;
}
.cal02 .holist stro.cal01ng {
  font-size: 1.3rem;
  font-weight: bold;
  color: #23cdb7;
  line-height: 1.1rem;
}

.checkw1 {
  /*기업용전체*/
  margin-top: 1.7%;
}
.checkcont {
  margin-right: 20px;
  width: 67%;
  float: left;
}
.main-card {
  border: 1px solid #eee;
  padding: 15px 25px;
  border-radius: 10px;
}

.check {
  box-sizing: border-box;
  /*float:left;
    height: 370px;*/
  vertical-align: middle;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px 25px;
}
.check h2 {
  float: left;
  font-weight: bold;
  font-size: 1.3rem;
}
.check-t {
  padding: 7px 0;
}
.check span {
  float: right;
}
.check span a {
  color: #d2d2d2;
  font-size: 0.9rem;
  vertical-align: middle;
}
.check span i {
  padding: 0 31px 0 0;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
}
.worktea {
  /* display: flex;
  flex-direction: column; */
}
.w-100 {
  width: 100% !important;
}
.worknews1 {
  /*width: 43%;
    float: left;*/
  /*margin-right: 1.8%;*/
  /*2차수정 height: 47.5%;*/
  display: none;
}
.worknews {
}
.checkcont1 {
  height: 514px;
  width: 74%;
  float: left;
  margin-right: 1.8%;
}
.main-box {
  display: grid;
  grid-template-columns: 68fr 35fr;
  grid-template-rows: auto auto;
  justify-content: space-between;
  column-gap: 20px;
}
.check01 {
  margin-bottom: 20px;
  /*기업용*/
}
.check01-1 {
  float: left;
}
.check01-3 {
  width: 100%;
}

.check01-0 {
  float: left;
}
.check01-4 {
  width: 100%;
  float: right;
}

.check-margin {
  margin-bottom: 20px;
}

.worktea1 {
}
.worktea1 ul {
  height: 195px;
  overflow: hidden;
}
.worktea-dl {
  display: inline-block;
  width: 100%;
}
.worktea-dl > dt {
  float: left;
}

.worktea-dl > dd {
  float: right;
  margin-bottom: 12px;
}

.worktea {
  /*기업용*/
  height: 100%;
  float: left;
  width: 48.5%;
}

.require {
  height: 290px;
  overflow: hidden;
}
.workboard1 {
  /*기업용*/
  height: 100%;
  float: left;
  width: 49%;
  margin-left: 2.5%;
}
.workboard {
  /*height: 56.5%;
    margin-top: 7%;*/
}
.workboard ul {
  height: 251px;
  overflow: hidden;
}
.card-t {
  margin-top: 9px;
  display: inline-block;
  height: 280px;
  width: 57%;
  overflow: hidden;
}
.card-t1 {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}
.card-t1 li {
  padding: 6px 0 !important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ccc;
}
.card-t1 li:last-child {
  border-bottom: 0 !important;
}
.card-t1 li strong {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  box-sizing: border-box;
  background: #cecece;
  padding: 8px 13px;
  border-radius: 10px;
}
.card-big {
  float: left;
  height: 100%;
  vertical-align: top;
  width: 64%;
}
.card-big a {
  display: block;
}
.card-big img {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.worknews .card-news {
  height: 254px;
  float: left;
  vertical-align: top;
  width: 30%;
  margin-left: 4%;
  overflow: hidden;
}
.card-news li:first-child {
  margin-bottom: 13%;
}
/*.card-news .tit{
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}*/
.card-news1 {
  padding: 14px 0;
  margin-bottom: 10px;
}
.card-news1 a {
  display: block;
}
.card-news1 .img-cd {
  width: 60%;
  margin-right: 3%;
  display: inline-block;
}
.card-news1 .img-cd img {
  width: 100%;
}
.card-big .tit-t {
  font-size: 15px;
  width: 100%;
  vertical-align: top;
  display: inline-block;
}
.card-big .tit {
  margin-top: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-news li {
  height: 47%;
  padding: 0 !important;
  box-sizing: border-box;
  border-bottom: 0 !important;
}
.card-news li a {
  display: inline-block;
  /* background: #ddd;*/
  height: 100%;
}
.card-news li img {
  /*width: 23%;
     float: left;*/
  height: 100%;
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
/*.card-news .tit-t{
    width: 50%;
    float: left;
    margin-left: 5%;
}*/

.check li {
  font-size: 0.96rem;
  padding: 14px 0;
  border-bottom: 1px solid #ccc;
}
.check li:last-child {
  border-bottom: 0;
}
.check ul dl dd {
  float: right;
  color: #b9b9b9;
}
.check ul dl dt {
  float: left;
  width: 60%;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.check i.newi {
  margin-left: 3px;
  width: 16px;
  height: 21px;
  display: inline-block;
}
/*기업메인 결재요청*/
.require li {
  padding: 0;
  border-bottom: 0;
  float: left;
}
.require ul {
  padding: 14px 0;
  border-bottom: 1px solid #ccc;
}
.require .tit {
  width: 33.3%;
}
.require .date,
.require .btn {
  text-align: right;
  width: 33%;
  float: left;
}
.require .btn a {
  display: inline-block;
  padding: 6px 13px;
  border-radius: 6px;
  box-sizing: border-box;
  color: #fff;
}
.require .bt1 a {
  background: #b5b5b5;
}
.require .bt2 a {
  background: #f9af2d;
}
.require .bt3 a {
  background: #fd669f;
}
.require .bt4 a {
  background: #23cdb7;
}

.check02 {
  display: none;
  margin-top: 2.2%;
}
.check02 li {
  height: 130px;
  width: 31.6%;
  float: left;
  /*border: 1px solid #000;*/
  border-radius: 10px;
  margin-right: 2.6%;
  text-align: center;
  box-sizing: border-box;
}
.check02 li a {
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 0.5rem 2rem 0.5rem;
  display: inline-block;
}
.check02 li:last-child {
  margin-right: 0;
}
.check02 li i {
  vertical-align: middle;
  padding: 10px;
  display: inline-block;
}
.check02 li .text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 33px 0 20px;
  text-align: left;
  color: #fff;
}
.check02 li .text p {
  font-size: 1.2rem;
}
.check02 li .text span {
  font-size: 0.9rem;
  font-family: s-core3;
}
.check02 .mana1 {
  background: linear-gradient(
    40deg,
    rgba(245, 137, 98, 1),
    rgba(252, 159, 66, 1),
    rgba(252, 159, 66, 1)
  );
  background: -webkit-linear-gradient(
    40deg,
    rgba(245, 137, 98, 1),
    rgba(252, 159, 66, 1),
    rgba(252, 159, 66, 1)
  );
  background: -moz-linear-gradient(
    40deg,
    rgba(245, 137, 98, 1),
    rgba(252, 159, 66, 1),
    rgba(252, 159, 66, 1)
  );
  background: -o-linear-gradient(
    40deg,
    rgba(245, 137, 98, 1),
    rgba(252, 159, 66, 1),
    rgba(252, 159, 66, 1)
  );
  background: -ms-linear-gradient(
    40deg,
    rgba(245, 137, 98, 1),
    rgba(252, 159, 66, 1),
    rgba(252, 159, 66, 1)
  );
}
.check02 .mana2 {
  background: linear-gradient(
    40deg,
    rgba(36, 191, 183, 1),
    rgba(65, 227, 151, 1)
  );
  background: -moz-linear-gradient(
    40deg,
    rgba(36, 191, 183, 1),
    rgba(65, 227, 151, 1)
  );
  background: -webkit-linear-gradient(
    40deg,
    rgba(36, 191, 183, 1),
    rgba(65, 227, 151, 1)
  );
  background: -o-linear-gradient(
    40deg,
    rgba(36, 191, 183, 1),
    rgba(65, 227, 151, 1)
  );
  background: -ms-linear-gradient(
    40deg,
    rgba(36, 191, 183, 1),
    rgba(65, 227, 151, 1)
  );
}
.check02 .mana3 {
  background: linear-gradient(
    40deg,
    rgba(229, 108, 213, 1),
    rgba(252, 96, 177, 1)
  );
  background: -webkit-linear-gradient(
    40deg,
    rgba(229, 108, 213, 1),
    rgba(252, 96, 177, 1)
  );
  background: -ms-linear-gradient(
    40deg,
    rgba(229, 108, 213, 1),
    rgba(252, 96, 177, 1)
  );
  background: -o-linear-gradient(
    40deg,
    rgba(229, 108, 213, 1),
    rgba(252, 96, 177, 1)
  );
  background: -moz-linear-gradient(
    40deg,
    rgba(229, 108, 213, 1),
    rgba(252, 96, 177, 1)
  );
}
.check02 .mana1 .ico {
}
.check02 .mana2 .ico {
}
.check02 .mana3 .ico {
}
.check02 .mana2 .ico.i02 {
}
.check02 .mana3 .ico.i03 {
}
.check02 li .ico {
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 31px;
  border-radius: 15px;
  vertical-align: middle;
  display: inline-block;
}
.checkcal1 {
  /*기업용달력*/
  width: 24%;
  height: 514px;
  float: left;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px 25px;
}
.title-h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 6px;
}
.total-people {
  width: 28%;
  /* float: left; */
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  display: flex;
  vertical-align: middle;
  border: 1px solid #22ccb7;
  border-radius: 10px;
  padding: 15px 25px;
  margin-bottom: 10px;
  color: #1baf9d;
  background-color: #f4fcfb;
  font-size: 18px;
}
.annual-leave {
  width: 28%;
  /* float: left; */
  text-align: center;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  vertical-align: middle;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 14px;
}

.checkcal {
  width: 28%;
  /* height: 670px; */
  /*height: 526px;*/
  float: left;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px 25px;
}

.checkmain {
  width: 28%;
  /* height: 670px; */
  /*height: 526px;*/
  display: none;
}
.monthtop {
  position: relative;
}
.monthtop h4 {
  font-size: 1.3rem;
  font-weight: bold;
}
.table-month {
  position: relative;
  text-align: center;
  margin: 1rem 0 0 0;
}
.table-month a {
  display: inline-block;
  position: absolute;
  background: #f4f4f4;
  padding: 6px 2px 4px 2px;
  width: 25px;
  vertical-align: middle;
  border-radius: 50%;
}
.table-month a:hover {
  background: #23cdb7;
}
.table-month h5 {
  font-weight: bold;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}
.table-month .pre-ar,
.table-month .next-ar {
  display: none;
}
.table-month a.pre-mo:hover .pre-ar {
  display: inline-block;
  transform: scaleX(-1);
}
.table-month a.pre-mo:hover .pre-ar1 {
  display: none;
}
.table-month a.next-mo:hover .next-ar {
  display: inline-block;
  transform: scaleX(-1);
}
.table-month a.next-mo:hover .next-ar1 {
  display: none;
}
.table-month a.pre-mo img,
.table-month a.next-mo img {
  vertical-align: top;
}
.table-month a.pre-mo {
  transform: scaleX(-1);
  top: 0;
  left: 0;
}
.table-month a.next-mo {
  top: 0;
  right: 0;
}
.monthbot {
  table-layout: fixed;
  margin-top: 18px;
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}
.monthbot td.t0 a {
  color: #ff1919;
}
.monthbot td {
  position: relative;
}
.monthbot td a {
  padding: 0.5rem 0;
  display: inline-block;
  width: 35px;
}
/*.monthbot tr.m0 .t4, .monthbot tr.m1 .t1, .monthbot tr.m2 .t2, .monthbot tr.m2 .t5{
    position: relative;
}*/
.monthbot tr td span {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  bottom: 2px;
  left: 50%;
  margin-left: -2.5px;
  display: block;
}
.monthbot tr span.tgrey {
  background: #a9a9a9;
  display: block;
}
.monthbot tr span.tameral {
  background: #1ebda7;
  display: block;
}

/*template에 해당m1줄에 달력on표시*/
.monthbot tr.m1 .active {
  /* position: relative;*/
  /*border-radius: 50%;
    background: #edfafb;*/
}
/*상단까지 template에 해당m1줄에 달력on표시되어있는거*/

.monthbot tr .active {
  position: relative;
}
.monthbot tr .active a {
  border-radius: 50%;
  background: #23cdb7;
  color: #fff;
}
.monthbot tr .active i {
  position: absolute;
  top: -4px;
  right: -2px;
  color: #fff;
  font-size: 11px;
  line-height: 17px;
  font-family: s-core5;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #fd669f;
}

.cal-bott {
  margin-top: 11px;
}
.cal-bott .pr-w {
  max-height: 150px;
  overflow-y: auto;
  overflow: auto;
  /*overflow:hidden;*/
  padding-left: 9px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background: #b9b9b9;
}
::-webkit-scrollbar-track {
  background: #d6d6d6;
}
h5.to-pri {
  margin-bottom: 10px;
  color: #23cdb7;
}
.pr-w .date {
  float: left;
  text-align: center;
  font-size: 0.9rem;
}
.pr-w .datenum {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.primary {
  float: left;
  text-align: center;
  width: 100%;
}
.primary li i {
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}
.primary li {
  margin-bottom: 15px;
}
.primary li:last-child {
  margin-bottom: 0;
}
.primary li.pr01 i {
}
.primary li.pr02 i {
}
.primary li.pr03 i {
}
.primary li.pr04 i {
} /*임시*/
.primary li.pr05 i {
} /*임시*/

.primary li .pr {
  margin-left: 5%;
  display: inline-block;
  vertical-align: middle;
  width: 70%;
}
.primary li em {
  display: block;
  font-size: 0.9rem;
}
/*퀵메뉴_접근성*/
.access_l {
  margin-bottom: 25px;
}
.access_l:last-child {
  margin-bottom: 0;
}
.access_l h3 {
  color: black;
  background: #f5f5f5;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 10px 0 10px 23px;
}
.access_l p {
  margin-bottom: 3px;
  font-family: s-core5;
  color: #565656;
  font-size: 15px;
}
.access_l ul {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.access_l li {
  float: left;
  width: 33.3%;
  text-align: center;
}
.access_l .switch {
  width: 100px;
  height: 45px;
}
.access_l .slider {
  border: 1px solid #aaa;
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.access_l .slider:before {
  position: absolute;
  box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  box-sizing: border-box;
  padding: 9px 0;
  background-color: #aaa;
  bottom: 3px;
  left: 4px;
  height: 37px;
  width: 37px;
  content: "OFF";
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.access_l input:checked + .slider:before {
  transform: translateX(53px);
  -webkit-transform: translateX(53px);
  -ms-transform: translateX(53px);
  content: "ON";
  box-shadow: -1px 0px 3px 1px #23cdb7;
  background-color: #23cdb7;
}
.access_l input:checked + .slider {
  border: 1px solid #23cdb7;
  background-color: #fff;
}

/*서브 레이아웃1*/
.mobi-menu {
  display: none;
}
.top-nav {
  overflow: hidden;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}
.top-nav .top-mob {
  display: none;
}
.top-nav .top-tit {
  float: left;
}
.top-nav .top-search {
  float: right;
}
.top-nav .top-search.sear {
  display: none;
}
.top-nav .top-tit h3 {
  font-size: 1.55rem;
  font-family: s-core5;
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}
.top-nav .top-tit ul {
  font-size: 0.96rem;
  display: inline-block;
  vertical-align: bottom;
}
.top-nav .top-tit ul .nav2 a {
  color: #b6b6b6;
}
.top-nav .top-tit ul .nav3 a {
  font-family: s-core5;
}
.top-tit li {
  display: inline-block;
  vertical-align: middle;
}
.top-tit li img {
  vertical-align: baseline;
}
.search-w > div {
  /*  width: 300px;*/
  position: relative;
}
.sea-text {
  box-sizing: border-box;
  border-radius: 25px;
  height: 40px;
  width: 100%;
  margin: 0;
  z-index: 1;
  position: relative;
  background: #f7f7f7;
  border: 1px solid #e2e2e2;
  padding: 0 44px 0 24px;
  outline: none;
}
.sea-butt {
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
  z-index: 1;
  width: 30px;
  height: 100%;
  line-height: 44px;
  /* background: url(/static/img/free-icon-magnifying-glass-49116.svg) no-repeat center; */
  background: url(../ico/icon_돋보기.svg) no-repeat center;
  background-size: 23px;
  position: absolute;
  top: 0;
  right: 6px;
  border: none;
}
.top-tit li::after {
  content: "";
  padding: 8px 4px 6px 15px;
  display: inline-block;
  width: 10px;
  vertical-align: middle;
}
.top-tit li:last-child::after {
  display: none;
}

/*서브 컨텐츠 상단버튼*/
.sub-cont {
  padding-top: 30px;
  overflow: hidden;
  /* margin-top: 30px;
  margin-left: 230px; */
}
.sub-top {
  padding: 0 0 25px 0;
  flex-wrap: wrap;
  display: flex;
}
.top-left {
  max-width: 25%;
  flex: 0 0 25%;
}
.top-right {
  max-width: 75%;
  flex: 0 0 75%;
  text-align: right;
}
.top-center {
  width: 100%;
  text-align: center;
}
.top-cenr {
  float: right;
}
.sear-txtc {
  cursor: inherit !important;
  display: inline-block;
  margin-right: 5px;
  padding: 13px 5px;
  height: 20px;
  width: 237px;
}
.butsea {
}
.butcen span {
  margin-left: 5px;
}
.butcen {
  margin-right: 8px;
}
.butcen,
.butsea {
  color: #fff;
  border: 1px solid #23cdb7;
  background: #23cdb7;
  display: inline-block;
  padding: 13px 15px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 6px;
}

.write a {
  padding-left: 0;
}
.write i {
  vertical-align: middle;
  padding: 10px 27px 13px 0;
  display: inline-block;
}
.write b {
  vertical-align: text-top;
}
.write {
  margin-right: 5px;
}
.down {
  margin-right: 5px;
}

.down a,
.clear a {
  height: 20px;
  padding: 13px 15px;
  display: inline-block;
  vertical-align: middle;
}
.butc,
.butt,
.butt2,
.butt3 {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  /* cursor: pointer;*/
  border-radius: 6px;
  vertical-align: middle;
}
.butc a {
  display: inline-block;
  color: #fff;
  padding: 13px 15px;
  height: 20px;
  vertical-align: middle;
}
.butc {
  background: #23cdb7;
  border: 1px solid #23cdb7;
}
.butt {
  background: #fff;
  border: 1px solid #ddd;
}
.butt2 {
  border: none;
}
.butt3 {
  padding: 6px 13px;
  width: 50px;
  border: 1px solid #ddd;
}
.butb {
  background: #23cdb7;
  color: #fff;
  padding: 13px 15px;
  /* float: right; */
  margin: 25px 0 11px 0;
}
.butcan {
  color: #fff;
  padding: 6px 13px;
}
/*결재함 품목버튼*/

/*왼쪽셀렉트박스*/
.fseleft {
  display: inline-block;
}
.monin i {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 13px;
  right: 8px;
  cursor: pointer;
  background-size: 100% 100%;
}
.selec-date {
  margin-right: 5px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
/*.btn-date input{
    position: absolute;
    pointer-events: none;
    clip: rect(0, 0, 0, 0);
}*/
.btn-date {
  color: #9d9d9d;
  width: 43px;
  height: 20px;
  text-align: center;
  border-radius: 6px;
  outline: none;
  padding: 13px 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: none;
  float: left;
}
.btn-date.on {
  background: #23cdb7;
  border-left: 0;
  border-right: 0;
  color: #fff;
}
.btn-date:nth-child(2) {
  border-radius: 0;
}
.btn-date:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.btn-date:nth-child(3) {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

/*재직자퇴사자버튼*/
.selec-worker {
  margin-right: 5px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-work {
  color: #9d9d9d;
  width: 43px;
  height: 20px;
  text-align: center;
  border-radius: 6px;
  outline: none;
  padding: 13px 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: none;
  float: left;
}
.btn-work.on {
  background: #23cdb7;
  border-left: 0;
  border-right: 0;
  color: #fff;
}
.btn-work:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.btn-work:nth-child(2) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.monin {
  position: relative;
  margin-right: 5px;
  padding: 13px 15px;
  padding-right: 34px;
  height: 20px;
  width: 72px;
}
.moninw {
  margin-right: 5px;
  display: inline-block;
}
.moninw span {
  vertical-align: sub;
}
.monin1 {
  padding: 13px 15px;
  padding-right: 34px;
  height: 20px;
  width: 72px;
}
.sear-txt {
  margin-right: 5px;
  padding: 13px 0 13px 4px;
  height: 20px;
}
.sear-txt input {
  border: 0;
}
.date_box {
  box-sizing: border-box;
  border: none;
  position: relative;
  width: 100%;
  font-size: 12px;
}
.fselect,
.fselect2 {
  width: 100px;
  height: 48px;
  margin-right: 5px;
  text-align: left;
}
div#select_combo {
  display: inline-block;
}
/*서브 리스트 셀렉트 및 인풋공통*/
.table_sel {
  height: inherit;
  margin-right: 0;
  padding: 6px 13px;
}
.ing .table_pad {
  width: 60%;
  box-sizing: border-box;
}
.table_pad {
  padding: 6px 13px;
}

/*회원관리 탭버튼*/
.tab-manage {
  width: 228px;
  display: inline-block;
}
.tab-manage ul {
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 7px;
}
.tab-manage ul li.on {
  background: #23cdb7;
}
.tab-manage ul li {
  float: left;
  height: 48px;
  width: 50%;
}
.tab-manage ul li.on a.per {
}
.tab-manage ul li.on a.com {
}
.tab-manage ul li a.per {
}
.tab-manage ul li a.com {
}
.tab-manage ul li.on a {
  color: #fff;
}
.tab-manage ul li a {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 16px 0 10px 44px;
}
/*회원관리-근로자관리 팝업*/
.pop-in-pop {
  border-collapse: collapse;
  table-layout: fixed;
}
.pop_thd {
  border: 1px solid #eaeaea;
  border-collapse: collapse;
}
th.pop_thd {
  background: #f7f7f7;
}
td.pop_thd {
  box-sizing: border-box;
  height: 50px;
  padding: 10px 15px;
}
.pop_input {
  width: 100%;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  height: 40px;
}

.pop_input[aria-invalid="true"] {
  border: 1px solid #fd9f9f;
}

.pop_time {
  padding: 7px;
  box-sizing: border-box;
  width: 46.333%;
  height: 40px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
}
/*결재함품목팝업 시작*/
.pop-in-approv {
  height: 440px;
  overflow-y: auto;
}
.pop-appr td {
  text-align: center;
}
.pop_thd1 {
  box-sizing: border-box;
  height: 50px;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
}
.appr_td {
  overflow-y: scroll;
  height: 223px;
  padding: 8px;
  box-sizing: border-box;
}
.etc_line {
  margin: 10px 0 0 0;
}
.etc_line h2 {
  font-size: 16px;
  font-weight: bold;
}
.etc_thd {
  padding: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #eaeaea;
} /*결재함품목팝업 끝*/

/*서브 테이블 리스트 */
/*.m_table02, .m_table04, .m_table05{
    table-layout: inherit !important;
}*/

/*서브페이지 테이블리스트 체크박스*/
.subin .che-m {
  width: 5px;
}
/*메뉴설정버튼 - table*/
.subin_me {
  overflow-y: auto;
  height: 500px;
  padding: 0 10px;
  box-sizing: border-box;
}
/*표*/
.subin ul {
  text-align: center;
}
.subin table {
  text-align: center;
  width: 100%;
  word-break: break-all;
  /*table-layout: fixed;*/
}
.subin table th {
  padding: 0.7rem 0;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.subin table th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #ccc;
}
.subin table th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-left: 1px solid #ccc;
}
/*두줄 table head용*/
.subin table .line1 th:first-child,
.subin table .line2 th:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.subin table .line1 th:last-child,
.subin table .line2 th:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.subin table .line2 th {
  border-top: 0;
}

.subin table td {
  border-bottom: 1px solid #ddd;
  padding: 13px 0;
}
.subin table td.tit {
  padding-left: 10px;
  text-align: left;
}
.subin table td.tit a {
  padding-left: 10px;
}
.subin table td.tit:hover a {
  color: #23cdb7;
}
.s-head {
  font-weight: bold;
  font-size: 0.96rem;
  background: #f7f7f7;
}
.s-sub {
  font-size: 0.9rem;
  border-bottom: 1px solid #ccc;
}
.head-w {
  padding: 10px 12px 10px 12px;
}
.sub-w {
  padding: 12px;
}

.sub-w .s-bt {
  padding: 6px 13px;
  /*padding: 10px;*/
  border: 0;
  border-radius: 6px;
  color: #fff;
}
.sub-w .receive {
  background: #23cdb7;
}
.sub-w .return {
  background: #fd669f;
}

.check-w {
  position: relative;
  /*width: 0%;*/
}
.w-che {
  position: absolute;
  top: 3px;
  left: 13px;
  opacity: 0;
  border: 0;
  z-index: -1;
  cursor: pointer;
}
.check-w input[type="checkbox"] + span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  box-sizing: border-box;
  border-radius: 3px;
}
.check-w span.none {
  display: inline-block;
  border: 1px solid #ddd;
  background: #f7f7f7;
  width: 21px;
  height: 21px;
  box-sizing: border-box;
  border-radius: 3px;
}

.check-w input[type="checkbox"] + span.off {
  border: 1px solid #ccc;
}
.check-w input[type="checkbox"] + span.on {
  background: #23cdb7 url("../img/check.png") no-repeat center;
}
.check-w input[type="checkbox"]:checked + span {
  background: #23cdb7 url("../img/check.png") no-repeat center;
}
.sub-w .title {
  text-align: left;
}
.sub-w .title:hover a {
  color: #23cdb7;
}

.print a {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 6px 13px;
  border-radius: 6px;
  box-sizing: border-box;
  color: #a9a9a9;
}
.state span {
  display: inline-block;
  padding: 6px 13px;
  border-radius: 6px;
  box-sizing: border-box;
  color: #fff;
}
.sta1 {
  background: #b5b5b5;
}
.sta2 {
  background: #f9af2d;
}
.sta3 {
  background: #fd669f;
}
.sta4 {
  background: #23cdb7;
}
.sta5 {
  width: 70.5px;
  border: 1px solid #ccc;
  color: #a9a9a9 !important;
  background: #fff;
}

/*업무진행도 셀렉트박스*/
.ing select {
  box-sizing: border-box;
  padding: 6px 13px;
}
.ing .save {
  box-sizing: border-box;
  border-radius: 6px;
  padding: 6px 13px;
  border: 1px solid #23cdb7;
  background-color: #23cdb7;
  margin-left: 8px;
  display: inline-block;
  color: #fff;
}
/*업무지시 작성버튼3개*/
.work-btnw {
  margin-top: 2.2%;
}
.work-btnw li.report03 {
  margin-right: 0;
}
.work-btnw li {
  position: relative;
  border: 1px solid #ddd;
  box-sizing: border-box;
  float: left;
  width: 32.6666%;
  /*height: 130px;*/
  margin-right: 1%;
  border-radius: 10px;
}
.work-btnw .box {
  text-align: center;
  padding: 11px 6px;
  box-sizing: border-box;
  /*background-color: #ddd;*/
  /*height: 100px;*/
}
.work-btnw .box-line {
  display: inline-block;
}
.work-btnw .box .ico {
  border-radius: 50%;
  border: 1px solid #ddd;
  background-position: center;
  background-repeat: no-repeat;
  width: 66px;
  height: 66px;
  display: inline-block;
}
.work-btnw .report01 .ico {
}
.work-btnw .report02 .ico {
}
.work-btnw .report03 .ico {
}
.work-btnw .box .text {
  display: inline-block;
  padding: 0 78px 0 20px;
  text-align: left;
}
.work-btnw .box .text .date {
  font-size: 16px;
  color: #a9a9a9;
}
.work-btnw .box .text p {
  font-size: 19px;
  font-weight: bold;
}
.work-btnw .infono {
  background: #959595;
}
.work-btnw .infoy {
  background: #f9af2d;
}
.work-btnw .info {
  display: inline-block;
  padding: 4px 7px;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
  vertical-align: bottom;
}
.work-btnw .report-btn.on {
  color: #23cdb7;
  background: #fff;
}
.work-btnw .report-btn.on:hover {
  color: #fff;
  background: #23cdb7;
}
.work-btnw .report-btn {
  border-top: 1px solid #ddd;
  color: #ccc;
  background: #f4f4f4;
  border-radius: 0 0 10px 10px;
  padding: 11px;
  text-align: center;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.work-btnw .cfreport {
  font-size: 0.85rem;
  color: #fd669f;
  position: absolute;
  right: 0;
  margin-top: 8px;
}
.work-btnw .cfreport strong {
  font-weight: bold;
  vertical-align: top;
}

/*페이징*/
.row-l {
  float: left;
}
.row-l i {
  margin-right: 5px;
}
.row-l i,
.row-l span {
  vertical-align: middle;
}
.row-l img {
  vertical-align: middle;
}
.pagerow {
  padding-top: 25px;
}
.row-r {
  width: 100%;
  font-size: 0.96rem;
}
.row-r ul {
  text-align: center;
}
.row-r li {
  display: inline-block;
  margin: 0 2px;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
}
.row-r li a {
  color: #b5b5b5;
  text-align: center;
  display: inline-block;
  min-width: 31px;
  height: 31px;
  line-height: 31px;
}
.row-r li.on {
  background: #b5b5b5;
}
.row-r li.on a {
  color: #fff;
}

/*사내문서 list박스*/
.mt-35 {
  margin-top: 35px;
}
.doc-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
}
.doc-card {
  border: 1px solid #22ccb7;
  background-color: rgba(34, 204, 183, 0.05);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}
.doc_none {
  color: #b7b7b7;
  text-align: center;
}
.doc_none p {
  font-size: 16px;
  margin-top: 20px;
}

.doc-card-header {
  padding: 10px 10px;
  color: #2e2e2e;
  text-align: right;
}
.doc-card-btn {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
}
.doc-card-btn:first-of-type {
}
.doc-card-btn:last-of-type {
}

.doc-card-close {
  cursor: pointer;
  float: right;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 16px;
  background-color: #22ccb7;
  color: #ffffff;
  font-size: 25px;
  line-height: 8px;
}

.doc-btn-group {
  display: flex;
}
.doc-btn-group > button {
  width: 50%;
  padding: 8px;
}
.doc-content {
  padding: 30px 15px;
}
.doc-greybtn {
  border-top: 1px solid #22ccb7;
  color: #22ccb7;
}

.doc-greenbtn {
  background: #22ccb7;
  color: white;
}

.doc-wrap {
  width: 100%;
  text-align: center;
}
.updoc b {
  vertical-align: text-bottom;
}
.updoc i {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 30px 13px 0;
}
.doc-w li {
  display: inline-block;
  /* float: left;*/
  width: 22%;
  margin: 1% 1% 1% 1%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  position: relative;
}
.doc-w .re-write {
  right: 18px;
  top: 18px;
  position: absolute;
}
.re-write .re-wri {
  margin-right: 7px;
}
.doc-w li .doc-img {
  text-align: center;
  padding: 65px 0;
  height: 150px;
}
.doc-img .img-con {
  position: relative;
  margin: 0 auto;
  width: 70px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
}
.doc-img .file-name {
  font-family: s-core5;
  color: #fff;
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -16px;
}
.doc-w li .doc-img p {
  text-align: center;
  font-size: 16px;
}
.doc-w li .doc-img .doc-tit {
  margin-top: 15px;
}
.doc-w li .doc-bt {
  position: relative;
  border-top: 1px solid #ccc;
}
.doc-w li .doc-bt a {
  padding: 17px;
  height: 50px;
  display: inline-block;
  float: left;
  box-sizing: border-box;
  text-align: center;
  width: 50%;
}
.doc-w li .doc-bt .see-down {
  background: #23cdb7;
  color: #fff;
}
.doc-w li .doc-bt .see-direc:hover {
  background: #f2f2f2;
}

/*서브-글쓰기*/

.view-w {
  border-collapse: collapse;
  width: 100%;
}
.view-con .v-row:first-child {
  border-top: 1px solid #ddd;
}
.view-con .v-row:nth-child(2) {
  border-top: 1px solid #ddd;
}
.view-con .v-row {
  border-bottom: 1px solid #ddd;
}
.view-con th {
  text-align: center;
  background: #f7f7f7;
  font-weight: bold;
  font-size: 0.96rem;
  vertical-align: middle;
}
.view-con td {
  vertical-align: middle;
  height: 50px;
  padding: 7px 20px;
}
.view-con1 th,
.view-con1 td {
  text-align: center;
  padding: 13px 10px !important;
}
.view-con1 td input {
  text-align: right;
  width: 100%;
  box-sizing: border-box;
} /*결재함 view품목*/

.view-con .v-input {
  padding: 7px;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  border: 1px solid #ccc;
  background: #fff;
  box-sizing: border-box;
}
.view-con .c-input {
  height: 100px;
  min-height: 210px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.view-con #file-sel {
  margin-right: 10px;
  padding-left: 29px;
  color: #6b6b6b;
  font-family: "s-core4", sans-serif;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100px;
}
.fil-w label {
  font-size: 0.85rem;
  color: #9d9d9d;
}
.fil-w .upload {
  font-family: s-core5;
}
.s-button {
  text-align: center;
  padding-top: 30px;
}
.s-save {
  color: #fff;
  background: #40b8a8;
  margin-right: 8px;
  border: 1px solid #40b8a8 !important;
}
.s-return {
  border: 0;
  background: #fd9f9f;
  color: #fff;
  margin-right: 8px;
}
.s-clea img {
  vertical-align: sub;
  margin-right: 3px;
}
.s-clea {
  padding: 15px 25px !important;
  margin-left: 8px;
}
.s-cans {
  background: #959595;
  border: 1px solid #959595;
  color: #fff;
}
.sc {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 15px 35px;
  display: inline-block;
}
.sc1 {
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 15px 35px;
  display: inline-block;
}
/*승인반려버튼*/
.s-ok {
  margin-right: 20px;
  background: #23cdb7;
  color: #fff;
}
.s-rej {
  background: #fd669f;
  color: #fff;
}

/*서브 뷰페이지*/
table.wrap-view,
table.work-view {
  width: 100%;
  border-collapse: collapse;
}
table.wrap-view caption,
table.work-view caption {
  line-height: 3rem;
  font-size: 1rem;
  background: #f7f7f7;
  font-weight: bold;
  border-top: 1px solid #ddd;
  /* border-bottom: 1px solid #eaeaea;*/
}
.wrap-view thead {
  border-top: 1px solid #ddd;
}
.wrap-view tr {
  /*background:#eaeaea;*/
  display: block;
}
.wrap-view tr th {
  padding: 18px;
  font-weight: bold;
  background: #f7f7f7;
}
.wrap-view tr td {
}
.wrap-view tr.sub-t {
  width: 50%;
}
.wrap-view tr.sub-t th {
  width: 203px;
}
.wrap-view tr.sub-t td {
  padding-left: 15px;
}
.wrap-view tr.sub-tl {
  float: left;
}
.wrap-view tr.sub-tr {
  float: left;
}
.wrap-view tr.sub-t td .input {
  width: 90%;
  border: 0;
}
.wrap-view .sub-attat th {
  width: 203px;
  display: inline-block;
  text-align: center;
}
.wrap-view .sub-attat {
  text-align: left;
}
.wrap-view .sub-attat td {
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  padding-left: 1.5%;
}
.wrap-view .sub-attat td a img {
  vertical-align: middle;
}
.wrap-view tbody {
  border-bottom: 1px solid #ddd;
}
.wrap-view tbody tr {
  padding: 21px 10px 21px 72px;
  width: 100%;
  box-sizing: border-box;
}
.wrap-view thead tr {
  border-bottom: 1px solid #ddd;
}
.wrap-view tbody img {
  width: 100%;
}
.s-btn {
  margin-top: 35px;
  text-align: center;
}
.s-btn a img {
  vertical-align: middle;
  padding-right: 8px;
}
.s-btn a {
  background: #f7f7f7;
  line-height: 1.8rem;
  border: 1px solid #ddd;
  height: 30px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 0.5rem 1.1rem;
  border-radius: 6px;
  vertical-align: middle;
  margin-right: 10px;
  color: #a9a9a9;
}
.s-btn a.but3 {
  margin-right: 0;
}

/*이전다음글*/
.preNext {
  overflow: hidden;
  margin-top: 55px;
  border-top: 1px solid #ddd;
}
.preNext li {
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}
.preNext li strong {
  font-weight: bold;
  padding: 20px 20px 20px 71px;
  display: block;
  float: left;
  overflow: hidden;
  width: 16.5%;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: #f7f7f7;
  background-position: 15% 50%;
  background-repeat: no-repeat;
  background-size: 18px auto;
}
.preNext li.prev > strong {
}
.preNext li.next > strong {
}
.preNext li .list {
  display: block;
  float: left;
  overflow: hidden;
  padding-left: 20px;
  width: 79%;
}
.preNext li .list a {
  padding: 20px 0;
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  width: 100%;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  line-height: 1rem;
  max-height: 1rem;
}

/*답변.댓글*/
.comme-box {
  margin: 70px 0 40px 0;
  /*    padding: 10px 20px;
        background-color: #f7f7f7;*/
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.comment-ti .mem {
  font-size: 0.95rem;
}
.comment-ti .time-in {
  font-size: 0.8rem;
  color: #979797;
  margin-left: 25px;
  vertical-align: middle;
}
.comme-ul li .c01 {
  border-bottom: 1px solid #eaeaea;
}
.comme-ul li .c01-re,
.comme-ul li .c01-wr {
  padding-left: 45px;
  background: #f7f7f7;
  position: relative;
}
.comme-ul li .c01-re:before,
.comme-ul li .c01-wr:before {
  content: "";
  border-style: solid;
  border-width: 0 0 1px 1px;
  position: absolute;
  top: 15px;
  left: 20px;
  width: 11px;
  height: 13px;
  display: block;
  opacity: 0.5;
}
.comme-ul li article {
  padding: 20px 20px;
}
.comme-ul li article .reply_ico {
  top: 24px;
  background: 0 0;
  border-width: 0 0 1px 1px;
  border-style: solid;
}
.comme-box .box {
  text-align: center;
}
.comment-ti {
  line-height: 1rem;
  margin-bottom: 10px;
}
.comment-ti strong {
  vertical-align: initial;
  font-weight: bold;
}
.input-wr {
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  vertical-align: middle;
  padding: 10px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  display: inline-block;
}
.comment-re .btn-wr li {
  display: inline-block;
}
.comment-re .btn-wr li .rewri {
  color: #fff;
  border: 1px solid #23cdb7;
  background: #23cdb7;
}

.comment-re {
  color: #8e8e8e;
  margin: 10px 0 0;
  border-radius: 6px;
  line-height: 1rem;
}
.comment-re .attat i {
  width: 23px;
  height: 22px;
  display: inline-block;
  background-size: 16px auto;
}
.comment-re .btn li a.attat {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #eaeaea;
  color: #a9a9a9;
}
.comment-re .btn-wr {
  float: right;
}
/*.comment-re .btn-wr li{
    display: inline-block;
}*/
.comment-re .btn-wr li .sign {
  color: #fff;
  background: #23cdb7;
}
.comment-re .btn-wr li a {
  border: 1px solid #ccc;
  padding: 12px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 6px;
  line-height: 1.5rem;
}
.comment-re p {
  float: left;
}
.comment-re .btn {
  float: right;
}
.comment-re .btn li {
  display: inline-block;
}
.comment-re .btn li a {
  color: #23cdb7;
}
.comment-re .btn li:first-child:after,
.comment-re .btn li:nth-child(2):after {
  padding-left: 6px;
  color: #23cdb7;
  content: "ㅣ";
}
.c01-wr .comment-re .btn li:first-child:after {
  display: none;
}
.c01-wr .input-wr {
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  vertical-align: middle;
  padding: 10px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  display: inline-block;
}
.comment h4 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 12px;
}
.comment .comment-b {
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  vertical-align: middle;
  padding: 10px;
  width: 92%;
  height: 120px;
  border-radius: 6px;
  display: inline-block;
}
.comment .btn {
  vertical-align: bottom;
  display: inline-block;
  width: 7%;
}
.comment .btn a {
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 5.5rem;
  width: 100%;
  height: 120px;
  background: #23cdb7;
  border: 1px solid #23cdb7;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 15px 0px;
  border-radius: 6px;
  vertical-align: middle;
  color: #fff;
}
/*업무보고 뷰페이지*/
.work-view thead {
  width: 100%;
  border-top: 1px solid #eaeaea;
}
.work-view thead tr,
.work-view tbody tr {
  border-bottom: 1px solid #ddd;
}
.work-view tr td {
  padding: 7px 20px;
}
.work-view textarea {
  overflow-y: auto;
  overflow: auto;
  width: 100%;
  min-height: 250px;
  padding: 7px;
  box-sizing: border-box;
}
.work-view tr th {
  font-weight: bold;
  background: #f7f7f7;
  padding: 18px;
}
.work-view tr td.edit {
  padding: 21px 0 21px 72px;
}
.work-view tr .input {
  width: 90%;
  border: 0;
}
.work-view .workhead {
  font-size: 1rem;
  border-top: 1px solid #eaeaea;
  /*
    line-height: 3rem;
    background: #f7f7f7;
    font-weight: bold;
    border-bottom: 1px solid #eaeaea;*/
}

/*작성페이지*/
.write_sel {
  padding: 12px 20px;
  box-sizing: border-box;
  height: 48px;
}
.write_100 {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
}

/*팝업창 디자인*/
/*.popconts{
    padding: 40px;
}*/
.popconts .pop-02 {
  overflow: hidden;
}
.popconts {
  border: 1px solid #ccc;
}
.invite-chat-wrapper {
  background: #cdf2ed;
  padding: 0;
  padding: 15px;
  display: block;
  margin: 0;
}
.invite-button {
  border-radius: 30px;
  background: #fafafa;
  color: #8b8b8b;
  padding: 5px 15px;
}

.popconts .pop01 {
  position: relative;
  height: 510px;
  width: 475px;
  box-sizing: border-box;
}
.popconts .pop-01 {
  height: 100%;
}
.popconts .pop-01 .ptit {
  font-weight: 300;
  box-sizing: border-box;
  height: 60px;
  padding: 20px 0 15px 20px;
  border-bottom: 1px solid #ccc;
}
.popconts .pop-01 .poimt {
  overflow-y: auto;
  overflow-x: visible;
  outline: none;
  height: 383px;
}
.popconts .popimg span {
  display: inline-block;
  margin-right: 2%;
  background-size: 40px 40px;
  height: 62px;
  width: 11%;
  vertical-align: middle;
}
.popconts .popimg .pitxt {
  line-height: 62px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}
.popconts .pop-01 .popimg {
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.popconts .pop-01 .popimg:last-child {
  border: none;
}
.popconts .pop-01 .popimg:hover {
  /* background: #ebfffd; */
}
.popconts .pop-01 .popimg a {
  padding: 10px 20px 0 20px;
  height: 77px;
  display: block;
}
.popscroll {
  overflow-y: visible;
  overflow: scroll;
  overflow-x: hidden;
}
.popconts .pop-01 .popit {
  display: inline-block;
  vertical-align: middle;
  width: 83%;
  position: relative;
}
.popconts dl {
  overflow: hidden;
  font-size: 0.95rem;
}
.popconts .pop-01 .popit dt {
  white-space: nowrap;
  max-width: 73%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.popconts .pop-01 .popit dd {
  width: 27%;
}
.popconts dt {
  float: left;
}
.popconts dd {
  color: #8c8c8c;
  font-weight: 300;
  float: right;
}
.popconts .popimg a {
  color: #000;
}
.popconts .popimg p {
  margin-top: 3px;
  font-weight: 300;
}
.popconts .popit .count {
  color: #fff;
  background: #ff247e;
  position: absolute;
  bottom: 4px;
  right: 22px;
  font-size: 0.7rem;
  text-align: center;
  line-height: 0.4rem;
  border-radius: 10px;
  margin-top: 0;
  padding: 7px;
  height: 18px;
  box-sizing: border-box;
}
.pbtn {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #ccc;
  background: #f7f7f7;
}
.pbtn a {
  color: #000;
}

.popconts .pop01:before {
  transform: translate(-50%, -50%) rotate(45deg);
  content: "";
  background: #fff;
  left: 50%;
  top: -1px;
  width: 15px;
  height: 15px;
  position: absolute;
  border-top: 1px solid #9c9fa6;
  border-left: 1px solid #9c9fa6;
}

.popconts .pop02 {
  box-sizing: border-box;
  height: 510px;
  width: 430px;
  overflow: hidden;
  border-radius: 10px;
}
.popconts .pop-02 {
  overflow: hidden;
}
.popconts .pop-02 .poimt {
  /*margin-top:60px;*/
}
.popconts .pop-02 .ptit {
  height: 60px;
  font-weight: 200;
  font-size: 1.1rem;
  float: left;
  color: #fff;
  /*padding: 0 0 0 20px;*/
  padding: 0 0 0 14px;
  background: #ada8ad;
  border-bottom: 1px solid #ccc;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.psearch {
  box-sizing: border-box;
  padding: 17px 0;
  border-bottom: 1px solid #ccc;
}
.psearch span {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  background-size: auto;
  width: 13%;
}
.psearch #search {
  display: inline-block;
  vertical-align: middle;
  width: 82%;
  /*padding: 0 0 0 60px;*/
  font-size: 1rem;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin: 0px 16px;
}
.psearch #search:before {
  content: "";
  width: 40px;
  height: 40px;
  display: block;
}
.popconts .pop-02 .popit {
  vertical-align: middle;
  display: inline-block;
}
.popconts .pop-02 .popimg {
  padding: 9px 0 9px 20px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.popconts .pop-02 .popimg:hover {
  /* background: #ebfffd; */
}
.popconts .pop-02 .popimg:last-child {
  border: none;
}
.popconts .pop-02 dt {
  float: inherit;
}
.popconts .pop-02 dd {
  float: inherit;
}

/*회원검색창*/
.pop-021 .popimg:hover {
  /* background: #ebfffd; */
}

/*채팅모니터링 history영역추가*/
.message-his {
  margin: 0 auto;
  width: 50%;
}
.message-his table {
  width: 100%;
  padding: 0px 15px 15px;
  border: 1px solid #ececec;
  border-radius: 8px;
}
.chatsearch {
  margin-bottom: 20px;
}
.search-chat {
  margin: 0 auto;
}

.popconts .pop-02 .poimt.chat {
  height: 318px;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  /* border:1px solid #ccc;*/
  border-top: none;
  border-bottom: none;
}
.popconts .pop-02 .poimt.chat table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  padding: 0 10px;
}
/* #databind table td {
  border: none;
} */
.chatlist {
  /*padding: 10px 11px 10px 11px;
    height: 280px;
    vertical-align: bottom;
    box-sizing: border-box;*/
}
.chatime {
  margin-bottom: 4px;
  margin-top: 13px;
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
}
.chatime .date {
  display: inline-block;
  position: relative;
  padding: 10px 15px;
  background: #f7f7f9;
  border-radius: 30px;
  line-height: 15px;
  font-size: 0.8rem;
  font-weight: 400;
}
.chatme {
  margin: 7px 0;
  text-align: right;
}
.chatme.chat-next {
  text-align: inherit;
}
.chatme .chatbody {
  position: relative;
  display: inline-block;
}
.chat_name {
  display: inline-block;
  margin-bottom: 4px;
  color: #4c4c4c;
  font-weight: bold;
}
.chat_text {
  box-sizing: border-box;
  max-width: 270px;
  line-height: 1.3rem;
  font-size: 0.95rem;
  display: block;
  padding: 7px 12px;
  border: 1px solid #b4b4b4;
  background: #f7f7f9;
  border-radius: 9px;
  word-break: break-all;
  margin-top: -7px;
  margin-left: 40px;
  /*white-space: pre-line;*/
}
.chat_status {
  width: 60px;
  font-size: 0.8rem;
  color: #a3a3a3;
  position: absolute;
  display: inline-block;
  bottom: 0px;
  right: -65px;
  /*width: 43px;*/
  height: 20px;
  margin-left: 5px;
  font-weight: 300;
}
.chat-one .num {
  left: 50px;
}
.chatbody .num {
  bottom: 18px;
  font-size: 0.6rem;
  display: inline-block;
  color: #23bdc7;
  position: absolute;
}
.chat-one .chat_status {
  position: absolute;
  text-align: right;
  left: -33px;
  bottom: 2px;
  margin-right: 5px;
}
.chat-next .chat_text {
  background: #fff;
}
.chat-img {
  max-width: 100%;
  text-align: center;
  overflow: hidden;
}
.chat-next .chat_text a.chatlink {
  display: inline-block;
  text-decoration: underline;
  color: #06f;
}
.chat-next .chat_text .attat img {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}
.chat-next .chat_text .attat-txt {
  display: inline-block;
  vertical-align: middle;
  color: #06f;
  font-size: 0.9rem;
}
/* .chat-next .me {
  background-color: #F7F7F7 !important;
  margin-left: 0;
}
.chat-next .me_time {
  right: 100px;
  margin-right: 5px;
} */

.pmessage {
  padding: 20px 20px 15px;
  border-top: 1px solid #ccc;
}
.pmessage textarea {
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid transparent;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  resize: none;
  outline: none;
}
.meicon {
  text-align: right;
}
.meicon a {
  display: inline-block;
  margin-right: 20px;
}

/*팝업창 디자인01*/

span.ptit1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 225px;
  display: inline-block;
  overflow: hidden;
  height: 60px;
  line-height: 60px;
}
button {
  vertical-align: middle;
  border: 0 none;
  background: transparent;
  outline: none;
  cursor: pointer;
}
button.pbutton1 {
  float: right;
  width: 38px;
  height: 38px;
}

button.pbutton2 {
  margin-right: 10px;
  float: right;
  width: 40px;
  height: 40px;
}
button.pbutton3 {
  width: 40px;
  height: 40px;
}
.picon {
  position: absolute;
  top: 0;
  right: 0;
  clear: both;
  margin-top: 10px;
  height: 60px;
}
.chat-mem {
  font-size: 0.9rem;
  display: inline-block;
  overflow-y: hidden;
  height: 50px;
  padding: 0 2px;
  vertical-align: top;
  cursor: pointer;
  margin-top: 6px;
  line-height: 50px;
}
.icon-img {
  vertical-align: top;
  display: inline-block;
  width: 9px;
}
.icon-img img {
  vertical-align: text-top;
}
.icon-count {
  margin-left: 6px;
  vertical-align: top;
}
.ptit-name .memn {
  padding-left: 4%;
}
.ptit1.mem {
  position: relative;
}
.chmlist {
  background: #0000006e;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 15;
  width: 40%;
  overflow-x: visible;
  overflow-y: auto;
  border-bottom: 1px solid #9c9fa6;
  border-right: 1px solid #9c9fa6;
}
.chmlist ul {
  overflow-y: auto;
  overflow-x: visible;
  height: 318px;
  padding-top: inherit;
}
.popconts .chmlist ul .chmimg {
  padding: 8px 0 9px 15px;
  border-right: inherit;
  border-left: inherit;
  border-top: inherit;
  border-bottom: 1px solid #ccc !important;
  border-radius: inherit;
  width: 100%;
  box-sizing: border-box;
}
.popconts .chmlist ul .chmimg:last-child {
  border-bottom: none !important;
}
.chmimg a {
  color: #000;
}
.chmimg .chtit {
  vertical-align: middle;
  display: inline-block;
}
.chmimg .chtit dl {
  font-weight: 400;
  overflow: hidden;
  font-size: 0.95rem;
  color: #fff;
}
.chmimg .chtit dd {
  line-height: 1.1rem;
  color: #8c8c8c;
  font-weight: 300;
  color: #fff;
}
.chadd {
  text-align: center;
  padding: 10px 0;
  background: #f7f7f9;
  font-weight: 400;
  border-top: 1px solid #ccc;
}
.chadd a {
  color: #000;
}
.setting-tot {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 49px;
  min-height: 100px;
  width: auto;
  /*border: 1px solid #b4b4b4;*/
  background: #f7f7f9;
}
.setting {
  padding-top: 0 !important;
}
.setting-tot:before {
  transform: translate(-50%, -50%) rotate(45deg);
  content: "";
  background: #f7f7f9;
  left: 50%;
  top: -1px;
  width: 9px;
  height: 9px;
  position: absolute;
  border-top: 1px solid #b4b4b4;
  border-left: 1px solid #b4b4b4;
}
.setting .set-list {
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  /*border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #ccc;*/
  border-radius: inherit;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  color: #000;
  font-size: 0.9rem;
}

/*채팅그룹참여자리스트*/
.chpop {
  /*width: 250px;
    border:1px solid #ccc;*/
  border-radius: 10px;
}
.chedit {
  padding: 14px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  color: #fff;
  background: #23cdb7;
  /*background: linear-gradient(45deg, #cd81ff, #a15aff);*/
}
.editbtn {
  float: right;
}
.popconts .chpop ul {
  overflow-y: auto;
  overflow-x: visible;
  height: 318px;
  padding-top: 0;
  margin-bottom: 8px;
}
.popconts .chpop ul li:last-child {
  border-bottom: 0;
}
.popconts .chpop ul li {
  border-radius: 0;
  padding: 8px 0 9px 15px;
  border-bottom: 1px solid #cccccc;
  border-right: inherit;
  border-left: inherit;
  border-top: inherit;
  width: 100%;
  box-sizing: border-box;
  margin-right: 0;
}
.popconts .chpop ul li a {
  display: block;
}
.chpimg span {
  width: 20%;
  background-size: auto 76% !important;
  /* background: url("../img/pimg01.png") no-repeat 0 center; */
  height: 45px;
  display: inline-block;
  vertical-align: middle;
}
.chpt {
  vertical-align: middle;
  display: inline-block;
}
.chpt dl {
  width: 165px;
}
.chpt dt {
  line-height: 1.1rem;
  float: inherit;
  display: block;
  color: #000;
}
.chpt dd {
  float: inherit;
  display: block;
}

/*알람아이콘*/
.a-icon a {
  position: relative;
  width: 20px;
  display: block;
}
span.al-on {
  right: 0;
  top: 0;
  position: absolute;
  border-radius: 50%;
  background: #23cdb7;
  width: 11px;
  height: 11px;
}
.a-hori {
  height: 50px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  background: #23cdb7;
  width: 172px;
  word-break: break-all;
  text-overflow: ellipsis;
  padding: 10px;
}
.a-hori span img {
  vertical-align: middle;
}
.a-hori span {
  vertical-align: middle;
  display: inline-block;
}
.a-hori p {
  font-weight: 300;
  padding: 0 0 0 5px;
  vertical-align: middle;
  display: inline-block;
}
.a-hori a {
  color: #fff;
}
.attat-t {
  display: inline-block;
}
.attat {
  display: inline-block;
}

/*마이페이지*/
.mypage-top {
  padding: 36px 40px;
  box-sizing: border-box;
  background: #fafafa;
}
.mypage-top .my-img {
  float: left;
  position: relative;
}
.mypage-top .my-txt {
  float: left;
  margin-left: 50px;
}
.my-img .profile-img {
  position: relative;
  box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.07);
  /*box-shadow: 2px 4px 5px rgba(0,0,0,0.23);*/
  /*background-size:100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../image/profile.png);*/
  border-radius: 100%;
  width: 133px;
  height: 133px;
  display: inline-block;
}
.my-img .profile-img img {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 100%;
  height: 100%;
  /* width: 133px;
     height: 133px;*/
  border-radius: 50%;
}
.my-img .pro-cam {
  bottom: 8px;
  right: -6px;
  position: absolute;
}
.my-img .pro-cam a {
  border: 1px solid #ccc;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.my-txt th {
  font-family: s-core5;
}
.my-txt th,
.my-txt td {
  font-size: 14px;
  /* padding-bottom: 10px;*/
  padding-right: 30px;
  line-height: 28px;
}
.my-txt .file-my {
  color: #fff;
  background: #23cdb7;
  padding: 5px 9px;
  border-radius: 6px;
  font-size: 13px;
}
.my-txt .file-my.no-file {
  color: #fff;
  background: #a9a9a9;
  padding: 5px 9px;
  border-radius: 6px;
  font-size: 13px;
}
.my-table {
  border-collapse: collapse;
  width: 100%;
  border-top: 4px solid #e5e5e5;
  margin-top: 30px;
}
.my-table input::placeholder {
  color: #c7c7c7;
}
.my-table th {
  font-family: s-core5;
  border-bottom: 1px solid #d2d2d2;
}
.my-table th.phone-t {
  vertical-align: text-top;
  padding-top: 44px;
}
.my-table td {
  border-bottom: 1px solid #d2d2d2;
  padding: 30px 0;
}
.my-table tr.last td,
.my-table tr.last th {
  border-bottom: 0;
}
.my-table td a {
  display: inline-block;
  /*height: 45px;*/
}
.my-table .my-butc,
.my-table .my-butc1 {
  text-align: center;
  color: #23cdb7;
  padding: 13px 0px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #23cdb7;
}
.my-table .sign-w {
  width: 293px;
}
.my-table .sign-area {
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
  height: 160px;
  background: #f2f2f2;
  /*width: 100%;*/
  border: 1px solid #ddd;
  margin-bottom: 10px;
}
.my-table .sign-area img {
  height: 100%;
}
.my-table .my-butcs,
.my-table .my-butcs1 {
  width: 48%;
  display: inline-block;
}
.my-table .my-butcs {
  margin-left: 2%;
  text-align: center;
  color: #555;
  padding: 13px 0px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
}
.my-table .my-butcs1 {
  text-align: center;
  color: #23cdb7;
  padding: 13px 0px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #23cdb7;
}
.my-table .my-butc {
  width: 110px;
}
.my-table .my-butc1 {
  width: 110px;
}
.my-phone0 input {
  background: #f2f2f2;
}
.my-phone0 a {
  margin-left: 4px;
}
.my-phone1 {
  margin: 8px 0;
}
.my-phone1 input {
  width: 360px;
}
.my-phone1 a {
  margin-left: 8px;
}
.my-table input {
  box-sizing: border-box;
  text-align: left;
  padding: 13px;
  cursor: inherit;
}
.my-table select {
  padding: 12px 13px;
}
.my-table .email0 {
  width: 200px;
}
.my-table tfoot {
  text-align: center;
}
.my-table tfoot td {
  border: none;
}
.my-table .my-bt,
.my-table .my-btc {
  padding: 13px 0;
  width: 115px;
}
.my-table .my-bt {
  color: #fff;
  margin-right: 10px;
}

/* select css */
.wrap {
  user-select: none;
  display: inline-block;
  margin-right: 20px;
}
.wrap ul li,
.wrap ol li {
  list-style: none;
}
.select_box {
  margin-right: 10px;
  width: 130px;
}
.select_box .box {
  display: inline-block;
  position: relative;
  width: 130px;
}

.select-td-important {
  border: none !important;
  font-size: 14px !important;
  color: #757575 !important;
}

.select_box .box .select {
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 10px;
  width: 130px;
  height: 49px;
  line-height: 49px;
  font-size: 16px;
  color: #9a9a9a;
  cursor: pointer;
}

.select_box .box .select:after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 10px;
  color: #c9c9c9;
}
.select_box.on .box .select:after {
  content: "▲";
}
.select_box .box .list {
  display: none;
  overflow-y: auto;
  position: absolute;
  top: 80%;
  left: 5px;
  z-index: 10;
  border: 1px solid #fafafa;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  width: 93%;
  max-height: 200px;
  background-color: #fff;
  padding-left: 0;
  color: #757575;
}
.select_box .box .list > li {
  box-sizing: border-box;
  padding-left: 25px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}
.select_box .box .list > li:hover {
  background-color: #ccc;
}
.selected {
  background: url("../img/select-check-mark.svg") no-repeat 7%;
  background-size: 13px;
  padding: 0 5px;
}
#type05 .box .select:after {
  content: "";
  position: absolute;
  transform: rotate(135deg);
  display: inline-block;
  width: 5px;
  height: 5px;
  border-top: 3px solid #707070;
  border-right: 3px solid #707070;
  top: 32%;
  right: 12px;
}
#type05.on .box .select:after {
  content: "";
  transform: rotate(-45deg);
  top: 42%;
  right: 12px;
}
.menu-hover:hover {
  color: #22ccb7;
}

.docs-popup-title {
  display: flex;
}

.docs-popup-tr {
  flex-direction: row;

  .docs-popup-td {
    width: 65%;
    margin-right: 8px;
  }
}

@media (max-width: 1600px) {
  /*메인개인*/
  .cal01 li .icocal {
    width: 30%;
  }
}

@media (max-width: 1500px) {
  /*메인개인*/
  /*잠시*/
  .checkw {
    /*background:#fff1f1;*/
  }
  .check01-1 {
  }
  .check01-0 {
  }
  .cal01 li {
    padding: 25px 18px;
  }
  .cal01 li .icocal {
    width: 30%;
  }
  .card-t {
    width: 46%;
  }
  .worknews {
  }
  .worknews .card-news {
    display: none;
  }
  .card-t1 {
    width: 49%;
  }
  .card-big {
    width: 100%;
  }

  .worktea1 {
  }
  .worktea1 ul {
    height: 185px;
  }
  .workboard {
  }
  .workboard ul {
    height: 247px;
  }

  .checkcal {
    /* height: 650px; */
  }
}
@media (max-width: 1400px) {
  /*메인개인*/
  .check01-1 {
  }
  .check01-0 {
  }
  .cal01 li {
    height: 131px;
    padding: 18px 18px;
  }
  .cal01 li:last-child {
    padding: 13px 18px;
  }
  /* .cal01 .work-t .icocal{
         background-color:none;
     }
     .cal01 .late .icocal{
         background-color:none;
     }
     .cal01 .absen .icocal{
         background-color:none;
     }
     .cal01 .holly .icocal{
         background-color:none;
     }*/

  .cal01 li .icocal {
    width: 30%;
  }
  .worknews {
  }
  .card-t1 li strong {
    margin-right: 5px;
  }
  .workboard {
  }
  .worktea1 {
  }
  .worktea1 ul {
    height: 145px;
  }
  .check {
    padding: 13px 21px;
  }
  .check ul dl {
    line-height: 20px;
  }
  .check ul dl dd {
    font-size: 13px;
  }
  .checkcal {
    /* height: 600px; */
  }

  .monthbot td a {
    width: inherit;
  }

  /*인사-친권동의서*/
  .finput1 {
    width: 67% !important;
  }
  .butcp {
    width: 31% !important;
  }
}

@media (max-width: 1300px) {
  /*메인기업*/
  .cal01 li .icocal {
    width: 30%;
  }
  .check01-0 {
  }
  .check01-1 {
  }
  .worktea1 {
  }
  .workboard {
  }
  .cal01 .holly .hol_tm {
    display: block;
    float: right;
    color: #169c8b;
    font-size: 0.95rem;
  }
  .worknews {
  }
  .card-t {
    width: 43%;
  }
  .card-t1 {
    width: 50%;
  }
  .check li {
    padding: 10px 0;
  }
  .card-t1 li strong {
    padding: 5px 10px;
  }

  /*.check{
        width: 48.7%;
    }*/
  .checkcal {
    /* height: 600px; */
    /* height: 514px;*/
    padding: 15px 20px;
  }
  .check02 li a {
    padding: 2.2rem 0.5rem 2.2rem 0.5rem;
  }
  .check02 li .ico {
    background-size: 54% auto;
    padding: 26px;
  }
  .check02 li .text {
    padding: 0 12px 0 9px;
  }
  .cal-bott .pr-w {
    padding-left: 0;
  }
  .pr-w .date {
    float: inherit;
  }
  .primary {
    margin-top: 7%;
    margin-left: 0;
    float: inherit;
    width: 100%;
  }

  /*결재함 품목*/
  .view-con1 th,
  .view-con1 td {
    text-align: center;
    padding: 13px 5px !important;
  }
}

@media (max-width: 1200px) {
  .monthbot {
    font-size: 0.91rem;
  }
  /*사내문서 list*/
  .doc-w li {
    width: 30%;
  }
}

.main-req-tablet-main {
  margin-top: 12px;
}

@media (max-width: 1150px) {
  #container {
    padding: 50px 15px 50px 195px;
  }

  .main-req-tablet-main {
    margin-top: 8px;
  }

  .checkmain {
    display: inline-block;
  }

  .checkmain-full {
    display: none;
  }

  .grid-item {
    grid-column: 1 / span 2;
  }

  .main-box {
    grid-template-columns: 1fr 1fr;
  }
  .checkcont {
    margin-bottom: 1.1%;
    width: 100%;
    margin-right: 0;
    height: inherit;
  }
  .check01-3 {
    width: 100%;
  }
  .check01-4 {
    width: 100%;
  }
  .checkcal {
    width: 100%;
    /* height: 568px; */
  }

  .row-r li a {
    min-width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .check02 li .ico {
    background-size: auto;
    width: 7%;
    margin: 0 auto;
    display: block;
  }
  .check02 li .text {
    text-align: center;
    margin-top: 4%;
  }
  .check02 li i {
    display: none;
  }
  .cal02 {
    width: 23%;
    padding: 5% 0 0 1.5%;
  }
  .cal02 .holly .holico {
    width: 63px;
    height: 63px;
  }
  .total-people {
    min-width: 100%;
  }
  .annual-leave {
    min-width: 100%;
  }
}

#vacation-rule-box {
  width: 33%;
  height: 100px;
  border-radius: 5px;
  background-color: #fafafa;
  padding: 10px 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/*태블릿*/
@media (max-width: 1030px) {
  #vacation-rule {
    /* display: flex; */
    width: 100%;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-right: 30px;
    padding: 30px;

    div {
      flex-direction: column;
    }
  }

  #vacation-rule-box {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    background-color: #fafafa;
    padding: 10px 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .con-login .loimage {
    display: none;
  }
  .flex-log.login-l {
    display: none;
  }
  .flex-log.login-r {
    width: 1100px;
    padding: 0 24px;
    overflow-x: auto;
  }
  .register-table {
    display: block;
  }

  .register-flex {
    display: inline-block;
  }
  .input-email select {
    width: 20%;
  }

  /* .register-form {
    max-width: 1000px;
    overflow-x: auto;
  } */

  .monthtop h4 {
    text-align: center;
  }
  .cal01 {
    grid-template-columns: 1fr 1fr;
  }
  .worknews {
  }
  .workboard {
  }
  .check02 li a {
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
  }
  .monthbot td a {
    width: 35px;
  }

  /*기업메인*/
  .checkcont1 {
    width: 100%;
    height: inherit;
  }
  .main-box {
    display: inline-block;
  }

  .check01 {
    width: 100%;
  }
  .workboard1 {
    margin-top: 2%;
    margin-left: 0;
  }
  .checkcal1 {
    width: 100%;
    margin-top: 2%;
  }
}

@media (max-width: 768px) {
  .chat-box {
    display: none !important;
  }
  .tdbutt {
    width: 50%;
  }
  .lobutt {
    width: 100%;
  }

  body {
    padding-left: 0;
  }
  .nav-bg {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
  }
  #left_nav.nav_c {
    display: none;
    position: fixed;
    top: 80px;
    left: inherit;
    right: 0;
    overflow: auto;
    max-height: calc(100vh - 80px);
  }
  .lome {
    display: none;
  }
  #container {
    padding: 50px 15px 50px 15px;
  }

  /*사내문서 list*/
  .doc-w li {
    margin: 0.5% 0 0.5% 0.5%;
  }
  .updoc i {
    display: none;
  }
  .doc-w .re-write {
    right: 12px;
    top: 12px;
  }
  .doc-w li .doc-img {
    padding: 25px 0;
  }
  .doc-img .img-con {
    background-size: auto 80%;
  }
  .doc-img .file-name {
    font-size: 13px;
    bottom: 18px;
  }
  .doc-w li .doc-img .doc-tit {
    margin-top: 4px;
  }
  .doc-w li .doc-bt a {
    padding: 9px 0;
  }

  /*마이페이지*/
  .mypage-top {
    padding: 25px;
  }
  .my-table {
    margin-top: 0;
  }
  .my-table th {
    width: 20%;
  }
  .my-table .my-butc {
    width: 30%;
  }
  .my-phone1 a {
    margin-left: 2%;
  }
  .my-phone1 input {
    width: 60%;
  }
  .my-table .email0 {
    width: 50%;
  }
  .my-table .email01 {
    width: 45%;
  }
  .my-table select {
    margin-top: 2%;
    width: 100%;
  }
  .my-table .my-butc1 {
    width: 30%;
  }
  .my-table .sign-area {
    height: 130px;
  }
  .my-table .sign-w {
    width: 62%;
  }
}

@media (max-width: 500px) {
  .docs-popup {
    display: flex;
    flex-direction: row-reverse;
    max-width: 100%;
    overflow-x: auto;
  }

  .docs-popup-title {
    display: block;
  }

  .docs-popup-tr {
    flex-direction: column;

    .docs-popup-td {
      width: 100%;
      margin-bottom: 8px;
    }
    div button {
      width: 50%;
    }
  }

  .cal01 li .icocal {
    display: none;
  }
  .clist {
    padding-top: 8%;
  }
  .check02 li {
    text-align: left;
  }
  .check02 li .ico {
    margin: inherit;
    background-color: inherit;
    background-position: 4px center;
    background-size: 60%;
    padding: 20px;
  }
  .check02 li .text {
    text-align: left;
    padding: 0;
  }
  .check02 li a {
    padding: 1.2rem 0.7rem 1.2rem 0.7rem;
  }
  .check02 li .text p {
    font-size: 1.05rem;
  }
  .check {
    overflow: hidden;
  }
  .check ul dl dd {
    font-size: 0.9rem;
  }
  .check li:nth-child(3) {
    border: none;
  }
  /*사내문서list*/
  .doc-w li {
    margin: 1% 0 0 0;
  }
  .doc-w li .doc-bt a {
    font-size: 12px;
  }
  .doc-w li .doc-img p {
    font-size: 14px;
  }
  /*마이페이지*/
  .mypage-top {
    padding: 20px;
  }
  .mypage-top .my-img {
    padding-top: 13px;
  }
  .my-img .profile-img {
    width: 110px;
    height: 110px;
  }
  .my-img .profile-img img {
    width: 100%;
    height: 100%;
  }
  /*.my-img .profile-img img{
        width: 110px;
        height: 110px;
    }*/

  .mypage-top .my-txt {
    margin-left: 40px;
  }
  .my-table th {
    width: 24%;
    font-size: 13px;
  }
  .my-table th.phone-t {
    padding-top: 30px;
  }
  .my-table td {
    padding: 15px 0;
  }
  .my-table .my-butc,
  .my-table .my-butc1 {
    font-size: 13px;
    padding: 10px 0;
  }
  .my-table .sign-area {
    height: 115px;
  }
  .my-table .my-butcs,
  .my-table .my-butcs1 {
    font-size: 13px;
    padding: 10px 0;
  }
  .my-table .my-butc {
    width: 42%;
  }
  .my-table tfoot td {
    padding: 45px 0 0 0;
  }
  .my-table .my-bt,
  .my-table .my-btc {
    padding: 10px 0;
  }
  .my-phone0 span {
    width: 56%;
    display: inline-block;
  }
  .my-phone0 a {
    margin-left: 0;
  }
  .my-phone0 input {
    width: 100%;
    margin-bottom: 8px;
  }
  .my-phone1 input {
    width: 100%;
    margin-bottom: 8px;
  }
  .my-phone1 a {
    margin-left: 0;
  }
  .my-table .my-butc1 {
    width: 100%;
  }
  .my-table .email01 {
    width: 43%;
  }
  .my-table .sign-w {
    width: 100%;
  }
}
@media (max-width: 400px) {
  /*사내문서list*/
  .doc-w li {
    width: 48%;
  }

  /* .cal {
    height: 115px;
  } */
  .clist {
    padding-top: 8%;
  }
  .clist strong {
    font-size: 2.5rem;
  }
  .cal01 .clist span {
    padding-left: 0;
  }
  .cal01 .clist .num {
    font-size: 0.8rem;
  }
  .cal02 {
    padding: 66px 0 0 0;
  }
  .check02 li .text {
    margin-top: 0;
  }
  .check02 li .ico {
    width: 3%;
    display: inline-block;
  }
  .check02 .mana3 {
    margin-bottom: 0;
  }
  /*마이페이지*/
  .mypage-top .my-img {
    float: inherit;
    text-align: center;
    padding-top: 0;
  }
  .my-img .profile-img {
    width: 90px;
    height: 90px;
  }
  .my-img .pro-cam {
    bottom: 0;
    right: 110px;
  }
  .my-img .pro-cam a {
    background-size: 59% auto !important;
    width: 35px;
    height: 35px;
  }
  .mypage-top {
    padding: 15px;
  }
  .mypage-top .my-txt {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
  .my-txt th,
  .my-txt td {
    line-height: 25px;
  }
  .my-txt .file-my {
    padding: 3px 9px;
  }
  .my-table .email01 {
    width: 45%;
  }
  .my-table .email0 {
    width: 46%;
  }
}

@media (max-width: 360px) {
  .cal01 li {
    padding: 1rem 0 0 0.6rem;
  }
  /*마이페이지*/
  .my-img .pro-cam {
    right: 90px;
  }
}

.search_input {
  width: 300px;
}
.finput {
  width: 100%;
  height: 40px;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-sizing: border-box;
}
/*비활성화버튼 회색바탕과 회색보더*/
.btnoff {
  color: #757575;
  background: #b5b5b5;
  border: 0;
}
.btnonf {
  color: #a9a9a9 !important;
  background: #fff;
  border: 1px solid #ddd;
}

/*인사-친권동의서*/
.finput1 {
  width: 73%;
  height: 48px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-sizing: border-box;
}
.butcp {
  padding: 14px 4px;
  box-sizing: border-box;
  height: 48px;
  width: 25%;
  color: #fff;
}
.work-view tr.work-viewp td {
  height: 150px;
}

.search_input {
  display: inline-block;
}

.view-con .search_input .finput {
  /*height: 40px;*/
}

.view-con .butc {
}
.searchbtn {
  margin-right: 5px;
}
.buts {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 6px;
  vertical-align: middle;
  background: #4e89eb;
}

.buts a {
  color: #fff;
  display: inline-block;
  padding: 0.5rem 1.1rem;
  line-height: 1.8rem;
  vertical-align: middle;
}

.pop_table {
  border: 1px solid #eaeaea;
  border-collapse: collapse;
  width: 100%;
}

.pop_row {
  border: 1px solid #eaeaea;
  border-collapse: collapse;
  line-height: 3rem;
}
th.pop_row {
  background: #f7f7f7;
}
td.pop_row {
  padding: 2px 11px;
}

.pop_textarea {
  width: 100%;
  height: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  resize: none;
}

.layer-pop .head .title {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
}

/*pageload*/
#loading {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  margin-top: 0px;
  top: 0px;
  left: 0;
}
#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}
#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin-top: -30px;
  margin-left: -30px;
  -webkit-animation: loading-center-absolute 1s infinite;
  animation: loading-center-absolute 1s infinite;
}
.object {
  width: 20px;
  height: 20px;
  background-color: #fff;
  float: left;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;
}
.object:nth-child(2n + 0) {
  margin-right: 0px;
}
#object_one {
  -webkit-animation: object_one 1s infinite;
  animation: object_one 1s infinite;
}
#object_two {
  -webkit-animation: object_two 1s infinite;
  animation: object_two 1s infinite;
}
#object_three {
  -webkit-animation: object_three 1s infinite;
  animation: object_three 1s infinite;
}
#object_four {
  -webkit-animation: object_four 1s infinite;
  animation: object_four 1s infinite;
}

/* 팝업 관련 css */
.preview-pop {
  position: absolute;
  z-index: 9999;
  display: none;
  top: 100px;
  left: 100px;
}

.main-req-list {
  border: 1px solid #efefef;
  border-radius: 0 11px 11px 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 15px;

  .main-docs-title {
    font-size: 1rem;
    line-height: 1.25rem;
    color: #535353;
  }

  .main-docs-req {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.75rem;
    color: #898989;
  }
}

@-webkit-keyframes loading-center-absolute {
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading-center-absolute {
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes object_one {
  50% {
    -ms-transform: translate(20px, 20px);
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}
@keyframes object_one {
  50% {
    -ms-transform: translate(20px, 20px);
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}

@-webkit-keyframes object_two {
  50% {
    -ms-transform: translate(-20px, 20px);
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}
@keyframes object_two {
  50% {
    -ms-transform: translate(-20px, 20px);
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}

@-webkit-keyframes object_three {
  50% {
    -ms-transform: translate(20px, -20px);
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}
@keyframes object_three {
  50% {
    -ms-transform: translate(20px, -20px);
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}

@-webkit-keyframes object_four {
  50% {
    -ms-transform: translate(-20px, -20px);
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}
@keyframes object_four {
  50% {
    -ms-transform: translate(-20px, -20px);
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}
