.bordered {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 12px 30px;
}

.card-title {
  font-size: 16px;
}

.employee-status {
  display: flex;
  flex-direction: column;
}
