@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
textarea {
  max-width: 100%;
  resize: none;
  padding: 5px;
  border: none;
}
.textarea {
  border: 1px solid #eee;
  width: 100%;
}
/* table {
  width: 100%;
  table-layout: fixed;
}
table th,
table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */
/* table th {
  width: 15%;
} */
a {
  color: #232222;
  text-decoration: none !important;
}
ul a:hover {
  color: #232222 !important;
}
.lnb ul {
  list-style: none;
  padding: 0;
}
.of-auto {
  overflow: auto;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-none {
  float: none;
}
.w-8 {
  width: 8%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-28 {
  width: 28%;
}
.w-35 {
  width: 35%;
}
.w-38 {
  width: 38%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}
.w-85 {
  width: 85%;
}

.table-responsive::-webkit-scrollbar {
  height: 4px;
}

/* 수정 */
.pencil-img {
  background: url(../img/add-com.png) no-repeat 12px;
  padding-left: 35px !important;
}
.doc-insert-btn {
  background: url(../img_new/doc_insert.svg) no-repeat 12px;
  padding-left: 35px !important;
}
.fixed-th-reason table th:nth-of-type(6) {
  width: 400px;
}
.staffModal #modalwin {
  max-width: 1100px;
}
.select-width {
  width: 120px;
}

/* 내용 높이 수정 */
.content-td-height {
  height: 100%;
}

/* 전자결제 select 길이 수정 */
.elec-select {
  width: 150px;
}

.elec-select2 {
  width: 80%;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
.header {
  position: fixed;
  width: 100%;
  height: 70px;
  color: #fff;
  background-color: #22ccb7;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}
.gnb {
  display: flex;
  align-items: center;
}
.pillbtn {
  margin-right: 16px;
}
.pill {
  /* width: 124px;
  height: 36px; */
  border: none;
  border-radius: 30px;
  color: #ffffff;
  font-size: 13px;
  margin-right: 5px;
  padding: 10px 20px;
}
.pill > img {
  width: 20px;
  height: 15px;
}
.blue {
  background-color: #1a5c96;
}
.pink {
  background-color: #fd9f9f;
}
.gray {
  background-color: #8e8e8e;
}
.gnbbtn {
  border: none;
  background-color: transparent;
  color: #ffffff;
  margin: 0 12px;
  font-size: 16px;
  position: relative;
}
.gnbbtn > img {
  width: 30px;
  height: 30px;
}
.alert-num {
  position: absolute;
  top: -4px;
  right: -18px;
  background-color: #ff5442;
  border-radius: 30px;
  padding: 3px 6px;
  font-size: 10px;
}
.section {
  display: flex;
  height: calc(100% - 70px);
}
.mobile-menu {
  display: none;
  cursor: pointer;
}

.menu {
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
  height: calc(100vh - 70px);
  border-right: 1px solid #eee;
  padding-top: 1.5em;
  margin-top: 70px;
  background-color: #fff;
  z-index: 1;
}
.lnb {
  width: 230px;
  height: calc(100% - 70px);
}
.left-menu {
  height: 100%;
  border-right: 1px solid #eee;
  padding-top: 1.5em;
  min-width: 250px;
}
.main > a,
.dmain > a {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 1em;
  padding-left: 1.8em;
  color: #232222;
  font-size: 16px;
  cursor: pointer;
}
.arrow {
  display: inline-block;
  color: #c9c9c9;
  float: right;
}
.arrowActive {
  transform: rotate(180deg);
}
.sub {
  display: none;
  margin-left: 0.5em;
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
}
.sub_on {
  display: block;
}
.sub > li > a {
  display: block;
  padding: 0.7em 2.5em;
  color: #363636 !important;
}
.sub > li > a:hover {
  color: #22ccb7 !important;
}
.sub a::before {
  content: "- ";
  color: #232222;
}
.sub .submenu-inline {
  display: inline;
}
#menuset {
  position: fixed;
  bottom: 0;
  width: 230px;
  height: 50px;
  font-size: 18px;
  background: #fafafa url("../ico/icon_메뉴설정.svg") no-repeat 21%;
  background-size: 35px;
  text-align: center;
  padding-top: 14px;
  /*cursor: pointer;*/
}

/* mobile menu start */
#mmenu {
  overflow: auto;
  display: none;
  position: fixed;
  z-index: 10000;
  width: 250px;
  height: 100%;
  top: 0;
  right: -250px;
  background-color: white;
}
#mgnb {
  background-color: #22ccb7;
  padding: 10px;
}
#mgnbicon {
  text-align: right;
}
#mgnbicon img {
  width: 20px;
  height: 20px;
}
#muserinfo {
  margin: 0 10px 10px 10px;
}
#muserimg {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
  background-color: #fff;
}
#musertxt {
  color: #fff;
  padding-top: 8px;
}
#mlnb {
  list-style: none;
}
#mlnb ul {
  list-style: none;
}
.css-26l3qy-menu {
  z-index: 9999 !important;
}
.mmain > a,
.mdmain > a {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 1em;
  padding-left: 1.8em;
  color: #232222;
  font-size: 16px;
}
.mmain > a:hover {
  color: #22ccb7;
}
.msub {
  display: none;
  margin-left: 0.5em;
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
}
.msub_on {
  display: block;
}
.msub > li > a {
  display: block;
  padding: 0.7em 2.5em;
  color: #363636 !important;
}
.msub > li > a:hover {
  color: #22ccb7 !important;
}
.msub a::before {
  content: "- ";
  color: #232222;
}
#mback {
  position: fixed;
  z-index: 9010;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
}
/* mobile menu end */

.main-container {
  height: 100%;
  width: 100%;
  padding: 25px 60px 60px 60px;
  margin-top: 70px;
  margin-left: 230px;
}
.substanceTitle {
  padding: 10px;
  margin-bottom: 30px;
  /* margin-top: 50px;
  margin-left: 230px; */
}
.date-filtering {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.all-group {
  position: relative;
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: stretch; */
}
/* .all-group-holiday {
  flex-wrap: nowrap;
} */
.searchbtn-group {
  display: flex;
  gap: 5px;
}
.mobile-block {
  display: none;
}
.mobile-table {
  display: none;
}
.selectbox {
  display: inline-block;
}
.custom-select {
  display: inline-block;
  width: 153px;
  height: 49px;
  padding: 0.5em;
  border-color: #eeeeee;
  border-radius: 5px;
  color: #9a9a9a;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../img/arrow.jpg") no-repeat 100% 50%;
}
.plus {
  width: 38px;
  height: 38px;
  background: #22ccb7 url("../img_new/icon_plus.svg") no-repeat center;
  background-size: 30px;
  border: none;
  border-radius: 5px;
}
.download {
  width: 38px;
  height: 38px;
  /* background: #22ccb7 url("../img/다운로드.svg") no-repeat center; */
  background: url(../img_new/icon_download.svg) no-repeat center;
  background-size: 30px;
  border: none;
  border-radius: 5px;
}
.rdw-editor-wrapper {
  border: 1px solid #eee;
}
.datebox {
  /* width: 200px; */
  min-height: 30px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  margin-right: 5px;
}
.daybtn {
  /* width: calc(200px / 3); */
  border: none;
  border-right: 1px solid #eeeeee;
  background-color: transparent;
  color: #9a9a9a;
  padding: 10px 12px;
}
.daybtn:last-of-type {
  border-right: none;
}
.actdaybtn {
  background-color: #22ccb7;
  color: #ffffff;
}
.actdaybtn:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.actdaybtn:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom-input {
  display: inline-block;
  /* width: 150px; */
  min-height: 30px;
  padding: 10px 12px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  color: #9a9a9a;
}

input[type="time"] {
  border: 1px solid #eee;
  border-radius: 4px;
  min-height: 30px;
  padding: 9px 12px;
}

input[type="date"] {
  position: relative;
  min-height: 30px;
  padding: 9px 12px;
  /* width: 130px; */
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  z-index: 1;
}
input[type="date"]:after {
  position: absolute;
  top: 50%;
  right: 10px;
  content: "";
  width: 20px;
  height: 22px;
  background: url("../img/calendar.png") no-repeat center center / contain;
  background-size: 15px;
  transform: translateY(-50%);
  z-index: 0;
}

/* .date-input {
  width: 142px;
  margin: 0 5px;
} */
.wave {
  padding-top: 7px;
  margin: 0 5px;
}
.relative {
  position: relative;
}
.inputsearch {
  position: absolute;
  right: 0;
  top: 5px;
}
.searchbtn {
  /* top: 10px;
  right: 5px; */
  width: 30px;
  height: 30px;
  background: url("../ico/icon_돋보기.svg") no-repeat center !important;
  background-size: 20px !important;
}
.table-scroll {
  overflow: auto;
}
table {
  width: 100%;
  border-top: 1px solid #e5e5e5 !important;
  border-collapse: collapse;
}
table th,
table td {
  padding: 0.5em;
  color: #757575;
}
table tr {
  border-bottom: 1px solid #eee;
}
.table-responsive tr:first-of-type {
  border-bottom: none;
}
table td {
  /* border-bottom: 1px solid #eee; */
  height: 50px;
}

.parent-scroll {
  height: 300px;
  overflow-y: auto;
}

.ql-clipboard {
  position: fixed !important;
}

.ql-container {
  height: 300px !important;
  font-size: 18px !important;
  color: #222222 !important;
}

.ql-editor {
  /* height: 300px !important; */
  width: calc(100vw - 500px);
  max-width: 100%;
  p {
    word-wrap: break-word;
  }
}

.ql-size-huge,
.ql-size span[data-value="huge"]::before {
  font-size: 22px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}

.text-center-title {
  text-align: center !important;
  background-color: #fafafa;
  font-weight: normal !important;
}
.text-center {
  text-align: center !important;
  font-weight: normal !important;
}
/* input[id="check"] {display: none;}
input[id="check"] + label {
  width: 22px;
  height: 20px;
  border: 2px solid #DCDCDC;
  cursor: pointer;
}
input[id="check"]:checked + label:after {
  content: '\2714';
  position: relative;
  top: -4px;
  left: 2px;
  font-size: 14px;
} */
/* check custom */
label {
  padding-top: 2px;
  /* overflow: auto; */
  display: inline-block;
  text-align: center;
}
label input[type="checkbox"] {
  display: none;
}
.chkbox {
  width: 22px;
  height: 20px;
  /* float: left; */
  border: 2px solid #dcdcdc;
  cursor: pointer;
  text-align: center;
}
.checked {
  background: #22ccb7 url("../img/check.png") no-repeat center;
  background-size: 16px;
}
#total .chkbox {
  width: 22px;
  height: 20px;
}
#total .checked {
  background: #22ccb7 url("../img/check.png") no-repeat center;
}

/* vacation css */
.smallbtn {
  /* width: 60px;
  height: 35px; */
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 6px 13px;
  margin-right: 3px;
}
.appbtn {
  background-color: #23cbb6;
}
.rebtn {
  background-color: #fd9f9f;
}
.printbtn {
  border: 1px solid #e5e5e5;
  color: #bebebe;
}
.sdelbtn {
  background-color: #535353;
}

/* Fresh css */
.holidaybtn2 {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  border: none;
  margin-right: 3px;
}
.holidaybtn {
  /* width: 90px;
  height: 49px; */
  height: 40px;
  padding: 0 12px;
  border-radius: 5px;
  color: #fff;
  border: none;
  margin-right: 3px;
}
.trans {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #626262;
  color: #626262;
}

.grid-3items {
  display: grid;
  grid-template-columns: 280px 200px 1fr;
  margin: 8px 0px;
}
/* .css-b62m3t-container {
  width: 100%;
  float: left;
} */
.css-1s2u09g-control {
  border: 1px solid #eee !important;
  color: #9a9a9a !important;
}
.graybtn-lg {
  background-color: #626262 !important;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-size: 16px;
}

.pinkbtn-lg {
  background-color: #fd9f9f !important;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-size: 16px;
}

.primarybtn-lg {
  background-color: #22ccb7 !important;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  margin: 0px 4px;
  font-size: 16px;
}
.primarybtn-md {
  background-color: #22ccb7 !important;
  color: #fff;
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0 9px;
  /* padding: 9px 12px; */
  border-radius: 4px;
}

.pinkbtn-md {
  background-color: #fd9f9f !important;
  color: #ffffff;
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0 9px;
  /* padding: 9px 12px; */
  border-radius: 4px;
}

.primarytoggle-md {
  background-color: #535353;
  color: #fff;
  border: none;
  padding: 9px 12px;
  border-radius: 4px;
}
.primarytoggle-md:disabled {
  background-color: #22ccb7;
}

.secondarybtn-md {
  border: 1px solid #eeeeee;
  background-color: transparent;
  color: #9a9a9a;
  padding: 9px 12px;
  border-radius: 4px;
}
.secondarybtn-sm {
  border: 1px solid #eeeeee;
  background-color: transparent;
  color: #9a9a9a;
  border-radius: 4px;
  padding: 4px 8px;
}
.bluebtn-sm {
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: #15abdb;
}
.primarybtn-sm {
  background-color: #22ccb7;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
}
.pinkbtn-sm {
  background-color: #fd9f9f;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
}

.redbtn-sm {
  background-color: #ce3426;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
}
.blackbtn-sm {
  background-color: #535353;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
}
.greenbtn:disabled {
  cursor: not-allowed;
  background-color: #626262;
}
.greenbtn {
  background-color: #22ccb7;
  color: #ffffff;
}
.graybtn {
  background-color: #626262;
  color: #ffffff;
}
.bluebtn {
  background-color: #18518b;
  color: #ffffff;
}
.redbtn {
  background-color: #e85d6c;
  color: #ffffff;
}
.delbtn {
  /* width: 60px;
  height: 35px; */
  background-color: #22ccb7;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 6px 13px;
}
.graydelbtn {
  /* width: 60px;
  height: 35px; */
  background-color: #626262;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 6px 13px;
}

/* work_order css */
.trashbtn {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #22ccb7;
  background: #ffffff url("../ico/icon_휴지통.svg") no-repeat center;
  background-size: 25px;
}

.dream_cafe {
  width: auto;
  height: 670px;
  cursor: pointer;
  border-radius: 24px;
  background-image: url("../img/dream_cafe.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.t-download {
  width: 30px;
  height: 30px;
  background: url("../img/첨부파일.svg") no-repeat center;
  background-size: 25px;
}
.ongoing {
  color: #22ccb7;
}
.complete {
  color: #bababa;
}

/* workorder_write css */
.writetable {
  color: #363636;
  margin-bottom: 40px;
}
.writetable th,
.writetable td {
  border: 1px solid #e5e5e5;
  padding: 15px;
}
#modalbtn {
  display: inline-block;
  padding: 11px 20px;
  /* width: 81px;
  height: 49px; */
  background-color: #22ccb7;
  border: none;
  border-radius: 5px;
  color: #ffffff !important;
  font-size: 18px;
  cursor: pointer;
}
.write-date {
  width: 135px;
  height: 39px;
  border: 1px solid #e5e5e5;
  color: #4e4e4e;
}
.write-input {
  width: 100%;
  padding: 0.3em;
  border: none;
}
.write-input::placeholder {
  color: #b1b1b1;
}

::-webkit-file-upload-button {
  cursor: pointer;
}
.filetype {
  position: relative;
  display: inline-block;
  vertical-align: top;
  *margin-right: 4px;
  width: 100%;
}

.input-file {
  width: 70px;
}

.filetype * {
  vertical-align: middle;
}

.filetype .file-text {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 50%;
  height: 20px;
  line-height: 20px;
  z-index: 10;
  border: none;
  background-color: transparent;
}

.filetype .file-select {
  position: absolute;
  top: 5px;
  left: 15px;
  width: 80px;
  overflow: hidden;
  cursor: pointer;
}

.filetype .file-select .input-file {
  /* width: 80px; */
  /* height: 40px; */
  /* filter: alpha(opacity=0); */
  opacity: 0;
}

.filetype .file-btn {
  display: inline-block;
  background-color: #b2b2b2;
  width: 116px;
  height: 35px;
  line-height: 22px;
  padding: 7px 15px;
  color: #fff !important;
  cursor: pointer;
  *margin-left: 4px;
}
.writebtnbox {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 40px;
}
.writebtn {
  width: 130px;
  height: 45px;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 16px;
}
.submitbtn {
  background-color: #40b8a8;
}
.rejectbtn {
  background-color: #fd9f9f;
}
.cancelbtn {
  background-color: #959595;
}

/* Workreport css */
.confirm {
  /* width: 60px;
  height: 35px; */
  border-radius: 5px;
  padding: 6px 13px;
}

/* Workreport_view css */
.circle {
  list-style: disc;
  padding-left: 30px;
}

/* Payment css */
.modifybtn {
  /* width: 60px;
  height: 35px; */
  color: #ffffff;
  background-color: #23cbb6;
  border: none;
  border-radius: 5px;
  padding: 6px 13px;
  margin-right: 3px;
}
.outlinebtn {
  /* width: 60px;
  height: 35px; */
  color: #bebebe;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 6px 13px;
}

/* pay_modify css */
.price {
  text-align: center;
}
.total {
  text-align: right !important;
}

/* business_guide_write css */
.writetable .custom-input {
  width: 392px;
}

/* schedule css */
/* table {
  clear: both;
}
th {
  height: 50px;
  width: 70px;
  background-color:  ;
}
td {
  text-align: center;
  height: 50px;
  width: 70px;
  background-color: #FFFDC5;  
}
input {
  height: 50px;
  width: 70px;
  border: none;
  background-color:  ;
  font-size: 30px;
}
.year_mon{
  font-size: 25px;
}
.colToday{
  background-color: #FFA07A;
} */

#btn1 {
  padding: 0.4em 0.9em;
  background-color: white;
  border: 4px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 0.5em;
}
#btn1:hover {
  border-color: #f0f0f0;
}

/* modal css */
#modal {
  position: fixed;
  z-index: 100000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* overflow: auto; */
  overflow-x: scroll;
}
#modalwin {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.5);
  max-width: 700px;
  min-width: 600px;
  margin: 100px auto;
}
#modalhead {
  padding: 20px 30px;
  border-bottom: 1px solid #e5e5e5;
  color: #2e2e2e;
  overflow: auto;
}
#modalhead > span {
  font-size: 1.2rem;
}
#modalclose {
  float: right;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 16px;
  background-color: #22ccb7;
  color: #ffffff;
  font-size: 25px;
  line-height: 8px;
}
#modalsec {
  padding: 20px 30px;
}
.modalscroll {
  overflow-y: scroll;
  padding: 20px 30px;
  max-height: 500px;
}
.modal-input {
  width: 100%;
  height: 59px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 5px;
}

#modalstep {
  position: fixed;
  z-index: 100000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* overflow: auto; */
  overflow-x: scroll;
}
#modalheadstep {
  padding: 15px 30px;
  border-bottom: 1px solid #e5e5e5;
  overflow: auto;
  border-radius: 8px 8px 0 0;
  background-color: #22ccb7;
  color: #fff;
}
#modalheadstep > span {
  font-size: 1.2rem;
}

.m-inputsearch {
  position: relative;
}
.m-searchbtn {
  position: absolute;
  top: -45px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: url("../ico/icon_돋보기.svg") no-repeat center;
  background-size: 25px;
}
/* business_guide_write modal css start */
.modaltitle-input {
  width: calc(100% - 80px);
  height: 49px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 5px;
}
#titlesearch {
  width: 72px;
  height: 49px;
  border-radius: 5px;
  background-color: #1ec5af;
  color: #ffffff;
  font-size: 20px;
}

/* business_guide_write modal css end */
/* staff modal css start */
#staffname {
  color: #757575 !important;
  cursor: pointer;
}
/* .infotable,
.infotable tr {
  border: 1px solid #E5E5E5;
}
.infotable .text-center-title {
  color: #4E4E4E;
}
.infotable td {
  padding: 6px;
}
.info_input {
  width: 100%;
  height: 41px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 5px;
} */
#stafftable th,
#stafftable td {
  border-bottom: 1px solid #e5e5e5;
}
#historytable th,
#historytable td {
  padding: 1px;
  border: 1px solid #c7c7c7;
}
/* staff modal css end */

#modaltable {
  /* border: none; */
  margin-top: 20px;
}
#modaltable td {
  /* border: none; */
}
.titleborder {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.contborder {
  border-bottom: 1px solid #e5e5e5;
}
.modaltablebtn {
  width: 71px;
  height: 33px;
  background-color: #22ccb7;
  border: none;
  border-radius: 5px;
  color: #ffffff;
}
#modalfoot {
  padding: 30px;
  text-align: center;
}
.fbtn {
  width: 106px;
  height: 47px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
}
.btn-main {
  background-color: #40b8a8;
}
.btn-sub {
  background-color: #959595;
}

/* select css */
.wrap {
  user-select: none;
  margin-bottom: 10px;
  display: inline-block;
}
.wrap ul li,
.wrap ol li {
  list-style: none;
}
.select_box {
  margin-right: 10px;
  width: 130px;
}
.select_box .box {
  display: inline-block;
  position: relative;
  width: 130px;
}
.select_box .box .select {
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 10px;
  width: 130px;
  height: 49px;
  line-height: 49px;
  font-size: 16px;
  color: #9a9a9a;
  cursor: pointer;
}
.select_box .box .select:after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 10px;
  color: #c9c9c9;
}
.select_box.on .box .select:after {
  content: "▲";
}
.select_box .box .list {
  display: none;
  overflow-y: auto;
  position: absolute;
  top: 80%;
  left: 5px;
  z-index: 10;
  border: 1px solid #fafafa;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  width: 93%;
  max-height: 200px;
  background-color: #fff;
  padding-left: 0;
  color: #757575;
}
.select_box .box .list > li {
  box-sizing: border-box;
  padding-left: 25px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}
.select_box .box .list > li:hover {
  background-color: #ccc;
}
.selected {
  background: url("../img/select-check-mark.svg") no-repeat 7%;
  background-size: 13px;
  padding: 0 5px;
}
/* type03 표 구분 */
.type03_1,
.type03 {
  width: 100%;
}
.type03_1 .box,
.type03 .box {
  width: 100%;
}
.type03_1 .box .select,
.type03 .box .select {
  position: relative;
  border: none;
  width: 100%;
  font-size: 15px;
  color: #757575;
  cursor: pointer;
}
.type03_1 .box .select:after,
.type03 .box .select:after {
  content: "";
}
.type03_1.on .box .select:after,
.type03.on .box .select:after {
  content: "";
}
.type03_1 .box .list,
.type03 .box .list {
  display: none;
  position: absolute;
  top: 40px;
  left: 30%;
  z-index: 10;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding-left: 0;
  width: 105px;
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  color: #757575;
  text-align: start;
}
.type03_1 .box .list > li,
.type03 .box .list > li {
  padding-left: 23px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}
/* type04 payment */
/* #type04 .box .select:after {
  content: '>';
  position: absolute;
  transform: rotate(90deg);
  top: 0;
  right: 10px;
  color: #707070;
}
#type04.on .box .select:after {
  content: '>';
  transform: rotate(270deg);
} */
#type04 {
  margin-top: 0;
  margin-right: 0;
  width: 100%;
}
#type04 .box .select:after {
  content: "";
  position: absolute;
  transform: rotate(135deg);
  display: inline-block;
  width: 5px;
  height: 5px;
  border-top: 3px solid #707070;
  border-right: 3px solid #707070;
  top: 16px;
  right: 12px;
}
#type04.on .box .select:after {
  content: "";
  transform: rotate(-45deg);
  top: 18px;
  right: 12px;
}
#type04 .box .list > li {
  padding-left: 10px;
}
#type04 .selected {
  /* background: url(/static/img/check-mark.svg) no-repeat 97%; */
  background-position: 97%;
  background-size: 13px;
  padding: 0 5px;
}
/* type05 notice_write */
#type05 .box .select:after {
  content: "";
  position: absolute;
  transform: rotate(135deg);
  display: inline-block;
  width: 5px;
  height: 5px;
  border-top: 3px solid #707070;
  border-right: 3px solid #707070;
  top: 32%;
  right: 12px;
}
#type05.on .box .select:after {
  content: "";
  transform: rotate(-45deg);
  top: 42%;
  right: 12px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "등록";
  display: inline-block;
  background: #22ccb7;
  border: none;
  padding: 8px 10px;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

/* radio button */
.container {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
  font-size: 15px;
  width: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.s-container {
  width: 60px;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #eee;
  border: 1px solid #bfbfbf;
}
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
.container input:checked ~ .checkmark {
  background-color: #fff;
  border: 2px solid #aaa;
}
.checkmark:after {
  content: attr(data-after);
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  left: 3px;
  top: -1px;
  width: 3px;
  height: 7px;
  border: solid #aaa;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*일정관리 리스트*/
.namesearch {
  height: 47px;
  padding: 0 20px;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
}
.calendarbox {
  width: 150px;
  height: 49px;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  margin-right: 5px;
}
.calendar_list {
  width: 75px;
  border: none;
  border-right: 1px solid #eeeeee;
  background-color: transparent;
  color: #9a9a9a;
}
.daybtn:last-of-type {
  border-right: none;
}
.actlist {
  background-color: #22ccb7;
  color: #fff;
}
.actlist:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.actlist:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/*일정등록 버튼 모달*/
.s-custom-input {
  width: 121px;
  height: 30px;
  padding-left: 2px !important;
}
.add-manager {
  padding: 8px 15px;
  background-color: #b2b2b2;
  color: #fff;
}

/*담당자 선택 모달*/
#member-left {
  border-right: 1px solid #e5e5e5;
}
.gray-input {
  background-color: #fafafa;
  border: none;
  width: 100%;
  height: 45px;
  padding: 5px 10px;
}
.person-searchbtn {
  position: absolute;
  top: -38px;
  right: 7px;
  width: 30px;
  height: 30px;
  background: url("../ico/icon_돋보기.svg") no-repeat center;
  background-size: 20px;
}
.team {
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.person-scroll {
  overflow-y: scroll;
}
.scroll-200 {
  max-height: 300px;
}
.scroll-345 {
  max-height: 345px;
}
.person-choice {
  display: flex;
  justify-content: space-between;
  padding: 10px 17px;
  border-top: 1px solid #e5e5e5;
}
.person-choice:last-of-type {
  border-bottom: 1px solid #e5e5e5;
}
.person-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  background-color: #f8f8f8;
  margin-right: 5px;
  position: relative;
}
.green-circle {
  position: absolute;
  left: -6px;
  width: 20px;
  height: 20px;
  background-color: #22ccb7;
  border-radius: 50%;
}
.person-check {
  width: 30px;
  height: 30px;
  background: url("../img/check-mark.svg") no-repeat center;
  background-position-y: 10px;
  background-size: 20px;
}
#member-right {
  padding: 10px 15px;
}
.persin-pill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 50px;
  border-radius: 30px;
  background-color: #fafafa;
  padding: 10px 15px;
  margin: 10px 0;
}
.person-circle-gray {
  width: 30px;
  height: 30px;
  background-color: #bababa;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
}
/* Payment modal end */

/* 회원가입 팝업 */
#join-popup-r {
  position: relative;
}
#join-popup {
  width: 600px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  right: 20%;
  z-index: 10;
  border: 1px solid #707070;
  border-radius: 8px;
}
#join-table {
  background-color: #fff;
}
#join-table th,
#join-table td {
  padding: 10px 18px;
}
#join-table th {
  background-color: #22ccb7;
  color: #fff;
}
/* 쪽지와 채팅 알림 팝업 */
#chatt-popup-r {
  position: relative;
  height: 800px;
}
#chatt-popup {
  position: absolute;
  bottom: 0;
  z-index: 10;
}
.chatt-msg {
  float: left;
  width: 370px;
  height: 90px;
  border-radius: 12px;
  background-color: #005595;
  color: #fff;
  padding: 10px 15px;
  margin-right: 10px;
}
#chatt-top {
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}
#chatt-cont {
  overflow: auto;
  padding-top: 5px;
}
/* #chatt-cont p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* @media (max-width: 991px) {
  .menu {
    display: none;
  }
  .utillbtn {
    display: none;
  }
  .totalMember {
  }
  .breadcrumb-pc {
    display: none !important;
  }
  .mobile-menu {
    display: block;
  }
} */
@media (max-width: 1260px) {
  .time-select .elec-select {
    width: 100px;
  }

  /* table 모바일 */
  .table-responsive {
    overflow-x: auto;
  }
  .table-responsive table {
    min-width: 1100px;
    white-space: nowrap;
  }

  .ql-editor {
    width: 1260px;
  }
  /* table {
    table-layout: fixed;
  } */
  .d-block {
    display: block;
  }
  #modalwin {
    min-width: 100% !important;
  }
  .s-custom-input {
    width: 80px;
  }

  .filetype .file-text {
    display: block;
  }
  input.finput.fin-bt {
    width: 70%;
  }
  .writetable th,
  .writetable td {
    padding-right: 5px;
    padding-left: 8px;
  }
  .padding-0 {
    padding: 0 !important;
  }
  .clist {
    width: 70%;
  }
  .modalscroll {
    padding: 10px;
  }

  .m-mt-12 {
    margin-top: 12px !important;
  }
  .btn-group-inline {
    display: flex;
    margin-top: 12px;
  }
  .d-inline-block {
    width: 100%;
  }
  .doc-grid {
    display: block;
  }
  .doc-card {
    margin-bottom: 20px;
  }
  #mmenu {
    display: block;
  }
  #mback {
    display: block;
  }

  .searchbtn-group {
    flex-direction: column;
  }
  .mobile-block {
    width: 100%;
    position: absolute;
    display: block;
  }
  .date-filtering {
    display: block;
    /* overflow: visible; */
  }
  .mobile-w-100 {
    width: 100%;
  }
  .grid-3items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .grid-3items :nth-child(3) {
    grid-column: span 3;
  }

  .width-100 {
    width: 100%;
  }
  .menu {
    display: none;
  }
  .utillbtn {
    display: none;
  }
  .breadcrumb-pc {
    display: none !important;
  }
  .mobile-menu {
    display: block;
  }
  /* display none */
  .hide-menu {
    display: none;
  }

  .mobile-table {
    display: table;
  }
  .substanceTitle {
    margin-left: 0;
  }
  .header {
    padding: 0 15px 0 5px;
  }
  .main-container {
    padding: 24px;
    margin: 0;
    margin-top: 140px;
  }
  /* .substanceCont {
    margin: 0 20px;
  } */
  .download,
  .datebox,
  .d-none {
    display: none;
  }
  /* input[type="date"] {
    min-width: 48%;
  } */
  .wave {
    padding-top: 7px;
  }
  /* select */
  /* .flex-direction-column {
    display: flex;
    flex-direction: column;
  }
  .flex-direction-row {
    display: flex;
    flex-direction: row;
  } */
  .select_box {
    width: 80px;
    float: left;
    margin-right: 5px;
    /* margin-top: 10px; */
  }
  .select_box .box {
    width: 80px;
  }
  .select_box .box .select {
    width: 80px;
    font-size: 15px;
  }
  /* .select_box .box .select1 {
    border-radius: 8px 0 0 8px;
  }
  .select_box .box .select2 {
    border-left: none;
    border-radius: 0 8px 8px 0;
  }
  .type02 {
    margin-right: 10px;
  } */
  .type03 {
    margin-top: 0;
    margin-right: 0;
  }
  .type03 .box .list {
    left: -5px;
  }
  /* .all-group {
    display: contents;
  } */
  .date-input {
    /* width: 28%; */
    width: calc((100% - 17px) / 2);
    margin-top: 10px;
    margin-right: 0;
    margin-left: 0;
  }
  .txt-search {
    width: 100%;
    margin: 10px 0;
  }
  .inputsearch {
    top: 15px;
    /* left: 410px; */
  }
  /* .all-group {
    flex-wrap: wrap;
  } */
  .pc-pillbtn {
    display: none;
  }
  .pill {
    padding: 10px 12px;
  }
  .pillbtn {
    background-color: #eee;
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: right;
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0 15px;
  }
  .mobile-width {
    width: 90px;
  }
  /* .holidaybtn {
    margin-bottom: 7px;
  } */
  .smallbtn,
  .outlinebtn,
  .modifybtn {
    padding: 4px 5px;
  }
  .trashbtn {
    /* margin-right: 20px; */
    /* margin-bottom: 5px; */
  }
  #stafftable {
    /* display: none; */
  }
  #mobile-stafftable {
    display: table;
  }
  .gray-title {
    display: inline-block;
    background-color: #fafafa;
    padding: 15px 20px;
    border-radius: 8px;
    margin-right: 3px;
  }
  .m-modalbtn {
    padding: 15px 20px;
    background-color: #22ccb7;
    border-radius: 5px;
    color: #fff;
  }
  .table-title {
    text-align: center;
    background-color: #fafafa;
    padding: 15px 0;
    margin-top: 10px;
    margin-left: -18px;
    margin-right: -18px;
  }
  .pay_center {
    text-align: center;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 5px;
    margin: 10px 0;
  }
  .pay_title {
    width: 20%;
    display: inline-block;
    margin-right: 5px;
  }
  .mprice {
    width: 70%;
  }
}
