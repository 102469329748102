/* blank */
/* .totalBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;

}
.breadcrumb {
  color: #757575;
  font-size: 16px;
}
.totalTitle .h3 {
  color: #2E2E2E;
  font-size: 29px !important;
}
.totalManage {
  margin: 0 10px;
}
.admin-pill {
  float: left;
  color: #fff;
  font-size: 18px;
}
.totalMember {
  border: 1px solid #22CCB7;
  border-radius: 10px;
  background-color: rgba(34,204,183,0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  color: #1BAF9D;
  padding: 1em;
}
.member>img {
  width: 35px;
}
.mnum {
  font-size: 32px;
} */

#type01 .box .select:after {
  content: "";
  position: absolute;
  transform: rotate(135deg);
  display: inline-block;
  width: 5px;
  height: 5px;
  border-top: 3px solid #707070;
  border-right: 3px solid #707070;
  top: 35%;
  right: 12px;
}
#type01.on .box .select:after {
  content: "";
  transform: rotate(-45deg);
  top: 42%;
  right: 12px;
}

/* 출퇴근기록 */
.topbtn {
  height: 40px;
  padding: 0 12px;
  border-radius: 5px;
}
.admin-download {
  width: 47px;
  height: 47px;
  background: url("/src/img/첨부파일.svg") no-repeat center;
  background-size: 30px;
  border: none;
  margin-left: 5px;
}
.moreview {
  width: 49px;
  margin-right: 5px;
  font-size: 35px;
  background: #22ccb7 url(/src/img/more.png) no-repeat center;
}
.write {
  padding-left: 35px;
  background-position-x: 8px;
  background-size: 20px;
  font-size: 14px;
  color: #fff;
  background: #22ccb7 url(/src/img/add-com.png) no-repeat left;
  background-size: 20px;
  background-position-x: 10px;
}
.select-name {
  display: inline-block;
  font-size: 18px;
  margin: auto 5px;
}
.filter-btn {
  display: inline-block;
  padding: 11px 20px;
  background-color: #22ccb7;
  border: none;
  border-radius: 5px;
  color: #ffffff !important;
  font-size: 18px;
  cursor: pointer;
}
.ip {
  color: #ec2323;
}
#greentext {
  color: #22ccb7;
  margin-bottom: 10px;
  font-size: 15px;
}
.gotowork {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
/* 휴가신청 */
.margin-left {
  margin-left: 5px;
}
/* 휴일관리 */
.tabbox {
  width: 200px;
  height: 49px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: flex;
  margin-right: 5px;
}
.tab_list {
  width: 100px;
  border: none;
  border-right: 1px solid #eeeeee;
  background-color: transparent;
  color: #9a9a9a;
}
.tab-img {
  width: 15px;
  height: 15px;
}
.acttab {
  background-color: #22ccb7;
  color: #fff;
}
.acttab:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.acttab:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* 자료실 */
.bang {
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  background-color: #d9274220;
  color: #d92742;
  border-radius: 50%;
  margin-right: 5px;
}
.admin-modalscroll {
  overflow-y: scroll;
  max-height: 500px;
}
.gray-t-download {
  width: 30px;
  height: 30px;
  background: url(/src/img/첨부파일_gray.svg) no-repeat center;
  background-size: 25px;
}

/* 회원관리 */
.member-input {
  width: 22px;
  height: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  cursor: pointer;
}
.member-on {
  background: #22ccb7 url(/src/img/check.png) no-repeat center;
  background-size: 18px;
}

/* @media (max-width: 720px) {
  .select-name {
    display: none;
  }
  .filter-btn {
    display: none;
  }
  .substanceCont {
    margin: 0;
  }
  .date-input {
    margin-top: 10px !important;
  }
} */
