.spinner-container {
  position: relative;
  display: inline-block;
  width: 100%;
  /* margin: 1rem; */
}

.spinner-container:hover {
  border: 1px solid darkgray;
  border-radius: 0.5rem;
}

.spinner-container:hover spinner-input {
  border: none;
}

spinner-input {
  border: 1px solid lightgray;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

spinner-input:hover {
  border-color: green;
}

.spinner {
  right: 1px;
  top: 2px;
  width: 1.5rem;
  position: absolute;
  background: white;
  border-radius: 0.5rem;
  display: none;
  height: 2rem;
}

.spinner {
  display: block;
}

.spinner-container:hover .spinner {
  right: 0;
  top: 1px;
}

.button {
  position: relative;
  color: lightgray;
  background: transparent;
  border: none;
  font-size: 2rem;
  padding: 0;
  display: block;
}

.button:hover {
  color: gray;
}

.button:active {
  color: darkgray;
}

.up {
  transform: rotate(-90deg);
  top: -0.5rem;
}

.down {
  transform: rotate(-90deg);
  top: -2rem;
}
