.cal-popup {
  z-index: 600;
  position: absolute;
  top: 10%;
  left: 20%;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-in-out;
  /*width: 300px;*/
  min-width: 445px;
  display: none;
}
.cal-popup .tit {
  position: relative;
  border-bottom: 1px solid #dddddd;
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
}
.cal-popup .tit h5 {
  font-size: 1.2rem;
}
.cal-popup .tit h5 span {
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
}
.cal-popup .tit button {
  position: absolute;
  display: block;
  right: 0%;
  top: 5%;
}
.cal-popup .pop-con {
  /*
    max-height: 200px;
    overflow-y: scroll;
     */
}
.cal-popup .pop-con table.pop-in-record {
  border: none;
  width: 100%;
}
.cal-popup .pop-con table.pop-in-record thead {
  display: table-caption;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 15px;
}
.cal-popup .pop-con table.pop-in-record tbody th {
  color: #909090;
  display: table;
  padding: 5px 0 0 5px;
}
.cal-popup .pop-con table.pop-in-record tbody tr.top th,
.cal-popup .pop-con table.pop-in-record tbody tr.top td {
  padding-top: 20px;
}
.cal-popup .pop-con table.pop-in-record tbody th img {
  margin-right: 6px;
}
.cal-popup .pop-con table.pop-in-record tbody th.ico-none {
  padding-left: 26px;
}
.cal-popup .pop-con table.pop-in-record tbody td {
  text-align: right;
  padding: 5px 0 0 0;
}
.cal-popup .pop-con table.pop-in-record tbody td .error {
  color: #fe0e5d;
}

.switch {
  position: relative;
  display: inline-block;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.popup-back1 {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
