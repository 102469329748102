.m-green {
  color: #23cdb7;
}
.table_cal caption {
  display: none;
}
.left-f {
  float: left;
}

/*ë‹¬ë ¥-í†µê³„-ë²„íŠ¼*/
.calendar {
  overflow: hidden;
  width: 100%;
}
.calendar .record {
  float: left;
  width: 17%;
  margin-right: 3%;
}
.calendar .record .total {
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 6%;
}
.calendar .record .total h4 {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
}
.calendar .record .total .total-ap {
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}
.calendar .record .total .total-ap p {
  color: #9d9d9d;
  padding: 0 0 15px 0;
}
.calendar .record .total .total-ap p span {
  display: block;
  float: right;
  text-align: right;
}
.calendar .record .total .total-count {
  padding: 25px 10px 10px 10px;
}
.calendar .record .total .total-count p {
  font-weight: 600;
  padding: 0 0 15px 0;
}
.calendar .record .total .total-count p span {
  display: inline-block;
  text-align: right;
  float: right;
}
.calendar .record .total .total-count p span i {
  display: inline-block;
  padding-right: 5px;
}
.calendar .record .record-btn {
  margin: 50px 0 0 0;
}
.calendar .record .record-btn a {
  width: 100%;
  margin: 10px 0;
  text-align: center;
  border-radius: 7px;
  color: #ffffff;
  border-width: inherit;
  font-size: 1.13rem;
  font-weight: 500;
  display: block;
  padding: 30px 0;
}
.calendar .record .record-btn a.start {
  background: linear-gradient(40deg, #f58962, #fc9f42);
}

.calendar .record .record-btn a.start_off {
  background: linear-gradient(40deg, #cbcbcb, #cbcbcb);
}

.calendar .record .record-btn a.end {
  background: linear-gradient(40deg, #245ebf, #3c85ff);
}

.calendar .record .record-btn a.end_off {
  background: linear-gradient(40deg, #cbcbcb, #cbcbcb);
}

.calendar .record .record-btn a.over {
  background: linear-gradient(40deg, #c446f2, #f273ee);
}

/*ë‹¬ë ¥*/
.width100 {
  width: 100% !important;
}
.calendar .calendar-con {
  float: right;
  width: 80%;
  position: relative;
  z-index: 100;
}
.calendar .calendar-con .top {
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
}
/*
.calendar .calendar-con .top div{
    float: left;
}
 */
.calendar .calendar-con .top div ul li {
  float: left;
}
.calendar .calendar-con .top.month-select {
  overflow: hidden;
  float: left;
}
.calendar .calendar-con .top .month-select ul li {
  border: 1px solid #dddddd;
  height: 47px;
}
.calendar .calendar-con .top .month-select ul li.pre {
  border-radius: 7px 0 0 7px;
  border-right: none;
}
.calendar .calendar-con .top .month-select ul li.pre:hover,
.calendar .calendar-con .top .month-select ul li.next:hover {
  background: #f1f1f1;
}
.calendar .calendar-con .top .month-select ul li.pre a,
.calendar .calendar-con .top .month-select ul li.next a {
  padding: 13px 20px;
}
.calendar .calendar-con .top .month-select ul li.next {
  border-radius: 0 7px 7px 0;
  border-left: none;
}

.calendar .calendar-con .top .month-select ul li.today-come {
  border-radius: 7px;
  margin-left: 15px;
  margin-right: 15px;
}
.calendar .calendar-con .top .month-select ul li.today-come:hover {
  background: #23cdb7;
}
.calendar .calendar-con .top .month-select ul li.today-come:hover a {
  color: #ffffff;
}
.calendar .calendar-con .top .month-select ul li.cal-select a {
  padding: 17px 37px 17px 80px;
}
.calendar .calendar-con .top .month-select ul li a {
  display: block;
  padding: 13px 15px;
}
.calendar .calendar-con .top .color-chart {
  /* height: 50px;*/
  float: left;
}
.calendar .calendar-con .top .color-chart ul li {
  margin-left: 20px;
}
.calendar .calendar-con .top .color-chart ul li span {
  padding: 30px 0 0 0;
  display: block;
}
.calendar .calendar-con .top .color-chart ul li.orenge span {
  color: #f58962;
}
.calendar .calendar-con .top .color-chart ul li.blue span {
  color: #245ebf;
}
.calendar .calendar-con .top .color-chart ul li.purple span {
  color: #c446f2;
}

.calendar .calendar-con .top .color-chart ul li.orenge span i {
  background: #f58962;
}
.calendar .calendar-con .top .color-chart ul li.blue span i {
  background: #245ebf;
}
.calendar .calendar-con .top .color-chart ul li.purple span i {
  background: #c446f2;
}
.calendar .calendar-con .top .color-chart ul li span i {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.calendar .calendar-con .top .list-select {
  float: right;
  width: 235px;
}
.calendar .calendar-con .top .list-select ul {
  border-radius: 7px;
  border: 1px solid #dddddd;
  overflow: hidden;
}
.calendar .calendar-con .top .list-select ul li {
  height: 50px;
  width: 50%;
  box-sizing: border-box;
}
.calendar .calendar-con .top .list-select ul li.on {
  background: #23cdb7;
}
.calendar .calendar-con .top .list-select ul li.on a {
  color: #ffffff;
}
.calendar .calendar-con .top .list-select ul li.on a.mon {
}
.calendar .calendar-con .top .list-select ul li.on a.list {
}

.calendar .calendar-con .top .list-select ul li a {
  width: 100%;
  display: block;
  padding: 16px 0 10px 50px;
  box-sizing: border-box;
}
.calendar .calendar-con .top .list-select ul li:hover {
  background: #23cdb7;
}
.calendar .calendar-con .top .list-select ul li:hover a {
  color: #ffffff;
}
.calendar .calendar-con .top .list-select ul li:hover a.mon {
}
.calendar .calendar-con .top .list-select ul li:hover a.list {
}

.calendar .calendar-con .top .list-select ul li a.mon {
  padding: 16px 0 10px 60px;
}
.calendar .calendar-con .top .list-select ul li a.list {
}
.calendar .calendar-con .content {
  width: 100%;
}
.calendar .calendar-con .content table {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 7px;
}
.calendar .calendar-con .content table th {
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #dddddd;
}
.calendar-con .content table td {
  height: 105px;
  width: 176px;
  border: 1px solid #dddddd;
  border-width: 0 1px 1px 0;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}
.calendar .calendar-con .content table td a {
  display: inline-block;
}

.calendar .calendar-con .content table td.last {
  border-right: none;
}
.calendar .calendar-con .content table tr:last-child td {
  border-bottom: 0;
}
.calendar .calendar-con .content table td span {
  display: inline-block;
  font-weight: 500;
  font-size: 0.8rem;
}
.calendar .calendar-con .content table td span.red {
  color: #ff1763;
}
.calendar .calendar-con .content table td span.num {
  margin: 8px 0 0 8px;
  padding: 7px;
}
.calendar .calendar-con .content table td span.pre,
.calendar .calendar-con .content table td span.next {
  color: #cacaca;
  font-weight: 400;
}
.calendar .calendar-con .content table td span.event-day {
  color: #ffffff;
  background: #ff1763;
  border-radius: 60px;
}
.calendar .calendar-con .content table td span.event {
  float: right;
  padding: 10px 7px 0 0;
  color: #ff1763;
  font-weight: 600;
}
.calendar .calendar-con .content table td span.today {
  color: #ffffff;
  background: #23cdb7;
  border-radius: 100%;
}
.calendar .calendar-con .content table td ul {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.calendar .calendar-con .content table td ul li {
  float: left;
  width: 49.66%;
  border: 1px solid #dddddd;
  border-width: 1px 1px 0 0;
}
.calendar .calendar-con .content table td ul li:nth-child(2n) {
  border-width: 1px 0 0 0 !important;
}
.calendar-con .content table td ul li a {
  width: 100%;
  display: block;
  padding: 5px 0 5px 29px;
  box-sizing: border-box;
}
.calendar .calendar-con .content table td ul li a.start,
.cal_in tbody td a.comin {
  color: #f58962;
}
.calendar .calendar-con .content table td ul li a.end,
.cal_in tbody td a.comout {
  color: #245ebf;
}
.calendar .calendar-con .content table td ul li a.over,
.cal_in tbody td a.comover {
  color: #c446f2;
}
/*ì¶œí‡´ê·¼ - ë¦¬ìŠ¤íŠ¸ gy*/
.calendar .calendar-con .content .cal_in table {
  border: 0;
}
.calendar-con .content .cal_in table th {
  border-top: 1px solid #ddd;
}
.calendar-con .content .cal_in table td {
  text-align: center;
  width: inherit;
  height: inherit !important;
  border-bottom: 1px solid #ddd;
  border-right: 0;
  border-left: 0;
  padding: 13px 0;
}
.calendar-con .content .cal_in table td a {
  display: inline-block;
  padding: 0 0 0 29px;
  box-sizing: border-box;
}

.calendar-con .content .cal_in table th:first-child {
  border-bottom-left-radius: 6px;
  border-left: 1px solid #ddd;
}
.calendar-con .content .cal_in table th:last-child {
  border-bottom-right-radius: 6px;
  border-right: 1px solid #ddd;
}
.calendar .calendar-con .content .cal_in table tr:last-child td {
  border-bottom: 1px solid #ddd;
}
.list_calw.list_cal1 table {
  display: inline-table;
  /*display: none;*/
}
.list_calw.list_cal2 table,
.list_calw.list_cal3 table,
.list_calw.list_cal4 table,
.list_calw.list_cal5 table {
  /*display: none;*/
  /* display: inline-table;*/
}
.content .list_calw.on {
  margin-bottom: 15px;
}
.content .list_calw.list_cal5.on {
  border-bottom: 0;
}
.list_cal5 .list_top {
  border-bottom: 1px solid #ddd;
}
.list_top {
  border: 1px solid #ddd;
  border-bottom: 0;
}
.week_num {
  box-sizing: border-box;
  width: 12.5%;
  text-align: center;
  float: left;
}
.week_num a {
  line-height: 37px;
  width: 100%;
  display: inline-block;
  background: #f7f7f7;
  font-weight: bold;
  border-right: 1px solid #ddd;
}
.week_num.on a {
  color: #fff;
  background: #23cdb7;
}
.week_tit {
  font-weight: bold;
  line-height: 37px;
  float: left;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  width: 87.5%;
}

/*ì¶œí‡´ê·¼-ë ˆì´ì–´íŒì—…*/
.cal-popup {
  z-index: 600;
  position: absolute;
  top: 10%;
  left: 20%;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-in-out;
  /*width: 300px;*/
  min-width: 445px;
  display: none;
}
.cal-popup .tit {
  position: relative;
  border-bottom: 1px solid #dddddd;
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
}
.cal-popup .tit h5 {
  font-size: 1.2rem;
}
.cal-popup .tit h5 span {
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
}
.cal-popup .tit a {
  position: absolute;
  display: block;
  right: 0%;
  top: 5%;
}
.cal-popup .pop-con {
  /*
    max-height: 200px;
    overflow-y: scroll;
     */
}
.cal-popup .pop-con table.pop-in-record {
  border: none;
  width: 100%;
}
.cal-popup .pop-con table.pop-in-record thead {
  display: table-caption;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 15px;
}
.cal-popup .pop-con table.pop-in-record tbody th {
  color: #909090;
  display: table;
  padding: 5px 0 0 5px;
}
.cal-popup .pop-con table.pop-in-record tbody tr.top th,
.cal-popup .pop-con table.pop-in-record tbody tr.top td {
  padding-top: 20px;
}
.cal-popup .pop-con table.pop-in-record tbody th img {
  margin-right: 6px;
}
.cal-popup .pop-con table.pop-in-record tbody th.ico-none {
  padding-left: 26px;
}
.cal-popup .pop-con table.pop-in-record tbody td {
  text-align: right;
  padding: 5px 0 0 0;
}
.cal-popup .pop-con table.pop-in-record tbody td .error {
  color: #fe0e5d;
}
.popup-back {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}

.popup-back1 {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*ì¼ì •ì¶”ê°€ ë ˆì´ì–´íŒì—…*/
.pop-in-schedule td {
  padding: 10px 0;
}
.pop-in-schedule td.midtd {
  padding: 0 15px;
}
.pop-in-schedule img {
  width: 25px;
}
.pop-in-schedule tr:nth-child(5) img {
  width: 20px;
}
.view-con .v-input.v-put {
  width: 161px;
}
.pop-in-schedule .v-input::placeholder {
  font-size: 13px;
  color: #777;
}
.pop-in-schedule .v-input {
  font-size: 13px;
}
.mondiv {
  width: 161px;
}
.midtd {
  margin: 0 17px;
}
.seldiv,
.mondiv {
  display: inline-block;
  padding: 9px !important;
  height: 40px !important;
  vertical-align: middle;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.seldiv {
  width: 120px;
}
/* .monin{
    width: 40%;
} */
.mondiv i {
  top: 4px;
}
.monin2 i {
  top: inherit !important;
}
.text-input {
  height: 110px;
}

/*ì¼ì •ê´€ë¦¬*/
/*
.re-width{
    width: 15% !important;
}
 */
.search-calendar {
  overflow: hidden;
  float: left;
}
.search-calendar div {
  float: left;
}

ul.schedule {
  overflow: hidden;
}
ul.schedule li {
  width: 100% !important;
  float: none !important;
  border: none !important;
}
ul.schedule li.sc-01 {
  background: #fdeae0;
}
ul.schedule li.sc-02 {
  background: #e0e2fd;
}
ul.schedule li.sc-03 {
  background: #fde0ed;
}
ul.schedule li.sc-04 {
  background: #e0fde4;
}
ul.schedule li.sc-05 {
  background: #fdfde0;
}

ul.schedule li a {
  padding: 3px 0 3px 20px !important;
  font-size: 0.8rem;
  box-sizing: border-box;
}
ul.schedule li a span {
  display: block;
  vertical-align: top !important;
  padding: 0 20px 0 5px;
  text-align: right !important;
  float: right;
}
.td150 table td {
  height: 130px !important;
}
.wid120 {
  width: 120px;
}
/*ê°œì¸ì •ë³´ì²˜ë¦¬ë°©ì¹¨-ë ˆì´ì–´íŒì—…*/
.pri-company {
  overflow-y: scroll;
  padding: 15px 10px;
  max-height: 500px;
}
.pri-company h4 {
  font-size: 1.1rem;
  padding: 6px 0;
  margin: 10px 0;
}
.pri-company h4.top {
  margin-top: 0;
}
.pri-company h4 span {
  vertical-align: top;
  display: inline-block;
  padding-right: 10px;
}
.pri-company h5 {
  padding: 10px 0 10px 10px;
  font-weight: 600;
}
.pri-company p {
  padding: 0 0 7px 15px;
}
.pri-company ul li {
  padding: 3px 0 3px 20px;
}
.pri-company ul li ul {
  padding-top: 10px;
}
.pri-company ul li ul li {
  padding: 3px 0 3px 25px;
}
.pri-company .box {
  background: #f7f7f7;
  border-radius: 7px;
  padding: 20px;
  margin: 0 0 15px 0;
}

/*ì—°ì°¨*/
.sc-left {
  width: 25%;
  box-sizing: border-box;
  padding-right: 40px;
}
.sc-right {
  width: 75%;
  box-sizing: border-box;
}
.sc-left .sc-total {
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 15px;
}
.sc-left .sc-total h4 {
  font-size: 1.5rem;
}
.sc-left .sc-total ul {
  margin: 15px 20px 0 20px;
}
.sc-left .sc-total ul li {
  margin: 7px 0 7px 0;
  font-weight: 600;
}
.sc-left .sc-total ul li span {
  vertical-align: top;
}
.sc-left .sc-total ul li:last-child {
  margin-bottom: 0;
}
.sc-left h5 {
  font-size: 1.2rem;
  padding: 15px 0 10px 0;
}
.sc-left .sc-select {
  overflow: hidden;
  clear: both;
}
.sc-left .sc-select ul li {
  float: left;
  width: 33.33%;
  box-sizing: border-box;
  padding: 0 10px 10px 0;
}
.sc-left .sc-select ul li a {
  display: block;
  text-align: center;
  padding: 7px 0;
  border-radius: 7px;
  border: 1px solid #dddddd;
}
.sc-left .sc-select ul li a.on {
  background: #414453;
  color: #ffffff;
}
.sc-left .sc-select ul li a:hover {
  background: #414453;
  color: #ffffff;
}
.sc-person select {
  width: 100%;
}
.sc-person {
  overflow: hidden;
  clear: both;
}
.sc-person ul {
  overflow: hidden;
  width: 100%;
  margin: 10px 0 0 0;
}
.sc-person ul li {
  width: 100px;
  float: left;
  box-sizing: border-box;
  background: #ececec;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 3px;
  font-size: 13px;
}
.sc-person ul li a {
  vertical-align: top;
  display: inline-block;
  float: right;
}
.sc-hi {
  height: 70px !important;
}
.sc-day {
  width: 100%;
}

.sc-left .result-r {
  float: right;
  padding: 17px 0 0 0;
  display: inline-block;
}

.sc-left .result-l {
  display: inline-block;
}

.sc-day div {
  width: 30.7%;
  /*width: 28%;*/
  display: inline-block;
}
.sc-btn a {
  display: inline-block;
  width: 47%;
  margin-left: 2%;
  text-align: center;
  padding: 15px 0;
  border-radius: 7px;
}
.sc-btn a.submit {
  color: #ffffff;
  background: #23cdb7;
}
.sc-btn a.re-btn {
  border: 1px solid #dddddd;
}
.sc-right .top-left {
  max-width: 10%;
  flex: 0 0 10%;
}
.sc-right .top-right {
  max-width: 90%;
  flex: 0 0 90%;
}
.pop_textarea {
  width: 100%;
  height: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  resize: none;
}

/*ì¼ì • ë”ë³´ê¸°*/
ul.schedule li.sc-more {
  text-align: right;
}
ul.schedule li.sc-more a {
  padding: 3px 10px 3px 0 !important;
  font-size: 12px;
}
ul.schedule li.sc-more i {
  vertical-align: top;
}
.cal-more-list {
  overflow-y: scroll;
  padding: 5px 8px;
  height: 126px;
}
.cal-more-list a {
  width: 100%;
  display: block;
  padding: 5px 0;
  overflow: hidden;
  clear: both;
}
.cal-more-list a span {
  display: block;
  float: right;
}
.cal-more-list a span.cal-t {
  float: left;
}
.cal-more-list a i {
  width: 10px;
  height: 10px;
  display: block;
  float: left;
  margin: 4px 9px 0 0;
}
.cal-more-list a i.sc-03 {
  background: #ffb6d7;
}
.cal-more-list a i.sc-01 {
  background: #ffd5be;
}
.cal-more-list a i.sc-02 {
  background: #e0e2fd;
}
.cal-more-list a i.sc-04 {
  background: #affbba;
}
.cal-more-list a i.sc-05 {
  background: #fff0ba;
}

@media (max-width: 1500px) {
  .month-select {
    /*float: right;*/
  }
  .search-calendar {
    width: 60%;
    margin: 10px 0 0 0;
  }
  .calendar .calendar-con .top .list-select {
    margin: 10px 0 0 0;
  }
  /*
    .calendar .calendar-con .top .color-chart {
        float: right;
    }
     */
  .calendar .calendar-con .content table td {
    height: 150px;
  }
  .calendar .calendar-con .content table td ul li {
    width: 100%;
  }
}
