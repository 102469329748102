/* #popup-box {
  overflow: "auto";
} */

#popup-content {
  max-width: 100%;
  max-height: 100%;
}

#popup-content img {
  max-width: 100%;
  max-height: 100%;
}

#popup-bottom {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: black;
  bottom: 0;
  width: 100%;
}

#popup-bottom button {
  color: white;
}
