@charset "utf-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: #222222;
  text-decoration: none;
}
/* member_list */
.chat-box {
  z-index: 999;
  position: absolute;
  top: 70px;
  right: 0;
  width: 500px;
  min-height: 550px;
  border: 1px solid #eee;
  background-color: white;
}
.chat-top {
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #23cdb7;
  color: #fff;
  font-size: 17px;
  padding: 15px 20px;
}
.chat-sec-list {
  padding: 10px 16px;
  border-bottom: 1px solid #e4e4e4;
}
.chat-sec-list .chat-search {
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px 23px;
  border: none;
  border-radius: 4px;
  color: #6f6f6f;
}
.chat-sec-list .search-x {
  position: absolute;
  top: 50%;
  left: 96%;
  transform: translate(-50%, -50%);
}
.chat-content {
  padding: 0 30px;
}
.chat-user-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid #eeeeee;
}
.profile {
  background: url(../img/profile.svg) no-repeat left;
  margin-right: 10px;
}
.user-select {
  width: 55px;
  height: 55px;
}
.user-list {
  width: 46px;
  height: 46px;
  background-size: 45px;
}
.chat-user-list {
  font-size: 15px;
}
.list {
  padding: 15px 7px;
  border-bottom: 1px solid #eeeeee;
}
.chat-user-list .list p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-user-list-select {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 4px;
}
.chat-scroll {
  overflow-y: scroll;
}
.chat-scroll::-webkit-scrollbar {
  width: 10px;
}
.chat-scroll::-webkit-scrollbar-thumb {
  background-color: #737d86;
  border-radius: 30px;
}
.chat-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

/* member_search */
.chat-icon {
  display: flex;
  justify-content: flex-end;
  padding: 8px 20px;
  border-bottom: 1px solid #e4e4e4;
}
/* .chat-icon button {
  width: ;
} */
.chat-icon button {
  width: 42px;
  height: 42px;
  display: inline-block;
}
.icon_search {
  background: url(../img/icon_search.svg) no-repeat center;
}
.icon_profile {
  background: url(../img/icon_profile.svg) no-repeat center;
}
.icon_chat {
  background: url(../img/icon_chat.svg) no-repeat center;
}
.icon_plus {
  background: url(../img/icon_plus.svg) no-repeat center;
}
.icon_out {
  background: url(../img/icon_out.svg) no-repeat center;
}
.icon_search:hover {
  background: #f3f3f3 url(../img/icon_search_hover.svg) no-repeat center;
  border-radius: 50%;
}
.icon_profile:hover {
  background: #f3f3f3 url(../img/icon_profile_hover.svg) no-repeat center;
  border-radius: 50%;
}
.icon_chat:hover {
  background: #f3f3f3 url(../img/icon_chat_hover.svg) no-repeat center;
  border-radius: 50%;
}
.icon_plus:hover {
  background: #f3f3f3 url(../img/icon_plus_hover.svg) no-repeat center;
  border-radius: 50%;
}
.chat-sec-search {
  padding: 9px 30px;
  border-bottom: 1px solid #e4e4e4;
}
.chat-sec-search .chat-search {
  width: calc(100% - 35px);
  border: 1px solid #222222;
  padding: 10px 24px;
  border-radius: 30px;
}
.chat-sec-search .search-x img {
  margin: -10px;
  margin-left: 5px;
}

/* chat_list */
.green-ing {
  width: 11px;
  height: 11px;
  background-color: #23cdb7;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute;
  top: 33px;
  left: 10px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.chatting-cont {
  font-size: 13px;
  color: #3c3c4399;
}
.chat-content-c .chat-user-list-select {
  padding-left: 30px;
}
.chat-time {
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #3c3c4399;
}

/* chat_invite */
.invite-chk-on {
  width: 40px;
  height: 40px;
  background: url(../img/check-on.svg) no-repeat center;
}
.invite-chk-off {
  width: 40px;
  height: 40px;
  background: url(../img/check-off.svg) no-repeat center;
}
.chat-bottom {
  padding: 15px 0;
  background-color: #f7f7f7;
  border: 1px solid #b9b9b9;
  text-align: center;
}
.chat-bottom a::before {
  content: "";
  width: 14px;
  height: 14px;
  display: inline-block;
  background: url(../img/plus.svg) no-repeat left;
}
.chatting-view {
  font-size: 13px;
  color: #3c3c4399;
}

/* chat_invite_select */
.choice-user {
  padding: 15px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.select-user {
  display: inline-block;
  margin-right: 12px;
}
.profile-delete {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(../img/profile_delete.svg) no-repeat;
  position: absolute;
  right: -7px;
}
.text-green {
  color: #23cdb7;
}

/* chatting */
.chat-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  box-shadow: 0 3px 10px #00000009;
}
.chat-between .user-name {
  display: inline-block;
}
.chat-between .icon-top button {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.chatting {
  padding: 15px 24px 0;
}
.counterpart {
  align-items: flex-end;
  /* position: relative; */
  margin-top: 10px;
}
.counterpart .user-list {
  /* margin: 10px; 
  margin-left:0; */
  /* position: absolute; */
  /* top: 0; */
}
/* .counterpart .m-width-50 {
  margin-left: 55px;
} */
.m-width-50 {
  max-width: 50%;
}
.chat-cont-text {
  word-break: break-all;
  background-color: #fff;
  box-shadow: 1px 1px 2px #22222218;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 8px;
  padding: 6px 10px;
}
.counterpart .chat-cont-text {
  border-radius: 0 8px 8px 8px;
}
.timebox p {
  font-size: 10px;
  font-weight: 500;
  color: #3c3c4399;
}
.counterpart .timebox p {
  margin-left: 10px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.mepart {
  margin-top: 10px;
}
.mepart .timebox p {
  margin-right: 10px;
}
.mepart .chat-cont-text {
  border-radius: 8px 0 8px 8px;
}

.textarea {
  width: calc(100% - 75px);
  border: none;
  resize: none;
  font-size: 14px;
  padding: 5px;
}
.submitbtn-c {
  display: inline-block;
  float: right;
  padding: 7px 15px;
  background-color: #2b2d42;
  border: 1px solid #242537;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
}
.submitbtn-c:active {
  color: #ffffff80;
}
.file-clip-box {
  height: 30px;
  display: flex;
}
.file-clip {
  display: inline-block;
  width: 30px;
  background: url(../img/file-clip.svg) no-repeat center;
}
.file-clip:hover {
  background: #f3f3f3 url(../img/file-clip-hover.svg) no-repeat center;
  border-radius: 50%;
}

/* chatting_multi */
.profile-multi {
  width: 50px;
  height: 45px;
  background: url(../img/profile-multi.svg) no-repeat center;
  margin-right: 10px;
  z-index: 100;
}
.profile-multi-d {
  width: 50px;
  height: 45px;
  background: url(../img/profile-multi-d.svg) no-repeat center;
  margin-right: 10px;
  z-index: 100;
}
.chat-list-all {
  width: 272px;
  max-height: 268px;
  position: absolute;
  background-color: #ffffff;
  padding: 16px;
  border-top: 1px solid #e4e4e4;
  border-radius: 0 0 10px 10px;
}
.chat-list-all p {
  border-bottom: 1px solid #dfe3e8;
  font-size: 13px;
  font-weight: 500;
  padding: 16px;
}
