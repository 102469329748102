@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* .user-substanceCont {
  margin-top: 90px;
} */
/* .user-pillbtn {
  margin-right: 16px;
} */
.user-pill {
  width: 100px;
  height: 70px;
  color: #fff;
  margin-left: -4px;
}
.user-pill > img {
  width: 30px;
  height: 30px;
  display: block;
  margin: 5px auto;
}
.user-schedule-menu {
  margin-right: 30px;
  min-width: 300px;
}
.user-schedule-menu-header {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 40px;
}
.user-schedule-menu-header > h2 {
  text-align: center;
  color: #22ccb7;
  font-size: 20px;
  margin-bottom: 20px;
}
.timebox {
  margin-bottom: 10px;
  color: #757575;
}
.timebox:last-of-type {
  margin-bottom: 0;
}
.user-schedule-time {
  display: block;
  float: right;
  text-align: end;
}
.user-schedule-btn {
  width: 100%;
  color: white;
  font-size: 20px;
  padding: 50px 30px;
  text-align: center;
  border-radius: 8px;
  margin: 10px 0;
  cursor: pointer;
}

.user-schedule-btn-top-gray {
  background: #626262 url("../img/work-t.png") no-repeat;
}
.user-schedule-btn-bottom-gray {
  background: #626262 url("../img/holly.png") no-repeat;
}
.user-schedule-btn-top {
  background: #22ccb7 url("../img/work-t.png") no-repeat;
}
.user-schedule-btn-bottom {
  background: #1a5c96 url("../img/holly.png") no-repeat;
}

.user-schedule-btn-top:disabled {
  background: #626262 url("../img/work-t.png") no-repeat;
  cursor: not-allowed;
}

.user-schedule-btn-bottom:disabled {
  background: #626262 url("../img/holly.png") no-repeat;
  cursor: not-allowed;
}

#user-substance {
  margin-top: 30px;
  display: flex;
}
.vacation-menu {
  width: 400px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-right: 30px;
  padding: 30px;
}
.vacation-menu > h2 {
  text-align: center;
  color: #22ccb7;
  font-size: 20px;
  margin-bottom: 25px;
}
#vacation-rule {
  /* display: flex; */
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-right: 30px;
  padding: 30px;
}
#vacation-rule > h2 {
  text-align: left;
  color: #22ccb7;
  font-size: 20px;
  margin-bottom: 25px;
}
.vacation-rule-box {
  margin-right: 10px;
}
#currentbox {
  border-radius: 5px;
  background-color: #fafafa;
  padding: 20px;
  margin-bottom: 10px;
}
.current {
  margin-bottom: 10px;
}
.current:last-of-type {
  margin-bottom: 0;
}
.user-time {
  display: block;
  float: right;
  text-align: end;
  color: #757575;
}
.calendar-wrapper {
  display: flex;
  margin-top: 20px;
}
.actday {
  display: inline-block;
  color: #22ccb7;
  margin-right: 15px;
}
.actrule {
  display: inline-block;
  color: #22ccb7;
  margin-right: 5px;
}
.vacation-menu h3 {
  font-size: 18px;
  margin: 10px 0;
}

.choice {
  float: left;
  width: calc((100% - 16px) / 3);
  background-color: #fafafa;
  border-radius: 5px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
}
.choice:nth-of-type(3n) {
  margin-right: 0;
}
.actchoice {
  background-color: #22ccb7;
  color: #fff;
}
.v-select_box {
  width: 100% !important;
}
.v-box {
  width: 100% !important;
}
.v-select {
  width: 100% !important;
  background-color: #fafafa;
}
#reason {
  width: 100%;
  height: 77px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  resize: none;
}
.input-w {
  width: calc((100% - 17px) / 2);
}
.vacationbtn {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}

.vacation-radio {
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }
  input[type="radio"]:checked {
    background-color: #22ccb7;
    border: 3px solid white;
    box-shadow: 0 0 0 1.6px #22ccb7;
  }
}

.v-substanceCont {
  width: 73%;
  margin-top: 0;
  margin-left: 0;
}

#reportCard {
  overflow: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  display: grid;
  margin: 30px 0;
  gap: 10px;
}
#reportCard-4 {
  overflow: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  display: grid;
  margin: 30px 0;
}
#reportCard-4 .write-card {
  border-radius: 0;
  border-right: 1px solid #22ccb7;
  border-left: 0;
}
#reportCard-4 .write-card:first-of-type {
  border-left: 1px solid #22ccb7;
}
.write-card {
  width: 100%;
  float: left;
  /* background-color: rgba(34, 204, 183, .05); */
  background-color: #22ccb70d;
  border: 1px solid #22ccb7;
  border-radius: 5px;
  margin-right: 10px;
  padding: 15px;
}
.write-card:last-of-type {
  margin-right: 0;
}
.card-title {
  color: #22ccb7;
  text-align: left;
}
.card-cont {
  padding: 30px;
  font-size: 20px;
  color: #2e2e2e;
}
.card-cont > img {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}
.mobile-inline {
  display: none;
}

/* elec_insert */
#itemtable {
  margin-top: 10px;
  border-top: none !important;
}
#itemtable th,
#itemtable td {
  border: none;
}
.member-input {
  width: 22px;
  height: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  cursor: pointer;
}
.member-on {
  background: #22ccb7 url("../img/check.png") no-repeat center;
  background-size: 18px;
}

/* 러닝센터 css */
#mv {
  max-width: 100%;
  margin-bottom: 20px;
}
#mv img {
  width: 100%;
}
#lecture {
  position: relative;
}
#lecture > h3 {
  font-size: 25px;
  margin-left: 10px;
  margin-bottom: 20px;
}
#lecture > h3::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  width: 4px;
  height: 28px;
  background-color: #22ccb7;
}
.lecture-left {
  width: 60%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 15px;
  padding-bottom: 0;
  margin-right: 25px;
}
.lecture-link {
  width: calc((100% - 45px) / 4);
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
}
.lecture-link:nth-of-type(4n) {
  margin-right: 0;
}
.lecture-link a,
.lecture-right a {
  cursor: pointer;
}
.lecture-link p {
  margin: 10px 0;
  font-size: 16px;
}
.lecture-right {
  width: 38%;
}
.shortcut {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 26px;
  overflow: auto;
}
.shortcut:first-of-type {
  margin-bottom: 15px;
}
.font-left {
  width: 65%;
  margin-right: 20px;
}
.big-fontsize {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 10px;
}
.small-fontsize {
  font-size: 16px;
}

@media (max-width: 1260px) {
  .user-pillbtn {
    background-color: #eee;
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: right;
    margin-top: 69px;
    margin-bottom: 20px;
  }
  .user-pill {
    width: 85px;
  }
  /* .substance {
    padding: 20px;
  } */
  /* 출퇴근기록 mobile */
  .calendar-wrapper {
    display: block;
  }
  .user-schedule-menu {
    width: 100%;
    /* margin-top: 150px; */
  }
  .user-schedule-btn {
    float: left;
    width: 48%;
    color: white;
    font-size: 20px;
    padding: 30px;
    text-align: center;
    border-radius: 8px;
  }
  .user-schedule-btn:first-of-type {
    margin-right: 10px;
  }
  .user-schedule-btn-top {
    background-position-x: 100%;
    background-position-y: 50%;
    background-size: 200px;
  }
  .user-schedule-btn-bottom {
    background-position-x: 100%;
    background-position-y: 50%;
    background-size: 200px;
  }

  /* vacation mobile */
  .mobile-inline {
    display: inline;
  }
  /* .user-pill {
    margin-top: 70px;
  } */
  #user-substance {
    display: block;
  }
  .user-substanceCont {
    margin: 10px 0;
  }
  .vacation-menu {
    /* margin-top: 150px; */
    width: 100%;
    margin-bottom: 10px;
  }
  .v-substanceCont {
    width: 100%;
    margin-top: 10px;
  }
  /* .datebox {
    display: inline-flex;
    width: 165px;
  } */
  .v-select_box {
    margin-bottom: 10px;
    margin-top: 0;
  }
  /* Workreport mobile */
  #reportCard {
    overflow: initial;
    margin: 0;
  }
  .write-card {
    padding: 5px;
  }
  .card-title {
    font-size: 12px;
  }
  .card-cont {
    padding: 15px;
    font-size: 15px;
  }
  .writetable {
    margin-top: 20px;
  }
  /*러닝센터 mobile*/
  #mv {
    margin-top: -10px;
    margin-left: -20px;
  }
  #lecture {
    margin-left: 0;
  }
  #lecture > h3 {
    font-size: 20px;
  }
  #lecture > h3::before {
    height: 21px;
  }
  .lecture-left {
    width: 100%;
    border: none;
  }
  .lecture-link {
    width: calc((100% - 15px) / 2);
    margin-right: 15px;
  }
  .lecture-link:nth-of-type(even) {
    margin-right: 0;
  }
  .lecture-right {
    width: 100%;
  }
  .font-left {
    width: 55%;
  }
  .big-fontsize {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .small-fontsize {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  /* Workreport mobile */
  #reportCard {
    overflow: visible;
  }
  .write-card {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }
}
