.react-calendar {
  border: none;
  margin: auto;
}
.react-calendar__tile {
  width: 50px;
  height: 50px;
  overflow: visible !important;
}
.react-calendar__tile--now {
  position: relative;
  border-radius: 50%;
  background: transparent;
  color: white;
}
.react-calendar__tile--now::before {
  content: "";
  display: inline-block;
  width: 35px;
  height: 35px;
  position: absolute;
  background-color: #22ccb7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.react-calendar__tile--now:hover::before {
  background-color: grey;
}
.react-calendar__tile--now > abbr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #c8c8c8;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: transparent;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__next-button {
  background: url("../img/right-arrow.svg");
}
abbr {
  text-decoration: none;
  width: 100%;
  text-align: left;
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 18px;
  text-decoration: none;
}
.calendar-arrow-button {
  border: none;
  background: none;
  cursor: pointer;
}
.event-today {
  font-size: 15px;
  text-align: center;
}
.event-today > span {
  color: "#22CCB7";
}
.cal-bott {
  margin-top: 12px;
}
.event {
  color: #757575;
}
.today-caption {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 100px;
  color: white;
  background: orange;
  padding: 2px;
  font-size: 11px;
}
.event-wrapper {
  display: none;
}
.holiday {
  color: red;
}
.holiday-name {
  display: none;
}

.home-calendar-content {
  margin: 8px;
}

.home-calendar-title {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  margin-bottom: 20px;
}

.home-calendar-title > p {
  color: #22ccb7;
  font-weight: bold;
}
